import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { EngineUserStore } from 'store/EngineUser';

export class ContactList extends EngineModel {
    static backendResourceName = 'contact_list';

    @observable id = null;
    @observable name = '';

    @observable memberCount = 0;
    @observable contactCount = 0;

    toBackend(...args) {
        const res = super.toBackend(...args);

        delete res.member_count;
        delete res.contact_count;

        return res;
    }

    relations() {
        return {
            members: EngineUserStore,
        };
    }
}

export class ContactListStore extends EngineStore {
    static backendResourceName = 'contact_list';
    Model = ContactList;
}
