import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { VoicemailMessage } from 'store/VoicemailMessage';
import RightDivider from 'spider/component/RightDivider';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SmallAvatar } from 'component/UserAvatar';

export const VoicemailContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    img {
        width: 40px !important;
        height: 40px !important;
    }
`;

const UserLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba(0, 0, 0, 0.87);
`;

export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 7px;
`;

export const ContactName = styled.div`
    font-size: 1rem;
    height: 1.25em;
    line-height: 1.25em;
    font-weight: bold;
    margin-bottom: 0.1em;
`;

export const MessageDate = styled.div`
    font-size: 0.8rem;
    height: 1.2em;
    line-height: 0.8em;
    color: rgba(0, 0, 0, 0.5);
`;


@observer
export default class VoicemailInfo extends Component {
    static propTypes = {
        voicemail: PropTypes.instanceOf(VoicemailMessage).isRequired,
        online: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {
        online: false,
        children: null,
    };

    @computed get contact() {
        const { voicemail } = this.props;
        
        if (voicemail) {
            return voicemail.call.from.models[0].contact
        } else {
            return null
        }
    }

    render() {
        const { voicemail, online, to, children, ...props } = this.props;

        const contactDetails = {}

        if (this.contact.name !== '') {
            contactDetails.name = this.contact.name
        }

        // calculate duration
        const minutes = Math.floor(voicemail.call.duration / 60)
        const seconds = voicemail.call.duration - minutes * 60
        contactDetails.callDate = `${voicemail.call.startedAt.format('DD/MM/YYYY HH:mm')} (${minutes}m${seconds ? seconds + 's' : ''})`

        contactDetails.phoneNumber = voicemail?.call?.from?.models[0]?.number        

        const phoneNumbers = (
            (this.contact.phoneNumbers || [])
            .filter((p) => p.primary)
            .map((p) => p.phoneNumber)
            .join(', ')
        );

        if (phoneNumbers !== '') {
            contactDetails.phoneNumbers = phoneNumbers
        }

        let userNode = (
            <React.Fragment>
                <SmallAvatar online={online} user={this.contact} />
                <UserDetails>
                    <ContactName>
                        {contactDetails.name ? contactDetails.name : contactDetails.phoneNumber}
                    </ContactName>
                    <MessageDate>
                        {contactDetails.callDate}
                    </MessageDate>
                </UserDetails>
            </React.Fragment>
        );

        if (to) {
            userNode = <UserLink to={to}>{userNode}</UserLink>;
        }

        return (
            <VoicemailContainer data-test-user-info {...props}>
                {userNode}
                <RightDivider />
                {children}
            </VoicemailContainer>
        );
    }
}
