import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { EngineClient } from 'store/EngineClient';
import { EngineUser } from 'store/EngineUser';
import { PublicPhoneNumber } from 'store/PublicPhoneNumber';

export class UserCallerId extends EngineModel {
    static backendResourceName = 'user_caller_id';

    @observable id = null;

    relations() {
        return {
            client: EngineClient,
            user: EngineUser,
            phoneNumber: PublicPhoneNumber,
        };
    }
}

export class UserCallerIdStore extends EngineStore {
    static backendResourceName = 'user_caller_id';
    Model = UserCallerId;
}
