import { observable } from 'mobx';
import { Contact } from "./Contact";
import { CallQueue } from "./CallQueue";
import { EngineModel, EngineStore, Casts } from "./Base";
import { VoicemailMessageStore } from './VoicemailMessage';

export class Call extends EngineModel {
    static backendResourceName = 'call';

    @observable id = null;
    @observable startedAt = null;
    @observable stoppedAt = null;
    @observable duration = 0;
    @observable recordings = [];
    @observable origin = null;

    relations() {
        return {
            legs: LegStore,
            from: LegStore,
            to: LegStore,
            voicemailMessages: VoicemailMessageStore,
        };
    }

    // temp calculate origin on the frontend while 
    /* @computed
    get origin() {
        if (this.legs === null || this.legs === undefined) {
            return 'outbund'
        }

        if (find(this.legs.models, (leg) => (leg.direction === 'inbound' && leg.answeredAt === null && leg.external === true))) {
            return 'missed'
        } else if (find(this.legs.models, (leg) => (leg.direction === 'inbound' && leg.external === true))) {
            return 'inbound'
        } else if (find(this.legs.models, (leg) => (leg.direction === 'outbound' && leg.external === true))) {
            return 'outbound'
        } else if (find(this.legs.models, (leg) => (leg.answeredAt === null))) {
            return 'failed'
        } else {
            return 'internal'
        }

    }
 */

    casts() {
        return {
            startedAt: Casts.datetime,
            stoppedAt: Casts.datetime,
        };
    }
}

export class CallStore extends EngineStore {
    static backendResourceName = 'call';
    Model = Call;
}


export class Leg extends EngineModel {
    static backendResourceName = 'leg';

    @observable id = null;
    @observable start = null;
    @observable external = false;
    @observable direction = 'inbound';
    @observable number = '';
    @observable answeredAt = null;
    @observable stoppedAt = null;
    @observable durationSeconds = 0;
    @observable ringingSeconds = 0;

    relations() {
        return {
            contact: Contact,
            callQueue: CallQueue,
            call: Call
        };
    }

    casts() {
        return {
            start: Casts.datetime,
            answeredAt: Casts.datetime,
            stoppedAt: Casts.datetime,
        };
    }
}

export class LegStore extends EngineStore {
    static backendResourceName = 'leg';
    Model = Leg;
}
