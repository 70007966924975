import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import Load from './Loadable';
import * as Sentry from '@sentry/react';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(BaseRoute);

const Dashboard = Load(() => import('../screen/Dashboard'));
const Settings = Load(() => import('../screen/Settings'));
const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(() => import('../screen/LoginForgot'));
const Activate = Load(() => import('../screen/Activate'));
const ResetPassword = Load(() => import('../screen/ResetPassword'));
const Changelog = Load(() => import('../screen/Changelog'));

const NotFound = Load(() => import('../container/NotFound'));

const ClientOverview = Load(() => import('../screen/Client/Overview'));
const ClientEdit = Load(() => import('../screen/Client/Edit'));

const DeviceOverview = Load(() => import('../screen/Device/Overview'));

const DialPlanOverview = Load(() => import('../screen/DialPlan/Overview'));
const DialPlanEdit = Load(() => import('../screen/DialPlan/Edit'));

const CallGroupOverview = Load(() => import('../screen/CallGroup/Overview'));
const CallGroupEdit = Load(() => import('../screen/CallGroup/Edit'));

const CallQueueOverview = Load(() => import('../screen/CallQueue/Overview'));

const ContactListOverview = Load(() => import('../screen/ContactList/Overview'));

const ContactOverview = Load(() => import('../screen/Contact/Overview'));
const ContactEdit = Load(() => import('../screen/Contact/Edit'));
const ContactCallOverview = Load(() => import('../screen/Contact/CallOverview'));

const CallOverview = Load(() => import('../screen/Call/Overview'));

const PublicPhoneNumberOverview = Load(() => import('../screen/PublicPhoneNumber/Overview'));

const LocationOverview = Load(() => import('../screen/Location/Overview'));

const ExtensionOverview = Load(() => import('../screen/Extension/Overview'));

const Docs = Load(() => import('../screen/Docs'));

const IntegrationsOverview = Load(() => import('../screen/Integration/Overview'));
const IntegrationErrorOverview = Load(() => import('../screen/IntegrationError/Overview'));

const MediaOverview = Load(() => import('../screen/Media/Overview'));


export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store} />;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;

        let uri = '';

        if (currentUser.isSuperuser) {
            uri = '/user/overview'
        }
        else {
            uri = '/dashboard'
        };

        return <Redirect to={uri} />;
    };

    render() {
        return (
            <Switch>
                <SentryRoute path="/" exact render={this.redirectFromHome} />
                <SentryRoute path="/changelog" render={this.route(Changelog)} />

                <SentryRoute path="/dashboard" render={this.route(Dashboard)} />
                <SentryRoute path="/company/settings/:tab?" render={this.route(Settings)} />

                <SentryRoute path="/profile/edit/:tab?" render={this.route(UserEdit)} />
                <SentryRoute path="/profile/call/overview" render={this.route(ContactCallOverview)} />

                <SentryRoute path="/company/user/overview" render={this.route(UserOverview)} />
                <SentryRoute path="/company/user/:id/edit/:tab?" render={this.route(UserEdit)} />
                <SentryRoute path="/company/user/add/:tab?" render={this.route(UserEdit)} />

                <SentryRoute path="/login/forgot" render={this.route(LoginForgot)} />
                <SentryRoute path="/user/:id/activate/:token" render={this.route(Activate)} />
                <SentryRoute path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />

                <SentryRoute path="/client/overview" render={this.route(ClientOverview)} />
                <SentryRoute path="/client/:id/edit" render={this.route(ClientEdit)} />
                <SentryRoute path="/client/add" render={this.route(ClientEdit)} />
                <SentryRoute path="/client/:clientId/user/:id/edit/:tab?" render={this.route(UserEdit)} />
                <SentryRoute path="/client/:clientId/user/add/:tab?" render={this.route(UserEdit)} />

                <SentryRoute path="/company/device/overview" render={this.route(DeviceOverview)} />

                <SentryRoute path="/company/dial-plan/overview" render={this.route(DialPlanOverview)} />
                <SentryRoute path="/company/dial-plan/add" render={this.route(DialPlanEdit)} />
                <SentryRoute path="/company/dial-plan/:id/edit" render={this.route(DialPlanEdit)} />

                <SentryRoute path="/company/call-group/overview" render={this.route(CallGroupOverview)} />
                <SentryRoute path="/company/call-group/add" render={this.route(CallGroupEdit)} />
                <SentryRoute path="/company/call-group/:id/edit" render={this.route(CallGroupEdit)} />

                <SentryRoute path="/company/call-queue/overview" render={this.route(CallQueueOverview)} />

                <SentryRoute path="/company/contact-list/overview" render={this.route(ContactListOverview)} />


                <SentryRoute path="/contact/overview" render={this.route(ContactOverview)} />
                <SentryRoute path="/contact/add/:phoneNumber?" render={this.route(ContactEdit)} />
                <SentryRoute path="/contact/:id/edit" render={this.route(ContactEdit)} />
                <SentryRoute path="/contact/:contactId/call/overview" render={this.route(ContactCallOverview)} />

                <SentryRoute path="/call/overview" render={this.route(CallOverview)} />

                <SentryRoute path="/company/phone-number/overview" render={this.route(PublicPhoneNumberOverview)} />
                <SentryRoute path="/company/location/overview" render={this.route(LocationOverview)} />
                <SentryRoute path="/company/extension/overview" render={this.route(ExtensionOverview)} />
                <SentryRoute path="/company/media/overview" render={this.route(MediaOverview)} />

                <SentryRoute path="/docs/:path*" render={this.route(Docs)} />

                <SentryRoute path="/integrations/integration/exact/ok" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/exact/error/:error" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/salesforce/ok" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/salesforce/error/:error" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/hubspot/ok" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/hubspot/error/:error" render={this.route(IntegrationsOverview)} />
                <SentryRoute path="/integrations/integration/overview" render={this.route(IntegrationsOverview)} />

                <SentryRoute path="/integrations/error/overview" render={this.route(IntegrationErrorOverview)} />
                <SentryRoute path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)} />
                <SentryRoute path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)} />


                <SentryRoute render={this.route(NotFound)} />
            </Switch>
        );
    }
}
