import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { observable, reaction, computed } from 'mobx';
import { observer } from 'mobx-react';
import NotificationArea from 'component/NotificationArea';
import Login from 'screen/Login';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { Route, withRouter } from 'react-router-dom';
import Router from './Router';
import StartupError from './StartupError';
import RuntimeError from './RuntimeError';
import { ReCyCleTheme, AppContainer, Body, NavMenu, MenuRow, NavItem } from 're-cy-cle';
import * as Sentry from "@sentry/react";
import { theme } from 'styles';
import { Helmet } from 'react-helmet';
import { TAB_TITLE_PREFIX } from 'helpers';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import phone from 'phone';
import Call, { END_STATUSES } from 'container/Call';

import ContactTab from 'container/Tabs/Contact';
import Voicemail from 'container/Tabs/Voicemail';

const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    overflow: hidden;
`;

const SubNavMenu = styled(NavMenu)`
    background-color: #E0E1E2;
    flex: 0 0 auto;
    .nav-item {
        color: rgba(0, 0, 0, 0.6);
        font-size: 1em;
        font-weight: bold;
        &::before {
            border-bottom-color: #f4f4f4;
        }
    }
`;

const SidebarContent = styled.div`
    flex: 0 0 auto;
    ${({open}) => open ? `
        margin-left: 0;
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    ` : `
        margin-left: -300px;
        box-shadow: 0 0 5px 3px transparent;
    `}
    width: 300px;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    overflow: hidden;
    transition: margin-left 300ms ease, box-shadow 300ms ease;
    background-color: #FFF;
    position: relative;
    z-index: 50;
    > div > div {
        display: flex;
        flex-direction: column;
    }
`;

const pathsThatSkipAuthentication = [
    /^\/login\/forgot$/,
    /^\/user\/\d+\/reset-password\/[^/]+$/,
    /^\/user\/\d+\/activate\/[^/]+$/,
];

@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    static childContextTypes = {
        viewStore: PropTypes.object,
    };

    contactSubscription = null;
    favoriteSubscription = null;

    constructor(...args) {
        super(...args);

        this.onTabSelect = this.onTabSelect.bind(this);
        this.renderCall = this.renderCall.bind(this);
        this.renderCompanyMenu = this.renderCompanyMenu.bind(this);
        this.renderIntegrationsMenu = this.renderIntegrationsMenu.bind(this);
    }

    componentDidMount() {
        this.callVisibleReaction = reaction(
            () => this.callVisible,
            (callVisible) => {
                if (callVisible && this.tab === null) {
                    this.tab = 'contacts';
                }
            },
        );
    }

    componentWillUnmount() {
        this.callVisibleReaction();
    }

    @computed get callVisible() {
        return phone.calls.some(({ status }) => !END_STATUSES.includes(status));
    }

    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    componentDidCatch(err) {
        this.hasCrashed = true;
        if (process.env.CY_FRONTEND_SENTRY_DSN) {
            Sentry.captureException(err);
            Sentry.showReportDialog();
        }
    }

    @observable hasCrashed = false;
    @observable tab = JSON.parse(localStorage.rexCurrentTab || '"contacts"');

    onTabSelect(tab) {
        if (tab === this.tab && !this.callVisible) {
            tab = null;
        }
        this.tab = tab;
        localStorage.rexCurrentTab = JSON.stringify(tab);
    }

    renderCall(call) {
        const { currentEngineUser } = this.props.store;

        return (
            <Call
                key={call.id}
                user={currentEngineUser}
                call={call}
            />
        );
    }

    renderIntegrationsMenu() {
        const { currentEngineUser } = this.props.store;

        return (
            <SubNavMenu>
                <MenuRow>
                    {(currentEngineUser.hasPermission('exact.authorize_exactintegration')) && (
                        <React.Fragment>
                            <NavItem
                                title={t('nav.integrations.integration')}
                                to="/integrations/integration/overview"
                                activePath="/integrations/integration/"
                            />
                            <NavItem
                                title={t('nav.integrations.errors')}
                                to="/integrations/error/overview"
                                activePath="/integrations/error/"
                            />
                        </React.Fragment>
                    )}
                </MenuRow>
            </SubNavMenu>
        );
    }

    renderCompanyMenu() {
        const { currentEngineUser } = this.props.store;

        return (
            <SubNavMenu>
                <MenuRow>
                    {(currentEngineUser.hasPermission('client.edit_user')) && (
                        <NavItem
                            title={t('nav.company.users')}
                            to="/company/user/overview"
                            activePath="/company/user/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_device')) && (
                        <NavItem
                            title={t('nav.company.devices')}
                            to="/company/device/overview"
                            activePath="/company/device/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_publicphonenumber')) && (
                        <NavItem
                            title={t('nav.company.publicPhoneNumbers')}
                            to="/company/phone-number/overview"
                            activePath="/company/phone-number/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_callgroup')) && (
                        <NavItem
                            title={t('nav.company.callGroups')}
                            to="/company/call-group/overview"
                            activePath="/company/call-group/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('call_queue.manage_callqueue')) && (
                        <NavItem
                            title={t('nav.company.callQueues')}
                            to="/company/call-queue/overview"
                            activePath="/company/call-queue/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_dialplan')) && (
                        <NavItem
                            title={t('nav.company.dialPlans')}
                            to="/company/dial-plan/overview"
                            activePath="/company/dial-plan/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('contact.manage_contactlist')) && (
                        <NavItem
                            title={t('nav.company.contactLists')}
                            to="/company/contact-list/overview"
                            activePath="/company/contact-list/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_location')) && (
                        <NavItem
                            title={t('nav.company.locations')}
                            to="/company/location/overview"
                            activePath="/company/location/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_extension')) && (
                        <NavItem
                            title={t('nav.company.extensions')}
                            to="/company/extension/overview"
                            activePath="/company/extension/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.manage_media')) && (
                        <NavItem
                            title={t('nav.company.media')}
                            to="/company/media/overview"
                            activePath="/company/media/"
                        />
                    )}
                    {(currentEngineUser.hasPermission('client.settings_client')) && (
                        <NavItem
                            title={t('nav.company.settings')}
                            to="/company/settings"
                            activePath="/company/settings"
                        />
                    )}
                </MenuRow>
            </SubNavMenu>
        );
    }

    render() {
        const {store, location} = this.props;
        const { currentEngineUser } = this.props.store;

        if (this.hasCrashed) {
            return <RuntimeError/>;
        }

        let content = null;
        if (
            store.isAuthenticated ||
            pathsThatSkipAuthentication.some(regex =>
                regex.test(location.pathname)
            )
        ) {
            content = <Router store={store}/>;
        } else if (store.bootstrapCode === 200) {
            content = <Login viewStore={store}/>;
        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode}/>;
        }

        let tab;
        switch (this.tab) {
            case 'contacts':
                tab = <ContactTab store={store} />;
                break;
            case 'voicemail':
                tab = <Voicemail store={store} />;
                break;
            default:
                tab = null;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{TAB_TITLE_PREFIX}</title>
                </Helmet>
                <FlexContainer>
                    {(store.isAuthenticated) && (
                        <AppSidebar
                            selected={this.tab}
                            onSelect={this.onTabSelect}
                            store={store}
                        />
                    )}
                    <AppContainer data-theme={JSON.stringify(theme)}>
                        {store.isAuthenticated && (
                            <ReCyCleTheme theme={theme}>
                                <AppHeader store={store} location={location}/>
                            </ReCyCleTheme>
                        )}
                        <FlexContainer>
                            {store.isAuthenticated && (currentEngineUser.hasPermission('contact.manage_contact')) && (
                                <SidebarContent open={this.tab !== null}>
                                    <Scrollbars autoHide>
                                        {phone.calls.map(this.renderCall)}
                                        {tab}
                                    </Scrollbars>
                                </SidebarContent>
                            )}
                            <FlexContainer direction="column">
                                {store.isAuthenticated && (
                                    <Route
                                        path="/company/"
                                        render={this.renderCompanyMenu}
                                    />
                                )}
                                {store.isAuthenticated && (
                                    <Route
                                        path="/integrations/"
                                        render={this.renderIntegrationsMenu}
                                    />
                                )}
                                <Body>{content}</Body>
                            </FlexContainer>
                        </FlexContainer>
                        {store.currentModal ? (
                            <store.currentModal.render
                                viewStore={store}
                                {...store.currentModal}
                            />
                        ) : null}
                        <NotificationArea store={store}/>
                    </AppContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}
