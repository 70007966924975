import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Image } from 'semantic-ui-react';
import { engineApi } from 'store/Base';
import DefaultAvatar from 'image/default_avatar.png';

@observer
export default class EngineImage extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        defaultSrc: PropTypes.string,
    };

    @observable src = null;

    componentDidMount() {
        this.srcReaction = reaction(
            () => this.props.src,
            (src) => {
                const url = engineApi.getFileUrl(src);
                if (url instanceof Promise) {
                    this.src = null;
                    url.then((res) => this.src = res).catch(() => {});
                } else {
                    this.src = url;
                }
            },
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.srcReaction();
    }

    render() {
        const { defaultSrc, ...props } = this.props;
        delete props.src;

        const src = this.src || defaultSrc;
        return src && (
            <Image src={src} {...props} />
        );
    }
}

@observer
export class EngineAvatar extends EngineImage {
    static defaultProps = {
        avatar: true,
        defaultSrc: DefaultAvatar,
    };
}
