import { tint } from 'polished';

const primaryColor = '#ebbb12';
const secondaryColor = '#2d303b';
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const favoriteColor = '#ebbb12';
export const favoriteIcon = 'star';

export const theme = {
    primaryColor,
    secondaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
};
