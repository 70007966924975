import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { DialPlan } from 'store/DialPlan';
import { CallGroup } from 'store/CallGroup';
import { Device } from 'store/Device';

export const TYPES = [
    'call_group',
    'call_queue',
    'user',
    'dial_plan',
    'device',
];

export const TYPE_ICONS = {
    device: 'phone-alt',
    user: 'user',
    call_group: 'users',
    call_queue: 'call-queue',
    dial_plan: 'th list',
};

export const EXTENSION_TYPE_COLORS = {
    call_group: '#41a85f',
    call_queue: '#ebbb12',
    user: '#ff7f2a',
    dial_plan: '#f55555',
    device: '#ad52f4',
};

export class Extension extends EngineModel {
    static backendResourceName = 'extension';

    @observable id = null;
    @observable extension = '';
    @observable type = null;
    @observable name = null;

    relations() {
        return {
            dialPlan: DialPlan,
            callGroup: CallGroup,
            device: Device,
        };
    }
}

export class ExtensionStore extends EngineStore {
    static backendResourceName = 'extension';
    Model = Extension;
}
