import {observable} from 'mobx';
import {EngineModel, EngineStore} from 'store/Base';
import {EngineClient} from "./EngineClient";
import {Casts} from "./Base";

export class EngineClientCustomInfoRabbitmq extends EngineModel {
    static backendResourceName = 'client_custom_info_rabbitmq';

    @observable id = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable enabled = true;
    @observable connectionError = null;

    @observable customInfoRabbitmqServer = null
    @observable customInfoRabbitmqPort = null
    @observable customInfoRabbitmqUsername = null
    @observable customInfoRabbitmqPassword = null
    @observable customInfoRabbitmqVhost = null
    @observable customInfoRabbitmqQueue = null

    relations() {
        return {
            client: EngineClient
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class EngineClientCustomInfoRabbitmqStore extends EngineStore {
    static backendResourceName = 'client_custom_info_rabbitmq';
    Model = EngineClientCustomInfoRabbitmq;
}
