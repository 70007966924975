import { observable } from 'mobx';
import { EngineModel, EngineStore } from 'store/Base';
import { DeviceStore } from './Device';


export class ProvisioningModel extends EngineModel {
    static backendResourceName = 'provisioning_model';

    @observable id = null;
    @observable voipqId = '';
    @observable brand = '';
    @observable name = '';

    relations() {
        return {
            devices: DeviceStore
        };
    }

    getLines(mac) {
        return this.api.get(`${this.url}lines/${mac}/`);
    }
}

export class ProvisioningModelStore extends EngineStore {
    static backendResourceName = 'provisioning_model';

    Model = ProvisioningModel;
}
