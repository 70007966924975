import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { UserStore } from 'store/User';

export class Client extends Model {
    static backendResourceName = 'client';

    @observable id = null;
    @observable slug = '';
    @observable name = '';
    @observable token = '';
    @observable isReseller = false;
    @observable logo = null;

    relations() {
        return {
            users: UserStore,
        }
    }
}

export class ClientStore extends Store {
    static backendResourceName = 'client';
    Model = Client;
}
