export const SUPPORTED_AUDIO_MIME_TYPES = "audio/mpeg, audio/wav, audio/x-wav"
export const SUPPORTED_IMAGE_MIME_TYPES = "image/jpeg, image/jpg, image/png"
export const MP3_FILE_SIZE_LIMIT = 3 // in megabytes
export const WAV_FILE_SIZE_LIMIT = 6 // in megabytes

export function validateAudioFile(file) { // returns { validation: bool, errorMessage: string }
    if (file.type === 'audio/mpeg') {
        const fileSize = Math.floor(file.size / 1000000) //get file size in megabytes
        if (fileSize < MP3_FILE_SIZE_LIMIT) {
            return { validation: true, errorMessage: '' }
        } else {
            return { validation: false, errorMessage: `MP3 file size must not exceed ${MP3_FILE_SIZE_LIMIT}MB` }
        }
    } else if (file.type === 'audio/wav') {
        const fileSize = Math.floor(file.size / 1000000) //get file size in megabytes
        if (fileSize < WAV_FILE_SIZE_LIMIT) {
            return { validation: true, errorMessage: '' }
        } else {
            return { validation: false, errorMessage: `WAV file size must not exceed ${WAV_FILE_SIZE_LIMIT}MB` }
        }
    }
    return { validation: false, errorMessage: 'Audio file extension not supported. Supported file extensions are MP3 and WAV.' }
}
