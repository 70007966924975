import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import Tooltip from 'component/Tooltip';
import { handleKeys } from 'helpers/keys';
import { ExtensionStore, TYPE_ICONS as EXTENSION_TYPE_ICONS } from 'store/Extension';
import { ContactStore } from 'store/Contact';
import { TargetSelect, TargetTextInput } from 'spider/semantic-ui/Target';
import EngineImage from 'component/EngineImage';
import DefaultAvatar from 'image/default_avatar.png';

const Tab = styled.div`
    height: calc(3em + 1px);
    line-height: 3em;
    padding: 0 1.5em;
    margin-left: 0.5rem;
    margin-bottom: -1px;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem;
    border: 1px solid transparent;
    border-bottom-width: 0;
    ${({ active }) => active ? `
        background-color: #FFF;
        border-color: #E0E0E0;
    ` : `
        cursor: pointer;
        &:hover {
            background-color: #E0E0E0;
        }
        color: rgba(0, 0, 0, 0.6);
    `}
    > i.icon {
        position: relative;
        left: -0.5em;
    }
    transition:
        background-color 300ms ease,
        border-color 300ms ease,
        color 300ms ease;
`;

const Tabs = styled.div`
    margin: -1.5rem -1.5rem 1.5rem;
    padding: 1rem 0.5rem 0;
    display: flex;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem;
    background-color: #F0F0F0;
    border-bottom: 1px solid #E0E0E0;
`;

const EngineAvatar = styled((props) => <EngineImage avatar {...props} />)`
    width: 1.5em !important;
    height: 1.5em !important;
    margin: -0.25em 0.5em -0.25em -0.5em !important;
`;

const TYPES = [
    'user', 'call_group', 'call_queue', 'contact', 'dial_plan', 'device', 'number',
];
const TYPE_ICONS = {
    ...EXTENSION_TYPE_ICONS,
    contact: 'user circle',
    number: 'hashtag',
};

function keysForType(type) {
    const pos = TYPES.indexOf(type);

    return {
        mac: ['meta', (pos + 1).toString()],
        default: ['ctrl', (pos + 1).toString()],
    };
}

const NUMBER_SELECT_KEYS = ['Enter'];

@observer
export default class NumberModal extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    constructor(...args) {
        super(...args);

        this.changeType = this.changeType.bind(this);
        this.renderTab = this.renderTab.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onNumberKeyDown = this.onNumberKeyDown.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.openReaction = reaction(
            () => this.props.open,
            (open) => this.changeType(open ? TYPES[0] : null),
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.openReaction();
    }

    @observable type = null;
    @observable extensionStore = new ExtensionStore({ params: { '.device.hotdesking:not': 'true' } });
    @observable contactStore = new ContactStore({ relations: ['phoneNumbers'] });
    @observable number = null;

    onSelect(number) {
        const { onSelect, onClose } = this.props;
        onClose();
        onSelect(number);
    }

    @action changeType(type) {
        if (type === this.type) {
            return;
        }

        this.extensionStore.clear();
        if (type !== null && type !== 'contact') {
            this.extensionStore.params['.type'] = type;
            this.extensionStore.fetch();
        }

        if (type === 'contact') {
            this.contactStore.fetch();
        } else {
            this.contactStore.clear();
        }

        if (type === 'number') {
            this.number = '';
        } else {
            this.number = null;
        }

        this.type = type;
    }

    onKeyDown(e) {
        return handleKeys(e, TYPES.map((type) => (
            [keysForType(type), () => this.changeType(type)]
        )));
    }

    onNumberKeyDown(e) {
        return (
            handleKeys(e, [
                [NUMBER_SELECT_KEYS, () => this.onSelect(this.number)],
            ]) ||
            this.onKeyDown(e)
        );
    }

    renderTab(type) {
        return (
            <Tooltip keys={keysForType(type)}>
                <Tab
                    key={type}
                    active={this.type === type}
                    onClick={() => this.changeType(type)}
                >
                    <Icon name={TYPE_ICONS[type]} />
                    {t(`numberModal.type.${type}.label`)}
                </Tab>
            </Tooltip>
        );
    }

    render() {
        const { onSelect, ...props } = this.props;

        return (
            <Modal closeIcon {...props}>
                <Modal.Content>
                    <Tabs>{TYPES.map(this.renderTab)}</Tabs>
                    {this.type === 'contact' ? (
                        <TargetSelect fluid noLabel remote
                            key="contactSelect"
                            value={null}
                            onChange={(id) => {
                                const contact = this.contactStore.get(id);
                                // eslint-disable-next-line no-unused-vars
                                for (const phoneNumber of contact.phoneNumbers.models) {
                                    if (phoneNumber.primary) {
                                        this.onSelect(phoneNumber.phoneNumber);
                                        break;
                                    }
                                }
                            }}
                            onKeyDown={this.onKeyDown}
                            store={this.contactStore}
                            toOption={(contact) => ({
                                value: contact.id,
                                text: (
                                    <span>
                                        <EngineAvatar
                                            src={contact.avatar}
                                            defaultSrc={DefaultAvatar}
                                        />
                                        {contact.name}
                                    </span>
                                ),
                            })}
                            searchKey=".name:icontains"
                            contentProps={{ ref: (ref) => {
                                if (ref) {
                                    ref.ref.current.children[0].focus();
                                }
                            } }}
                            selectOnBlur={false}
                        />
                    ) : this.type === 'number' ? (
                        <TargetTextInput fluid noLabel autoFocus
                            value={this.number}
                            onChange={(value) => this.number = value}
                            onKeyDown={this.onNumberKeyDown}
                        />
                    ) : this.type !== null ? (
                        <TargetSelect fluid noLabel remote
                            key="extensionSelect"
                            value={null}
                            onChange={(id) => this.onSelect(this.extensionStore.get(id).extension.toString())}
                            onKeyDown={this.onKeyDown}
                            store={this.extensionStore}
                            toOption={(extension) => ({
                                value: extension.id,
                                text: (
                                    <span>
                                        <Icon name={TYPE_ICONS[extension.type]} />
                                        {' '}
                                        {extension.name}
                                    </span>
                                ),
                            })}
                            searchKey=".name:icontains"
                            contentProps={{ ref: (ref) => {
                                if (ref) {
                                    ref.ref.current.children[0].focus();
                                }
                            } }}
                            selectOnBlur={false}
                        />
                    ) : (
                        null
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}
