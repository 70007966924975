import Phone from 'simple-phone-api/dist/phone';
import { engineApi } from 'store/Base';
import { Contact } from 'store/Contact';
import { observable, action } from 'mobx';
import { showNotification } from 'helpers/notification';
import DefaultAvatar from 'image/default_avatar.png';

let nextCallId = 1;

function getCallId() {
    return nextCallId++;
}

export class RexCall {
    @observable phone = null;
    @observable call = null;
    @observable status = null;

    @observable muted = false;
    @observable onHold = false;
    @observable incoming = false;
    @observable duration = 0;
    @observable participants = [];

    @observable new = true;
    @observable old = false;

    durationInterval = null;
    volumeBeforeMute = 0;

    constructor({ phone, call, status = 'dialing' }) {
        this.id = getCallId();

        this.phone = phone;
        this.call = call;
        this.status = status;
        this.incoming = call.incoming;

        this.answer = this.answer.bind(this);
        this.end = this.end.bind(this);
        this.remove = this.remove.bind(this);
        this.hold = this.hold.bind(this);
        this.unhold = this.unhold.bind(this);
        this.mute = this.mute.bind(this);
        this.unmute = this.unmute.bind(this);
        this.transfer = this.transfer.bind(this);
        this.sendKey = this.sendKey.bind(this);

        call.on('answered', () => {
            this.status = 'active';
            this.durationInterval = setInterval(
                () => this.duration += 1,
                1000,
            );
            this.holdOtherCalls();
        });

        call.on('ended', ({ error }) => {
            if (error === Phone.ERROR.USER_DENIED_MEDIA_ACCESS) {
                showNotification({
                    message: t(`accountDropdown.error.micNotGranted`),
                    dismissAfter: 5000,
                    type: 'error',
                });
                this.status = 'mic_not_granted';
            } else {
                this.status = this.status === 'dialing' ? 'not_answered' : 'ended';
            }

            if (this.durationInterval) {
                clearInterval(this.durationInterval);
            }

            call.off();
            setTimeout(this.remove, 3000);
        });

        call.on('holdchanged', () => this.onHold = this.call.isOnHold());

        setTimeout(() => this.new = false, 0);
    }

    holdOtherCalls() {// eslint-disable-next-line no-unused-vars
        for (const call of this.phone.calls) {
            if (
                call !== this &&
                call.status === 'active' &&
                !call.onHold
            ) {
                call.hold();
            }
        }
    }

    answer() {
        this.holdOtherCalls();
        this.call.answer();
    }

    end() {
        this.call.terminate();
        this.remove();
    }

    remove() {
        this.old = true;
        setTimeout(() => {
            const index = this.phone.calls.indexOf(this);
            if (index !== -1) {
                this.phone.calls.splice(index, 1);
            }
        }, 1000);
    }

    @action mute() {
        if (!this.muted) {
            this.volumeBeforeMute = this.call.getInputVolume();
            this.call.setInputVolume(0);
            this.muted = true;
        }
    }

    @action unmute() {
        if (this.muted) {
            this.call.setInputVolume(this.volumeBeforeMute);
            this.volumeBeforeMute = null;
            this.muted = false;
        }
    }

    hold() {
        this.call.hold();
    }

    unhold() {
        this.holdOtherCalls();
        this.call.unhold();
    }

    transfer(number) {
        this.call.transferCall(number);
    }

    sendKey(key) {
        this.call.sendKey(key);
    }
}

async function getParticipant(number) {
    const contact = new Contact();

    // Hack so that mobx-spine allows fetching on contact without id
    contact.id = 1;
    const promise = contact.fetch({
        url: `${contact.urlRoot()}caller_id/`,
        data: { number },
        skipRequestError: true,
    });
    contact.id = null;
    await promise;

    return { contact, number };
}

/**
 * Singleton that Handles the whole state of the phone
 */
class RexPhone {
    @observable phone = null;
    @observable status = 'ready';
    @observable user = null;

    @observable calls = [];

    constructor() {
        this.call = this.call.bind(this);
    }

    config() {
        return engineApi.get("user/config/").then(data => {
            this.phone = new Phone.Phone(data);
            this.phone.on('created', data => {
                const call = new RexCall({
                    phone: this,
                    call: data.phoneCall,
                    status: 'dialing',
                });
                this.calls.push(call);
            });

            this.phone.on('incoming', async data => {
                const call = this.calls.find((call) => call.call === data.phoneCall);
                if (call) {
                    const caller = await getParticipant(data.remote_caller_id);
                    call.participants.push(caller);

                    if (document.visibilityState !== 'visible') {
                        const name = caller.contact.name || caller.contact.company.name;

                        let url = DefaultAvatar

                        if (caller.contact.avatar) {
                            const index = caller.contact.avatar.indexOf('?')
                            const data = Buffer.from(await engineApi.get(caller.contact.avatar.slice('/api'.length, index), undefined, { responseType: 'arraybuffer' })).toString('base64');
                            const params = new URLSearchParams(caller.contact.avatar.slice(index + 1));
                            const contentType = params.get('content_type');
                            url = `data:${contentType};base64,${data}`
                        }

                        const notification = new Notification(t('call.incoming'), {
                            body: name ? `${name} (${caller.number})` : caller.number,
                            icon: url,
                        });

                        call.call.on('ended', () => notification.close());
                        notification.onclick = function(event) {
                            event.preventDefault();
                            window.focus(); event.target.close(); //When clicking the notification it should focus on the triggering window
                        }

                        function onVisibilityChange() {
                            if (document.visibilityState === 'visible') {
                                notification.close();
                                document.removeEventListener('visibilitychange', onVisibilityChange);
                            }
                        }
                        document.addEventListener('visibilitychange', onVisibilityChange);
                    }
                }
            });

            this.phoneSounds = new Phone.PhoneSounds();
            this.phoneSounds.addToPhone(this.phone);
            this.phoneSounds.setVolume(0.2);
        });
    }

    @action async call(number) {
        let destinationNumber = number;

        let device = this.user.activeDevice;
        if (device.isNew) {
            device = this.user.devices.find(({ type }) => type === 'REX');
        }
        number = device.type !== 'REX' ? device.extensionNumber : number;

        const phoneCall = this.phone.startCall(number, {
            // extraHeaders: [],
        });
        phoneCall.call_id = 1;

        const call = this.calls.find((call) => call.call === phoneCall);
        if (call) {
            try {
                call.participants.push(await getParticipant(number.toString()));
            } catch (noParticipantContact) {
                console.log('Missing participant contact:', noParticipantContact);
            }

            if (number !== destinationNumber) {

                call.call.on('answered', () => {
                    call.transfer(destinationNumber.toString());
                });
            }
        }
    }
}

const phone = new RexPhone();
export default phone;
