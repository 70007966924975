import { action, observable, computed } from 'mobx';
import { CallGroupCallerId } from 'store/CallGroupCallerId';
import { EngineModel, EngineStore } from './Base';
import { Contact } from 'store/Contact';
import { isArray } from 'lodash';
import { Device, DeviceStore } from 'store/Device';
import { Location } from 'store/Location';
import { DialPlan } from 'store/DialPlan';
import { Extension } from 'store/Extension';
import { CalendarAccountStore } from 'store/CalendarAccount';
import { VoicemailBox } from 'store/VoicemailBox';

export const STATUSES = [
    'available',
    'available_internal',
    'follow_up',
    'training',
    'lunch',
    'bathroom',
    'meeting',
    'away_from_desk',
    'do_not_disturb',
    'out_of_office',
    'on_holiday',
    'not_available',
];

export class Permission extends EngineModel {
    static backendResourceName = 'permission';

    @observable id = null;
    @observable app = '';
    @observable codename = '';
    @observable permissions = [];
}

export class PermissionStore extends EngineStore {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends EngineModel {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            permissions: PermissionStore,
        };
    }
}

export class GroupStore extends EngineStore {
    Model = Group;
    static backendResourceName = 'group';
}

export class EngineUser extends EngineModel {
    static backendResourceName = 'user';
    static VOICEMAIL_NOTIFICATIONS = ['user', 'override', 'do_not_send'];

    @observable id = null;
    @observable baseStatus = 'available';
    @observable deleted = false;
    @observable isEnabled = true;
    @observable activeSessions = [];
    @observable offlineStatuses = [];
    @observable statusAvailabilities = {};
    @observable callAnonymous = false;
    @observable callAnonymousOverride = false;

    @observable recordCalls = null;
    @observable recordCallsOverride = null;
    @observable agentAvailableUserStatuses = null;

    @observable voicemailNotification = 'user';
    @observable voicemailNotificationOverrideEmails = [];

    @observable hotdesking = false;
    @observable hotdeskPin = '0000';

    @observable callRestrictionOverride = null;

    // Annotations
    @observable extensionNumber = null;

    @computed get isOnline() {
        return this.activeSessions.length > 0;
    }

    @computed get status() {
        let status = ''

        if (this.isOnline || this.offlineStatuses.includes(this.baseStatus)) {
            status = this.baseStatus
        } else if (this.registeredDevice) {
            status = 'available_device'
        } else {
            status = 'not_available'
        }

        return status;
    }

    @computed get registeredDevice() {
        let regedDevice = false
        // eslint-disable-next-line no-unused-vars
        for (const device of this.devices.models) {
            if (device.isRegistered) {
                regedDevice = true
                    break;
                }
            }

        return regedDevice;
    }

    @computed get baseAvailability() {
        return this.statusAvailabilities[this.baseStatus] || 'none';
    }

    @computed get availability() {
        return this.statusAvailabilities[this.status] || 'none';
    }

    @computed get name() {
        return this.contact.name;
    }

    @computed get permissions() {
        const perms = [];

        this.groups.forEach(group =>
            group.permissions.forEach(highLevelPermission => {
                perms.push(`${highLevelPermission.app}.${highLevelPermission.codename}`);

                highLevelPermission.permissions.forEach(p =>
                    perms.push(`${p.name}:${p.scope}`)
                )
            })
        );

        return perms;
    }

    hasPermission(permissions) {
        if (this.isSuperuser) {
            return true;
        }
        if (!isArray(permissions)) {
            return this.permissions.includes(permissions);
        }
        return this.permissions.some(groupName =>
            permissions.includes(groupName)
        );
    }

    changeStatus(status) {
        return (
            this.api
            .put(this.url, { base_status: status })
            .then(action((res) => {
                this.baseStatus = status;
                this.__changes = this.__changes.filter((f) => f !== 'baseStatus');
                return Promise.resolve(res);
            }))
        );
    }

    relations() {
        return {
            callerId: CallGroupCallerId,
            contact: Contact,
            location: Location,
            devices: DeviceStore,
            activeDevice: Device,
            calendars: CalendarAccountStore,
            dialPlan: DialPlan,
            groups: GroupStore,
            extension: Extension,
            voicemailBox: VoicemailBox,
        };
    }

    @action parse(...args) {
        const res = super.parse(...args);
        if (this.voicemailNotificationOverrideEmails === null) {
            this.voicemailNotificationOverrideEmails = [];
        }
        return res;
    }

    toBackend(...args) {
        const res = super.toBackend(...args);
        delete res.extension_number;
        if (this.voicemailNotification !== 'override') {
            res.voicemail_notification_override_emails = null;
        }
        if (!this.hotdesking) {
            res.hotdesk_pin = null
        }
        return res;
    }
}

export class EngineUserStore extends EngineStore {
    static backendResourceName = 'user';
    Model = EngineUser;
}
