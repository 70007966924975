import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { engineApi } from 'store/Base';
import { Contact, ContactStore } from 'store/Contact';
import { CallGroupStore } from 'store/CallGroup';
import UserInfo, { UserContainer, UserDetails, UserName, UserStatus } from 'component/UserInfo';
import styled from 'styled-components';
import { Popup, Icon, Input, Dimmer, Loader } from 'semantic-ui-react';
import { Model } from 'store/Base';
import { snakeToCamel } from 'helpers';
import { favoriteColor, favoriteIcon } from 'styles';
import phone from 'phone';
import UnknownAvatar from 'image/unknown_avatar.png';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { debounce, pick } from 'lodash';
import CONTACT_PLACEHOLDER from 'image/contact_placeholder.png';
import { FullDimmable } from 'spider/semantic-ui/Admin/Overview';
import Callable from 'component/Callable';
import { DeviceStore } from 'store/Device';
import { CallQueueStore } from 'store/CallQueue';
import { DialPlanStore } from 'store/DialPlan';
import RightDivider from 'spider/component/RightDivider';
import { TYPE_ICONS as EXTENSION_TYPE_ICONS, EXTENSION_TYPE_COLORS } from 'store/Extension';
import { StyledVoicemailBox } from './Voicemail';
import subscribe from 'decorator/subscribe';


const LABEL_HEIGHT = 20;
const CONTACT_HEIGHT = 50;
const NUMBER_HEIGHT = 40;
const MARGIN = 5 * CONTACT_HEIGHT;

export const StyledCallable = styled(Callable)`
    &.ui.button.ui.button.ui.button.ui.button {
        -webkit-touch-callout: all;
        -webkit-user-select: all;
        -khtml-user-select: all;
        -moz-user-select: all;
        -ms-user-select: all;
        user-select: all;
        padding: calc(0.5833em - 1px) 0.833em calc(0.5833em - 1px) 2.75em !important;
        > i.icon {
            width: 2.1666em !important;
        }
        margin-right: 0 !important;
        margin-left: 0.75rem !important;
        transition: transform 300ms ease;
        line-height: 1;
    }
`;

class SpecialContactStore extends ContactStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialCallGroupStore extends CallGroupStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialCallQueueStore extends CallQueueStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialDialPlanStore extends DialPlanStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialDeviceStore extends DeviceStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

const DIAL_KEYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#',null,'+'];
const DIAL_KEY_ICONS = {
    '*': 'asterisk',
    '#': 'hashtag',
};

const MODEL_STORES = {
    call_group: SpecialCallGroupStore,
    call_queue: SpecialCallQueueStore,
    dial_plan: SpecialDialPlanStore,
    device: SpecialDeviceStore,
};

const MODEL_RELATIONS = {
    call_group: [],
    call_queue: [],
    dial_plan: [],
    device: [],
};
const CONTACT_RELATIONS = [
    'user.devices.extension',
    'user.extension',
    'company',
    'phoneNumbers',
];

const MODEL_ORDER_BY = {
    call_group: 'name,id',
    call_queue: 'name,id',
    dial_plan: 'name,id',
    device: 'name,id',
};
const CONTACT_ORDER_BY = 'availability_order,last_name,first_name,id';

const STORE_ICONS = {
    ...EXTENSION_TYPE_ICONS,
    users: 'user',
    call_group_list: 'users',
    contact_list: 'list',
    company: 'building',
};

const ContactPlaceholder = styled.div`
    background-image: url(${CONTACT_PLACEHOLDER});
    background-size: 250px 50px;
    background-repeat: repeat-y;
`;

const NoResults = styled.div`
    padding: 0 10px;
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    line-height: 1;
    margin-top: ${({ hide }) => hide ? 0 : 10}px;
    height: ${({ hide }) => hide ? 0 : 1.5}rem;
    opacity: ${({ hide }) => hide ? 0.5 : 1};
    overflow: hidden;
    transition: margin-top 300ms ease, height 300ms ease, opacity 300ms ease;
`;

const GroupNumbers = styled.div`
    height: ${({ hide, numberLines }) => hide ? 0 : NUMBER_HEIGHT * numberLines}px;
    transition: opacity 300ms ease, height 300ms ease;
    overflow: hidden;
`;

const ContactDimmer = styled(Dimmer)`
    background-color: rgba(255, 255, 255, 0.65) !important;
`;

const ExpandIcon = styled(Icon)`
    margin-right: 0 !important;
    float: right;
    cursor: pointer;
    opacity: 0.5 !important;
    &:hover {
        opacity: 1 !important;
    }
    transition: opacity 300ms ease;
`;

const ClearButton = styled(Icon)`
    position: absolute;
    top: 0;
    right: calc(1.625rem * 1 + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5rem !important;
    font-size: 1.1em !important;
    color: rgba(0, 0, 0, 0.435) !important;
    opacity: ${({ active, searchFocus }) => !active ? 0 : !searchFocus ? 0.6 : 1} !important;
    ${({ active }) => active ? `` : `
        pointer-events: none;
    `}
    transition: color 300ms ease, opacity 300ms ease, transform 300ms ease;
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
`;

const DialKeyButton = styled(Icon)`
    position: absolute;
    top: 0;
    right: calc(1.625rem * ${({ clearable }) => clearable ? 2 : 1} + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5rem !important;
    font-size: 1.1em !important;
    color: ${({ active }) => active ? favoriteColor : 'rgba(0, 0, 0, 0.435)'} !important;
    opacity: ${({ searchFocus }) => searchFocus ? 1 : 0.6} !important;
    transition: color 300ms ease, opacity 300ms ease, transform 300ms ease;
    &:hover {
        opacity: 1 !important;
    }
    cursor: pointer;
`;

const FavoriteToggle = styled(({ active, ...props }) => <Icon name={favoriteIcon} {...props} />)`
    position: absolute;
    top: 0;
    right: 0;
    right: calc(1.625rem * 0 + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5em !important;
    color: ${({ active }) => active ? favoriteColor : 'rgba(0, 0, 0, 0.435)'} !important;
    cursor: pointer;
    opacity: ${({ searchFocus }) => searchFocus ? 1 : 0.6} !important;
    &:hover {
        opacity: 1 !important;
    }
    transition: color 500ms ease, transform 300ms ease, opacity 300ms ease;
`;

const CallDialKeysContainer = styled.div`
    margin: 5px -5px -5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CallDialKeys = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template: 2em 2em 2em 2em 2em / 3em 3em 3em;
    grid-gap: 5px;
`;

const CallDialKey = styled.div`
    font-weight: bold;
    text-align: center;
    width: 3em;
    height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.125);
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.25);
    }
    transition: background-color 300ms ease;
    > i.icon {
        font-size: 0.75em;
        line-height: 1;
        margin: 0 !important;
    }
`;

const SearchContainer = styled.div`
    > .ui.icon.input {
        > input {
            padding-right: 5.79642857em !important
            border: unset !important;
            background-color: #E0E0E0 !important;
            border-radius: 999px !important;
        }
        > i.icon {
            opacity: 0.5 !important;
            right: calc(1.625rem * ${({ clearable }) => clearable ? 3 : 2} + 0.523214285rem);
            width: 1.625rem !important;
            transition: right 300ms ease;
        }
        opacity: ${({ hasFocus }) => hasFocus ? 1 : 0.6} !important;
        transition: opacity 300ms ease;
    }
    position: relative;
    margin: 10px;
`;

const ContactsContainer = styled.div`
    flex: 1 1 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        ${({ scrolled }) => scrolled ? `
            box-shadow: inset 0 14px 18px -14px rgba(0, 0, 0, 0.125);
        ` : ``}

        transition: box-shadow 300ms ease;
    }
`;

const GroupContact = styled.div`
    height: ${({ numberLines = 0 }) => CONTACT_HEIGHT + numberLines * NUMBER_HEIGHT}px;
    opacity: 1;
    overflow: hidden;
    transition: height 300ms ease, opacity 300ms ease;
`;

const GroupNumber = styled.div`
    height: ${NUMBER_HEIGHT}px;
    opacity: 1;
    overflow: hidden;
    display: inline-block;
    transition: height 300ms ease, opacity 300ms ease;
`;

const StoreIcon = styled(Icon)`
    color: #fff;
    background-color: ${({ color }) => color};
    width: 40px !important;
    height: 40px !important;
    border-radius: 9999px !important;
    margin-right: 0.25rem !important;
    font-size: 1.33rem !important;
    line-height: 40px !important;
`;

const FlatLink = styled(Link)`
    line-height: 1;
`;

const IconButton = styled(Icon)`
    font-size: 1.25em !important;
    color: rgba(0, 0, 0, 0.6);
    ${({ clickable, onClick }) => (clickable !== undefined ? clickable : onClick) ? `
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    ` : `
        opacity: 0.5 !important;
    `}
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
    transition: transform 300ms ease;
    line-height: 1;
`;

@observer
class CallButton extends Component {
    static propTypes = {
        internal: PropTypes.bool,
        number: PropTypes.string,
    };

    static defaultProps = {
        internal: false,
    };

    @computed get internalPhone() {
        return (
            window.viewStore.currentEngineUser.activeDevice.callerId.isNew &&
            window.viewStore.currentEngineUser.callerId.isNew &&
            window.viewStore.currentEngineClient.callerId.isNew
        );
    }

    render() {
        const { number, internal } = this.props;

        const clickable = number && (internal || !this.internalPhone);
        let button = (
            <IconButton
                name="phone"
                clickable={clickable}
                onClick={clickable ? () => phone.call(number) : undefined}
            />
        );

        if (number && !internal && this.internalPhone) {
            button = (
                <Popup
                    trigger={button}
                    content={t('contactTab.internalOnly')}
                />
            );
        }

        if (!clickable) {
            button = (
                <Popup
                    trigger={button}
                    content={t('contactTab.notAvailable')}
                />
            );
        }

        return button;
    }
}

export function updateStore(store, { added = [], updated = [], deleted = [] }) {
    store.models = store.models.filter((m) => !deleted.includes(m.id));
    // eslint-disable-next-line no-unused-vars
    for (const { id, ...changes } of added + updated) {
        let model = store.get(id);
        if (!model) {
            model = store.add();
            changes.id = id;
        }
        updateModel(model, changes);
    }
    store.removeById(deleted);
}

export function updateModel(model, changes) {
    const rels = model.relations();
    // eslint-disable-next-line no-unused-vars
    for (let [field, value] of Object.entries(changes)) {
        field = snakeToCamel(field);
        if (rels[field]) {
            if (model.__activeCurrentRelations.includes(field)) {
                if (rels[field].prototype instanceof Model) {
                    updateModel(model[field], value);
                } else {
                    updateStore(model[field], value);
                }
            }
        } else {
            model[field] = value;
        }
    }
}

@observer
class ContactItem extends Component {
    static propTypes = {
        contact: PropTypes.object.isRequired,
    };

    @observable hiddenNumbers = true;

    renderNumber({ number, device, disabled }) {
        return (
            <GroupNumber key={number}>
                <StyledCallable
                    number={ number }
                    device={ device }
                    disabled={disabled}
                />
            </GroupNumber>
        );
    }

    render() {
        const { contact } = this.props;

        let phoneNumber = null;
        const key = (`contact_${contact.id}`);
        const contactNumbers = [];
        let numberLinesCount = 0;
        let pnCount = 0;
        let extensionCount = 0;

        if (!contact.user.isNew) {
            phoneNumber = contact.user.extensionNumber;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const pn of contact.phoneNumbers.models) {
                if (pn.primary) {
                    phoneNumber = pn.phoneNumber;
                    break;
                }
            }
        }

        if (!contact.user.isNew) {
            contactNumbers.push({number: contact.user.extension});
            extensionCount ++
        } // eslint-disable-next-line no-unused-vars
        for (const number of contact.user.devices.models) {
            contactNumbers.push({ number: number.extension, device: number, disabled: !number.isRegistered});
                extensionCount++;
        } // eslint-disable-next-line no-unused-vars
        for (const number of contact.phoneNumbers.models) {
            contactNumbers.push({ number });
                pnCount++;
        }

        numberLinesCount = Math.round(extensionCount / 3) + Math.round(pnCount / 2)

        return (
            <GroupContact
                key={key}
                numberLines={this.hiddenNumbers ? 0 : numberLinesCount}
            >
                <UserInfo
                    user={contact}
                    to={`/contact/${contact.id}/call/overview`}
                >
                    <React.Fragment key={key}>
                        {
                            numberLinesCount ? (
                                <ExpandIcon
                                    name={this.hiddenNumbers ? 'chevron down' : 'chevron up'}
                                    onClick={() => {this.hiddenNumbers = !this.hiddenNumbers}}
                                />
                              ) : ''
                        }
                        <CallButton
                            internal={!contact.user.isNew}
                            number={phoneNumber}
                        />
                    </React.Fragment>
                </UserInfo>
                <GroupNumbers
                    numberLines={numberLinesCount}
                    hide={this.hiddenNumbers} >

                    {contactNumbers.map(this.renderNumber)}
                </GroupNumbers>
            </GroupContact>
        );
    }
}

class CallGroupItem extends Component {
    static propTypes = {
        callGroup: PropTypes.object.isRequired,
    };

    render() {
        const { callGroup } = this.props;

        return (
            <GroupContact>
                <UserContainer>
                    <StoreIcon
                        color={EXTENSION_TYPE_COLORS.call_group}
                        name={STORE_ICONS.call_group}
                    />
                    {' '}
                    <UserDetails>
                        <UserName>
                            {callGroup.name}
                        </UserName>
                        <UserStatus>
                            {t('callGroup.field.extension.label')}
                            {' '}
                            {callGroup.extensionNumber}
                        </UserStatus>
                    </UserDetails>
                    <RightDivider />
                    <CallButton number={callGroup.extensionNumber} />
                </UserContainer>
            </GroupContact>
        );
    }
}

class CallQueueItem extends Component {
    static propTypes = {
        callQueue: PropTypes.object.isRequired,
    };

    render() {
        const { callQueue } = this.props;

        return (
            <GroupContact>
                <UserContainer>
                    <StoreIcon
                        color={EXTENSION_TYPE_COLORS.call_queue}
                        name={STORE_ICONS.call_queue}
                    />
                    {' '}
                    <UserDetails>
                        <UserName>
                            {callQueue.name}
                        </UserName>
                        <UserStatus>
                            {t('callQueue.field.extension.label')}
                            {' '}
                            {callQueue.extensionNumber}
                        </UserStatus>
                    </UserDetails>
                    <RightDivider />
                    <CallButton number={callQueue.extensionNumber} />
                </UserContainer>
            </GroupContact>
        );
    }
}

class DialPlanItem extends Component {
    static propTypes = {
        dialPlan: PropTypes.object.isRequired,
    };

    render() {
        const { dialPlan } = this.props;

        return (
            <GroupContact>
                <UserContainer>
                    <StoreIcon
                        color={EXTENSION_TYPE_COLORS.dial_plan}
                        name={STORE_ICONS.dial_plan}
                    />
                    {' '}
                    <UserDetails>
                        <UserName>
                            {dialPlan.name}
                        </UserName>
                        <UserStatus>
                            {t('dialPlan.field.extension.label')}
                            {' '}
                            {dialPlan.extensionNumber}
                        </UserStatus>
                    </UserDetails>
                    <RightDivider />
                    <CallButton number={dialPlan.extensionNumber} />
                </UserContainer>
            </GroupContact>
        );
    }
}

class DeviceItem extends Component {
    static propTypes = {
        device: PropTypes.object.isRequired,
    };

    render() {
        const { device } = this.props;

        return (
            <GroupContact>
                <UserContainer>
                    <StoreIcon
                        color={EXTENSION_TYPE_COLORS.device}
                        name={STORE_ICONS.device}
                    />
                    {' '}
                    <UserDetails>
                        <UserName>
                            {device.name}
                        </UserName>
                        <UserStatus>
                            {t('device.field.extension.label')}
                            {' '}
                            {device.extensionNumber}
                        </UserStatus>
                    </UserDetails>
                    <RightDivider />
                    <CallButton number={device.extensionNumber} />
                </UserContainer>
            </GroupContact>
        );
    }
}

@subscribe
@observer
export default class ContactTab extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    @observable stores = [];
    @observable loadingLists = true;

    @observable search = '';
    @observable favoriteFilter = false;
    @observable searchFocus = false;
    @observable hidden = JSON.parse(localStorage.getItem('rexHiddenContacts') || '{}');
    @observable dialKeys = false;

    @observable scrollView = { top: 0, bottom: 0 };

    constructor(...args) {
        super(...args);

        this.onScroll = this.onScroll.bind(this);
        this.fetchLists = this.fetchLists.bind(this);
        this.debouncedFetchLists = debounce(this.fetchLists, 300);
        this.fetchStores = this.fetchStores.bind(this);
        this.debouncedFetchStores = debounce(this.fetchStores, 300);
        this.renderContact = this.renderContact.bind(this);
        this.renderStore = this.renderStore.bind(this);
    }

    componentDidMount() {
        this.fetchLists({ fullLoad: false });

        this.favoriteSubscription = this.subscribe(
            { type: 'favorite_change', user: window.viewStore.currentEngineUser.id },
            action(({ data: { contact: contactId, favorite } }) => { // eslint-disable-next-line no-unused-vars
                for (const { store } of this.stores) {
                    const contact = store.get(contactId);
                    if (contact) {
                        contact.favorite = favorite;
                    }
                }
                if (this.favoriteFilter) {
                    this.fetchLists({ showLoad: false });
                }
            }),
        );

        this.contactSubscription = this.subscribe(
            {
                type: 'contact_change',
                client: window.viewStore.currentEngineClient.id,
                contact: '*',
            },
            action(({ data: { added = [], ...data } }) => { // eslint-disable-next-line no-unused-vars
                for (const { store } of this.stores) {
                    updateStore(store, data);
                }
                if (
                    // Set change
                    added.length > 0 ||
                    (data.deleted && data.deleted.length > 0) ||
                    // Data change
                    data.updated.some(({ id, ...changes }) => (
                        // Ordering change
                        (changes.user && (
                            changes.user.base_status ||
                            changes.user.is_enabled ||
                            changes.user.active_sessions ||
                            changes.first_name ||
                            changes.last_name
                        )) ||
                        // Phone number search change
                        (this.phoneNumberSearch && changes.phone_numbers) ||
                        // Standard search change
                        (this.search && !this.phoneNumberSearch && (
                            changes.first_name ||
                            changes.last_name ||
                            (changes.company && changes.company.name)
                        ))
                    ))
                ) {
                    this.fetchLists({ showLoad: false });
                }
            }),
        );
    }

    componentWillUnmount() {
        this.favoriteSubscription.unsubscribe();
        this.contactSubscription.unsubscribe();
    }

    fetchLists({ fullLoad = true, showLoad = true } = {}) {
        if (showLoad) {
            this.loadingLists = true;
        }

        return (
            engineApi.get('contact/lists/', this.filter)
            .then(action((lists) => {
                const stores = lists.map(({ type, id, name, count }) => {
                    const TypeStore = MODEL_STORES[type] || SpecialContactStore;
                    const data = {
                        type,
                        store: new TypeStore({
                            relations: MODEL_RELATIONS[type] || CONTACT_RELATIONS,
                            params: {
                                order_by: MODEL_ORDER_BY[type] || CONTACT_ORDER_BY,
                                ...(
                                    MODEL_STORES[type]
                                    ? pick(this.filter, 'search')
                                    : this.filter
                                ),
                            },
                        }),
                    };
                    data.store.__state.totalRecords = count;
                    /*eslint-disable*/
                    switch (type) {
                        case 'users':
                            data.store.params['.user:not:isnull'] = true;
                            data.store.params['.user.is_enabled'] = true;
                            break;
                        case 'call_group_list':
                            data.id = id;
                            data.name = name;
                            data.store.params['.call_group.id'] = id;
                            break;
                        case 'contact_list':
                            data.id = id;
                            data.name = name;
                            data.store.params['.contact_list.id'] = id;
                            break;
                        case 'company':
                            data.store.params['.user:isnull'] = true;
                            data.store.params['.call_group:isnull'] = true;
                            data.store.params['.contact_list:isnull'] = true;
                            break;
                        case 'dial_plan':
                            data.store.params['.user:isnull'] = true;
                            break;
                    }/*eslint-enable*/

                    if (data.store.params.order_by.split(',').includes('availability_order')) {
                        data.store.params.include_annotations = '*,availability_order'
                    }
                    return data;
                });

                let promise = this.fetchStores(stores);
                if (fullLoad) {
                    return promise.then(() => this.stores = stores);
                } else {
                    this.stores = stores;
                }
            }))
            .finally(() => {
                if (showLoad) {
                    this.loadingLists = false;
                }
            })
        );
    }

    @computed get filter() {
        const filter = {};

        if (this.favoriteFilter) {
            filter['.favorited_by'] = window.viewStore.currentEngineUser.id;
        }

        if (this.phoneNumberSearch) {
            filter['.phone_numbers.phone_number:startswith'] = this.phoneNumberSearch;
        } else if (this.search) {
            filter['search'] = this.search;
        }

        return filter;
    }

    @action fetchStores(stores) {
        let { top, bottom } = this.scrollView;

        if (stores === undefined) {
            stores = this.stores;
        }

        let promises = [];
        // eslint-disable-next-line no-unused-vars
        for (const { type, id, store } of stores) {
            const key = id ? `${type}:${id}` : type;

            let limit, offset;
            if (this.hidden[key]) {
                limit = 0;
                offset = 0;
            } else {
                limit = Math.ceil((bottom - top + 2 * MARGIN) / CONTACT_HEIGHT);
                offset = Math.floor((top - LABEL_HEIGHT - MARGIN) / CONTACT_HEIGHT);
                if (offset < 0) {
                    limit += offset;
                    offset = 0;
                }
                if (limit <= 0 || offset >= store.__state.totalRecords) {
                    limit = 0;
                    offset = 0;
                }
            }

            if (store.params.limit !== limit || store.params.offset !== offset) {
                store.params.limit = limit;
                store.params.offset = offset;
                if (store.params.limit === 0) {
                    store.clear();
                    store.fetch();
                } else {
                    promises.push(store.fetch());
                }
            }

            const height = 20 + (this.hidden[key] ? 0 : store.__state.totalRecords * 50);
            top = Math.max(top - height, 0);
            bottom = Math.max(bottom - height, 0);
        }

        return Promise.all(promises);
    }

    @action onScroll(e) {
        this.scrollView.top = e.target.scrollTop;
        this.scrollView.bottom = this.scrollView.top + e.target.clientHeight;
        this.debouncedFetchStores();
    }

    @computed get phoneNumberLocalRegExp() {
        const prefix = window.viewStore.currentEngineUser.location.phoneNumberPrefix;
        const min = Math.max(1 - (prefix.length - 1), 0);
        const max = Math.max(14 - (prefix.length - 1), 0);
        const regexp = new RegExp(`^0\\d{${min},${max}}$`);
        return regexp;
    }

    @computed get phoneNumberSearch() {
        return (
            /^\+[1-9]\d{1,14}$/.test(this.search)
            ? this.search
            : this.phoneNumberLocalRegExp.test(this.search)
            ? (
                window.viewStore.currentEngineUser.location.phoneNumberPrefix +
                this.search.slice(1)
            )
            : null
        );
    }

    renderContact(contact) {
        return (
            <ContactItem key={contact.cid} contact={contact} />
        );
    }

    renderCallGroup(callGroup) {
        return (
            <CallGroupItem key={callGroup.cid} callGroup={callGroup} />
        );
    }

    renderCallQueue(callQueue) {
        return (
            <CallQueueItem key={callQueue.cid} callQueue={callQueue} />
        );
    }

    renderDialPlan(dialPlan) {
        return (
            <DialPlanItem key={dialPlan.cid} dialPlan={dialPlan} />
        );
    }

    renderDevice(device) {
        return (
            <DeviceItem key={device.cid} device={device} />
        );
    }

    renderStore({ type, id, name, store }) {
        const key = id ? `${type}:${id}` : type;
        const contacts = [];

        if (!this.hidden[key]) {
            contacts.push(
                <ContactPlaceholder
                    key="placeholder-start"
                    style={{ height: CONTACT_HEIGHT * store.currentScope.offset }}
                />
            ); // eslint-disable-next-line no-unused-vars
            for (const contact of store.models) {
                contacts.push(
                    MODEL_STORES[type]
                    ? this[snakeToCamel(`render_${type}`)](contact)
                    : this.renderContact(contact)
                );
            }
            contacts.push(
                <ContactPlaceholder
                    key="placeholder-end"
                    style={{ height: CONTACT_HEIGHT * (store.__state.totalRecords - store.currentScope.offset - store.length) }}
                />
            );
        }

        return (
            <React.Fragment key={key}>
                <StyledVoicemailBox>
                    <Icon name={STORE_ICONS[type]} />
                    {' '}
                    {name ? (
                        name
                    ) : type === 'company' ? (
                        window.viewStore.currentEngineClient.name
                    ) : (
                        t(`contactTab.label.${type}`)
                    )}
                    {` (${store.__state.totalRecords})`}
                    <ExpandIcon
                        name={this.hidden[key] ? 'chevron down' : 'chevron up'}
                        onClick={action(() => {
                            if (this.hidden[key]) {
                                delete this.hidden[key];
                            } else {
                                this.hidden[key] = true;
                            }
                            this.fetchStores();
                            localStorage.setItem('rexHiddenContacts', JSON.stringify(this.hidden));
                        })}
                    />
                </StyledVoicemailBox>
                {contacts}
            </React.Fragment>
        );
    }

    containerNode = null;

    render() {
        return (
            <React.Fragment>
                <SearchContainer hasFocus={this.searchFocus} clearable={this.search !== ''}>
                    <Input fluid autoFocus
                        hasFocus={this.searchFocus}
                        icon="search"
                        value={this.search}
                        onChange={(e, { value }) => {
                            this.search = value;
                            this.debouncedFetchLists();
                        }}
                        onFocus={() => this.searchFocus = true}
                        onBlur={() => this.searchFocus = false}
                        ref={(node) => this.searchInput = node}
                        placeholder={t('nav.search.placeholder')}
                    />
                    <ClearButton
                        name="delete"
                        searchFocus={this.searchFocus}
                        active={this.search !== ''}
                        onClick={(e) => {
                            this.search = '';
                            this.searchInput.focus();
                            this.fetchLists();
                        }}
                    />
                    <DialKeyButton
                        name="dialpad"
                        searchFocus={this.searchFocus}
                        clearable={this.search !== ''}
                        active={this.dialKeys}
                        onClick={() => this.dialKeys = !this.dialKeys}
                    />
                    {this.dialKeys && (
                        <CallDialKeysContainer>
                            <CallDialKeys>
                                {DIAL_KEYS.map((key) => (
                                    key === null ? <div /> : (
                                        <CallDialKey onClick={() => {
                                            this.search += key
                                            this.debouncedFetchLists();
                                        }}>
                                            {DIAL_KEY_ICONS[key] ? <Icon name={DIAL_KEY_ICONS[key]} /> : key}
                                        </CallDialKey>
                                    )
                                ))}
                            </CallDialKeys>
                        </CallDialKeysContainer>
                    )}
                    <FavoriteToggle
                        searchFocus={this.searchFocus}
                        active={this.favoriteFilter}
                        onClick={() => {
                            this.favoriteFilter = !this.favoriteFilter;
                            this.fetchLists();
                        }}
                    />
                </SearchContainer>
                <ContactsContainer scrolled={this.scrollView.top !== 0}>
                    <FullDimmable>
                        <ContactDimmer inverted active={this.loadingLists}>
                            <Loader inverted size="big" />
                        </ContactDimmer>
                        <Scrollbars
                            ref={action((node) => {
                                if (node !== null) {
                                    this.scrollView.top = node.getScrollTop();
                                    this.scrollView.bottom = this.scrollView.top + node.getClientHeight();
                                }
                            })}
                            onScroll={this.onScroll}
                        >
                            {this.stores.map(this.renderStore)}
                            {!this.loadingLists && this.stores.length === 0 && (this.phoneNumberSearch === null ? (
                                <NoResults>{t('contactTab.noResults')}</NoResults>
                            ) : (
                                <GroupContact>
                                    <UserInfo user={new Contact({
                                        firstName: this.phoneNumberSearch,
                                        avatar: UnknownAvatar,
                                        company: { id: 1, name: t('contactTab.unknownPhoneNumber') },
                                    }, {
                                        relations: CONTACT_RELATIONS,
                                    })}>
                                        <FlatLink to={`/contact/add/${this.phoneNumberSearch}`}>
                                            <IconButton clickable name="user add" />
                                        </FlatLink>
                                        <CallButton number={this.search} />
                                    </UserInfo>
                                </GroupContact>
                            ))}
                        </Scrollbars>
                    </FullDimmable>
                </ContactsContainer>
            </React.Fragment>
        );
    }
}
