import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { ContactStore } from 'store/Contact';

export class Company extends EngineModel {
    static backendResourceName = 'company';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            contacts: ContactStore,
        };
    }
}

export class CompanyStore extends EngineStore {
    static backendResourceName = 'company';
    Model = Company;
}
