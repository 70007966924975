import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { Extension } from 'store/Extension';
import { CallGroupCallerIdStore } from 'store/CallGroupCallerId';
import { UserCallerIdStore } from 'store/UserCallerId';

export const TYPES = [
    'regular',
    'spoofed',
    'vamo',
];

export const TYPE_ICONS = {
    regular: 'globe',
    'spoofed': 'user secret',
    'vamo': 'mobile-alt',
};

export class PublicPhoneNumber extends EngineModel {
    static backendResourceName = 'public_phone_number';

    @observable id = null;
    @observable type = 'regular';
    @observable phoneNumber = '';

    relations() {
        return {
            'extension': Extension,
            'callGroupCallerIds': CallGroupCallerIdStore,
            'userCallerIds': UserCallerIdStore,
        };
    }

    toBackend(...args) {
        const res = super.toBackend(...args);
        return res;
    }
}

export class PublicPhoneNumberStore extends EngineStore {
    static backendResourceName = 'public_phone_number';
    Model = PublicPhoneNumber;
}
