import React, { Component } from 'react';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import LoginImage from 'image/login_image.jpg';

const Container = styled.div`
    flex: 1;
    display: flex;

    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    position: relative;
    overflow: hidden;
`;

const RightsLink = styled.a`
    position: absolute;
    right: 1em;
    bottom: 1em;
    color: rgba(0, 0, 0, 0.25);
    &:hover {
        color: rgba(0, 0, 0, 0.5);
    }
    transition: color 300ms ease;
`;

const StickerImage = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: 0 0;

    transform: ${({ transform }) => transform};
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.125));
`;

const bgWidth = 4928;
const bgHeight = 3264;
const bgRatio = bgWidth / bgHeight;

const logoX = 2775;
const logoY = 320;
const logoWidth = 800;
const logoHeight = 600;
const logoRatio = logoWidth / logoHeight;
const logoRotation = 19.5;

@observer
export default class TrippyBackground extends Component {
    constructor(...args) {
        super(...args);
        this.onImgLoad = this.onImgLoad.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    imgNode = null;

    @observable imgWidth = 100;
    @observable imgHeight = 100;
    @observable width = bgWidth;
    @observable height = bgHeight;

    componentDidMount() {
        this.sizeInterval = setInterval(action(() => {
            if (
                this.containerNode.clientWidth !== this.width ||
                this.containerNode.clientHeight !== this.height
            ) {
                this.width = this.containerNode.clientWidth;
                this.height = this.containerNode.clientHeight;
            }
        }), 1000 / 60);
    }

    componentWillUnmount() {
        clearInterval(this.sizeInterval);
    }

    @computed get transform() {
        const transformations = [];

        if (this.width / this.height < bgRatio) {
            transformations.push(`translate(${(this.width - this.height * bgRatio) / 2}px, 0)`);
            transformations.push(`scale(${this.height / bgHeight})`);
        } else {
            transformations.push(`translate(0, ${(this.height - this.width / bgRatio) / 2}px)`);
            transformations.push(`scale(${this.width / bgWidth})`);
        }

        transformations.push(`translate(${logoX}px, ${logoY}px)`);

        transformations.push(`rotate(${logoRotation}deg)`);
        if (this.imgWidth / this.imgHeight < logoRatio) {
            transformations.push(`translate(${(this.imgHeight * logoRatio - this.imgWidth) / 2}px, 0)`);
            transformations.push(`scale(${logoHeight / this.imgHeight})`);
        } else {
            transformations.push(`translate(0, ${(this.imgWidth / logoRatio - this.imgHeight) / 2}px)`);
            transformations.push(`scale(${logoWidth / this.imgWidth})`);
        }

        return transformations.join(' ');
    }

    @action onImgLoad() {
        this.imgWidth = this.imgNode.naturalWidth;
        this.imgHeight = this.imgNode.naturalHeight;
    }

    @action onResize({ bounds: { width, height } }) {
        this.width = width;
        this.height = height;
    }

    render() {
        const { children, ...props } = this.props; // eslint-disable-line
        return (
            <Container innerRef={(ref) => this.containerNode = ref} {...props}>
                {children}
                <RightsLink target="_blank" href="https://www.freepik.com/free-photos-vectors/template">
                    Template photo created by freepik - www.freepik.com
                </RightsLink>
                {window.viewStore.currentClient.logo && (
                    <StickerImage
                        src={window.viewStore.currentClient.logo}
                        innerRef={(ref) => this.imgNode = ref}
                        onLoad={this.onImgLoad}
                        transform={this.transform}
                    />
                )}
            </Container>
        );
    }
}
