import { EngineModel, EngineStore } from 'store/Base';
import { observable } from 'mobx';
import { EngineUser } from 'store/EngineUser';
import { CallGroup } from 'store/CallGroup';
import { Extension } from 'store/Extension';
import { Media } from 'store/Media';
import { omit } from 'lodash';

export const RING_STRATEGY_RING_ALL = 'ring_all';
export const RING_STRATEGY_LONGEST_IDLE_AGENT = 'longest_idle_agent';
export const RING_STRATEGY_ROUND_ROBIN = 'round_robin';
export const RING_STRATEGY_TOP_DOWN = 'top_down';
export const RING_STRATEGY_RING_PROGRESSIVELY = 'ring_progressively';

export const RING_STRATEGIES = [
    RING_STRATEGY_ROUND_ROBIN,
    RING_STRATEGY_RING_ALL,
    RING_STRATEGY_LONGEST_IDLE_AGENT,
    RING_STRATEGY_TOP_DOWN,
    RING_STRATEGY_RING_PROGRESSIVELY,
];

export class CallQueueUser extends EngineModel {
    static backendResourceName = 'call_queue_user';

    @observable id = null;

    relations() {
        return {
            callQueue: CallQueue,
            user: EngineUser,
        };
    }
}


export class CallQueueUserStore extends EngineStore {
    static backendResourceName = 'call_queue_user';
    Model = CallQueueUser;
}


export class CallQueueCallGroup extends EngineModel {
    static backendResourceName = 'call_queue_call_group';

    @observable id = null;

    relations() {
        return {
            callQueue: CallQueue,
            callGroup: CallGroup,
        };
    }
}


export class CallQueueCallGroupStore extends EngineStore {
    static backendResourceName = 'call_queue_call_group';
    Model = CallQueueCallGroup;
}


export class CallQueueBreakout extends EngineModel {
    static backendResourceName = 'call_queue_breakout';

    @observable id = null;
    @observable digit = 0;

    relations() {
        return {
            callQueue: CallQueue,
            destination: Extension,
        };
    }
}


export class CallQueueBreakoutStore extends EngineStore {
    static backendResourceName = 'call_queue_breakout';
    Model = CallQueueBreakout;
}


export class CallQueue extends EngineModel {
    static backendResourceName = 'call_queue';

    @observable id = null;
    @observable name = '';
    @observable extensionNumber = null;
    @observable ringStrategy = 'round_robin';
    @observable timeout = null;
    @observable maxMembers = 15;
    @observable announceInterval = null;

    relations() {
        return {
            extension: Extension,
            timeoutDestination: Extension,
            callQueueUsers: CallQueueUserStore,
            callQueueCallGroups: CallQueueCallGroupStore,
            onHoldSound: Media,
            announceSound: Media,
            breakouts: CallQueueBreakoutStore,
        };
    }

    toBackend(...args) {
        const res = super.toBackend(...args);
        return omit(res, 'extension_number');
    }
}


export class CallQueueStore extends EngineStore {
    static backendResourceName = 'call_queue';
    Model = CallQueue;
}
