import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { EngineUser } from 'store/EngineUser';
import { Contact } from 'store/Contact';
import RightDivider from 'spider/component/RightDivider';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SmallAvatar } from 'component/UserAvatar';
import { Icon } from 'semantic-ui-react';
import { favoriteColor, favoriteIcon } from 'styles';

export const UserContainer = styled.div`
    width: 300px;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    img {
        width: 40px !important;
        height: 40px !important;
    }
`;

const UserLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba(0, 0, 0, 0.87);
`;

export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    overflow: hidden;
`;

export const UserName = styled.div`
    font-size: 1.1rem;
    height: 1.25em;
    line-height: 1.25em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const UserStatus = styled.div`
    font-size: 0.8rem;
    height: 1.2em;
    line-height: 0.8em;
    color: rgba(0, 0, 0, 0.5);
`;

const FavoriteIcon = styled((props) => <Icon name={favoriteIcon} {...props} />)`
    opacity: 0.75;
    color: ${favoriteColor};
    transform: scale(0.8);
    margin: 0 0 0 0.2rem !important;
`;

@observer
export default class UserInfo extends Component {
    static propTypes = {
        user: PropTypes.oneOfType([
            PropTypes.instanceOf(Contact).isRequired,
            PropTypes.instanceOf(EngineUser).isRequired,
        ]),
        online: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {
        online: false,
        children: null,
    };

    @computed get isContact() {
        const { user } = this.props;
        return user instanceof Contact && user.user.isNew;
    }

    @computed get user() {
        const { user } = this.props;
        if (user instanceof Contact) {
            return user.user;
        } else {
            return user;
        }
    }

    @computed get contact() {
        const { user } = this.props;
        if (user instanceof Contact) {
            return user;
        } else {
            return user.contact;
        }
    }

    render() {
        const { user, online, to, children, ...props } = this.props;

        const lines = [];

        if (this.contact.name !== '') {
            lines.push(this.contact.name);
        }

        if (this.isContact) {
            if (
                this.contact.company !== undefined &&
                !this.contact.company.isNew
            ) {
                lines.push(this.contact.company.name);
            }
        } else {
            lines.push(t(`user.field.status.value.${online ? this.user.baseStatus : this.user.status}`));
        }

        const phoneNumbers = (
            (this.contact.phoneNumbers || [])
            .filter((p) => p.primary)
            .map((p) => p.phoneNumber)
            .join(', ')
        );
        if (phoneNumbers !== '') {
            lines.push(phoneNumbers);
        }

        let userNode = (
            <React.Fragment>
                <SmallAvatar online={online} user={user} />
                {" "}
                <UserDetails>
                    <UserName>
                        {lines.length >= 1 && lines[0]}
                        {this.isContact && this.contact.favorite && <FavoriteIcon />}
                    </UserName>
                    <UserStatus>
                        {lines.length >= 2 && lines[1]}
                    </UserStatus>
                </UserDetails>
            </React.Fragment>
        );

        if (to) {
            userNode = <UserLink to={to}>{userNode}</UserLink>;
        }

        return (
            <UserContainer data-test-user-info {...props}>
                {userNode}
                <RightDivider />
                {children}
            </UserContainer>
        );
    }
}
