import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed, action, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Icon, Button, Popup } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { TargetBase, stripQueryParams } from 'spider/semantic-ui/Target';
import { engineApi } from 'store/Base';
import { showErrorNotification } from 'helpers/notification';
import { validateAudioFile } from 'helpers/fileTypeRestrictions';
import { SUPPORTED_AUDIO_MIME_TYPES } from '../helpers/fileTypeRestrictions';


function formatTime(seconds, detail = 'seconds', pad = false) {
    switch (detail) {
        case 'hours':
            return `${Math.floor(seconds / 3600)}:${formatTime(seconds % 3600, 'minutes', true)}`;
        case 'minutes':
            return `${pad && Math.floor(seconds / 60) < 10 ? '0' : ''}${Math.floor(seconds / 60)}:${formatTime(seconds % 60, 'seconds', true)}`;
        case 'seconds':
            return `${pad && Math.floor(seconds) < 10 ? '0' : ''}${Math.floor(seconds)}`;
        default:
            throw Error(`Unknown detail level: ${detail}`);
    }
}

const StyledProgressBar = styled.div`
    position: relative;
    flex: 1 1 auto !important;
    height: 0.5rem;
    border-radius: 0.25rem;
    background: linear-gradient(
        to right,
        #60B0E0,
        #60B0E0 ${({ progress }) => progress * 100}%,
        #D0D0D0 ${({ progress }) => progress * 100}%,
        #D0D0D0
    );
`;

const StyledProgressHandle = styled.div`
    position: absolute;
    left: calc(${({ progress }) => progress * 100}% - ${({ progress }) => progress}rem - 2px);
    top: 50%;
    transform: translateY(-50%);
    width: calc(1rem + 4px);
    height: calc(1rem + 4px);
    border-radius: 9999px;
    background-color: #D0D0D0;
    border: 2px solid #FFF;
    cursor: grab;
`;

@observer
class ProgressBar extends Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    @observable dragging = false;

    constructor(...args) {
        super(...args);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.onDragMove);
        document.addEventListener('mouseup', this.onDragEnd);
    }


    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onDragMove);
        document.removeEventListener('mouseup', this.onDragEnd);
    }

    onDragStart(e) {
        e.preventDefault();
        this.dragging = true;
    }

    onDragMove(e) {
        const { onChange } = this.props;

        if (this.dragging) {
            e.preventDefault();

            const barRect = this.bar.getBoundingClientRect();
            const handleRect = this.handle.getBoundingClientRect();

            const minX = barRect.x + handleRect.width / 2;
            const maxX = barRect.x + barRect.width - handleRect.width / 2;
            const x = Math.min(Math.max(e.clientX, minX), maxX)
            const value = (x - minX) / (maxX - minX);

            onChange(value);
        }
    }

    onDragEnd(e) {
        if (this.dragging) {
            e.preventDefault();
            this.dragging = false;
        }
    }

    render() {
        const { value } = this.props;

        return (
            <StyledProgressBar
                innerRef={(ref) => this.bar = ref}
                progress={value}
            >
                <StyledProgressHandle
                    innerRef={(ref) => this.handle = ref}
                    progress={value}
                    onMouseDown={this.onDragStart}
                />
            </StyledProgressBar>
        );
    }
}

const AudioContainer = styled.div`
    display: flex;
    align-items: center;
    > i.icon {
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 1.25rem;
        line-height: 1;
    }
    > * {
        flex: 0 0 auto;
        margin: 0 0.5rem 0 0 !important;
    }
    > *:first-child {
        margin-left: 0 !important;
    }
`;

const AudioVolume = styled.div`
    display: flex;
    align-items: center;
    > i.icon {
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 1.25rem;
        line-height: 1;
        margin: 0 !important;
    }
    > ${StyledProgressBar} {
        flex: 0 0 auto !important;
        width: 0;
        transition: width 300ms ease, margin-left 300ms ease;
        > ${StyledProgressHandle} {
            opacity: 0;
            transition opacity 300ms;
        }
    }
    &:hover {
        > ${StyledProgressBar} {
            margin-left: 0.5rem !important;
            width: 6rem;
            > ${StyledProgressHandle} {
                opacity: 1;
            }
        }
    }
`;

const AudioDropzone = styled(Dropzone)`
    cursor: pointer;
    width: unset;
    height: unset;
    border: unset;
    > .ui.button {
        margin: 0 !important;
    }
`;

const AudioTime = styled.div`
    color: rgba(0, 0, 0, 0.75);
`;

const AudioSize = styled.div`
    color: rgba(0, 0, 0, 0.75);
`

@observer
export class AudioPlayer extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.instanceOf(Blob).isRequired,
            PropTypes.instanceOf(File).isRequired,
        ]).isRequired,
        onChange: PropTypes.func,
    };

    constructor(...args) {
        super(...args);
        this.onDrop = this.onDrop.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.setVolume = this.setVolume.bind(this);
        this.setProgress = this.setProgress.bind(this);
    }

    @observable value = null;

    @observable audio = {
        audio: null,
        time: 0,
        duration: 0,
        volume: 1,
        state: 'paused',
    };

    componentDidMount() {
        this.valueReaction = reaction(
            () => this.props.value,
            (value) => {
                const url = engineApi.getFileUrl(value);
                if (url instanceof Promise) {
                    this.value = null;
                    url.then((value) => this.value = value);
                } else {
                    this.value = url;
                }
            },
            { fireImmediately: true },
        );
        this.audioReaction = reaction(
            () => this.value,
            (value) => {
                const audio = value && new Audio(value);

                this.audio = {
                    audio,
                    time: 0,
                    duration: 0,
                    volume: this.audio.volume,
                    state: 'paused',
                };

                if (audio) {
                    // Update state
                    audio.addEventListener('loadeddata', () => {
                        this.audio.duration = this.audio.audio.duration;
                    });
                    audio.addEventListener('timeupdate', () => {
                        this.audio.time = this.audio.audio.currentTime;
                    });
                    audio.addEventListener('volumechange', () => {
                        this.audio.volume = this.audio.audio.volume;
                    });
                    audio.addEventListener('play', () => {
                        this.audio.state = 'playing';
                    });
                    audio.addEventListener('pause', () => {
                        this.audio.state = 'paused';
                    });
                    // Actually react to events
                    audio.addEventListener('ended', action(() => {
                        audio.currentTime = 0;
                        audio.pause();
                    }));
                }
            },
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.valueReaction();
        this.audioReaction();
        this.pause();
    }

    @action play() {
        if (this.audio.audio) {
            this.audio.audio.play();
        }
    }

    @action pause() {
        if (this.audio.audio) {
            this.audio.audio.pause();
        }
    }

    @action setProgress(progress) {
        if (this.audio.audio) {
            this.audio.audio.currentTime = progress * this.audio.duration;
        }
    }

    @action setVolume(volume) {
        if (this.audio.audio) {
            this.audio.audio.volume = volume;
        }
    }

    @computed get timeDetail() {
        if (this.audio.duration >= 3600) {
            return 'hours';
        } else {
            return 'minutes';
        }
    }

    onDrop(files) {
        const { onChange } = this.props;
        for (const file of files) { // eslint-disable-line
            const { validation, errorMessage } = validateAudioFile(file)
            if (validation) {
                onChange(file);
            } else {
                showErrorNotification(errorMessage)
            }
        }
    }

    onDelete() {
        const { onChange } = this.props;
        onChange(null);
    }

    render() {
        const { onChange } = this.props;

        return (
            <React.Fragment>
                <audio ref={(ref) => this.audioRef = ref}>
                    {this.value && <source src={this.value} />}
                </audio>
                <AudioContainer>
                    <Icon
                        name={this.audio.state === 'paused' ? 'play' : 'pause'}
                        onClick={
                            this.audio.audio === null
                            ? undefined
                            : this.audio.state === 'paused'
                            ? this.play
                            : this.pause
                        }
                    />
                    <ProgressBar
                        value={this.audio.duration === 0 ? 0 : this.audio.time / this.audio.duration}
                        onChange={this.setProgress}
                    />
                    <AudioTime data-test-audio-duration >
                        {formatTime(this.audio.time, this.timeDetail)}
                        {' / '}
                        {formatTime(this.audio.duration, this.timeDetail)}
                    </AudioTime>
                    {this.audio ? (<AudioSize>{}</AudioSize>) : null}
                    <AudioVolume>
                        <Icon name="volume up" />
                        <ProgressBar
                            value={this.audio.volume}
                            onChange={this.setVolume}
                        />
                    </AudioVolume>
                    {onChange && (
                        <>
                            <AudioDropzone
                                accept={SUPPORTED_AUDIO_MIME_TYPES}
                                onDrop={this.onDrop}
                                data-test-drop-audio
                            >
                                <Button icon="upload" />
                            </AudioDropzone>
                            <Button icon="trash alternate" onClick={this.onDelete} />
                        </>
                    )}
                    <Popup
                        trigger={<Icon name="info circle" style={{ margin: 0, color: "rgba(0, 0, 0, 0.25)" }} />}
                        content={t('media.overview.info')}
                        position='top right'
                    />
                </AudioContainer>
            </React.Fragment>
        );
    }
}

export default class TargetAudio extends TargetBase {
    fromModel(value) {
        return stripQueryParams(value);
    }

    renderContent(props) {
        return (
            <div>
                <AudioPlayer
                    value={this.value}
                    onChange={this.onChange}
                    {...props}
                />
            </div>
        );
    }
}
