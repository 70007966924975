import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { Extension } from './Extension';
import { EngineUser } from './EngineUser';

export class DialPlan extends EngineModel {
    static backendResourceName = 'dial_plan';

    @observable id = null;
    @observable name = '';
    @observable isDefaultDialPlan = false;
    @observable type = '';
    @observable extensionNumber = '';
    @observable config;

    relations() {
        return {
            user: EngineUser,
            extension: Extension,
        }
    }

    toBackend(...args) {
        const res = super.toBackend(...args);
        delete res.extension_number;
        return res;
    }
}

export class DialPlanStore extends EngineStore {
    static backendResourceName = 'dial_plan';
    Model = DialPlan;
}
