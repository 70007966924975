import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { EngineClient } from 'store/EngineClient';
import { CallGroup } from 'store/CallGroup';
import { PublicPhoneNumber } from 'store/PublicPhoneNumber';

export class CallGroupCallerId extends EngineModel {
    static backendResourceName = 'call_group_caller_id';

    @observable id = null;

    relations() {
        return {
            client: EngineClient,
            callGroup: CallGroup,
            phoneNumber: PublicPhoneNumber,
        };
    }
}

export class CallGroupCallerIdStore extends EngineStore {
    static backendResourceName = 'call_group_caller_id';
    Model = CallGroupCallerId;
}
