import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { EngineUser } from 'store/EngineUser';
import { CallGroup } from 'store/CallGroup';
import { VoicemailMessageStore } from 'store/VoicemailMessage';

export const TYPES = [
    'call_group',
    'user',
];

export const TYPE_ICONS = {
    call_group: 'users',
    user: 'user',
};

export class VoicemailBox extends EngineModel {
    static backendResourceName = 'voicemail_box';

    @observable id = null;
    @observable audioFile = null;

    // Annotations
    @observable type = 'call_group';
    @observable name = '';

    relations() {
        return {
            user: EngineUser,
            callGroup: CallGroup,
            messages: VoicemailMessageStore
        };
    }

    toBackend(...args) {
        const res = super.toBackend(...args);

        delete res.type;
        delete res.name;

        return res;
    }
}

export class VoicemailBoxStore extends EngineStore {
    static backendResourceName = 'voicemail_box';
    Model = VoicemailBox;
}
