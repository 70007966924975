import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { EngineClient } from 'store/EngineClient';
import { EngineUserStore } from 'store/EngineUser';

export class Location extends EngineModel {
    static backendResourceName = 'location';

    @observable id = null;
    @observable name = '';
    @observable default = false;
    @observable phoneNumberPrefix = '+31';
    @observable defaultLanguage = '';

    relations() {
        return {
            client: EngineClient,
            users: EngineUserStore,
        };
    }
}

export class LocationStore extends EngineStore {
    static backendResourceName = 'location';
    Model = Location;
}
