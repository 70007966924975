import { EngineModel, EngineStore } from 'store/Base';
import { action, observable } from 'mobx';
import { EngineUser } from 'store/EngineUser';
import { Extension } from 'store/Extension';
import { CallGroupCallerIdStore } from 'store/CallGroupCallerId';
import { VoicemailBox } from 'store/VoicemailBox';



export class CallGroup extends EngineModel {
    static backendResourceName = 'call_group';
    static RING_STRATEGIES = ['simultaneous', 'single', 'weighted_random'];
    static VOICEMAIL_NOTIFICATIONS = ['users', 'override', 'do_not_send'];

    @observable id = null;
    @observable name = '';
    @observable ringStrategy = 'simultaneous';
    @observable groupTimeout = 20;
    @observable userTimeout = 20;
    @observable repeats = 1;

    // Annotations
    @observable extensionNumber = null;
    @observable memberCount = 0;

    @observable voicemailNotification = 'users';
    @observable voicemailNotificationOverrideEmails = [];

    relations() {
        return {
            extension: Extension,
            callgroupUsers: CallGroupUsersStore,
            callGroupCallerIds: CallGroupCallerIdStore,
            voicemailBox: VoicemailBox,
        };
    }

    @action parse(...args) {
        const res = super.parse(...args);
        if (this.voicemailNotificationOverrideEmails === null) {
            this.voicemailNotificationOverrideEmails = [];
        }
        return res;
    }

    toBackend(...args) {
        const res = super.toBackend(...args);

        delete res.extension_number;
        delete res.member_count;

        if (this.voicemailNotification !== 'override') {
            res.voicemail_notification_override_emails = null;
        }

        return res;
    }
}


export class CallGroupStore extends EngineStore {
    static backendResourceName = 'call_group';
    Model = CallGroup;
}


export class CallGroupUsers extends EngineModel {
    static backendResourceName = 'call_group_users';

    @observable id = null;
    @observable ordering = 0;

    relations() {
        return {
            user: EngineUser,
            callGroup: CallGroup,
        };
    }
}


export class CallGroupUsersStore extends EngineStore {
    static backendResourceName = 'call_group_users';
    Model = CallGroupUsers;
}
