import { observable, computed } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { Company } from 'store/Company';
import { ContactList } from 'store/ContactList';
import { CallGroup } from 'store/CallGroup';
import { EngineUser } from 'store/EngineUser';
import { ContactPhoneNumberStore } from 'store/ContactPhoneNumber';

export class Contact extends EngineModel {
    static backendResourceName = 'contact';

    @observable id = null;
    @observable email = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable avatar = null;
    @observable favorite = false;

    // Annotations
    @observable contactListName = '';
    @observable exactUrls = [];
    @observable salesforceUrls = [];
    @observable hubspotUrls = [];

    @computed get name() {
        return `${this.firstName} ${this.lastName}`.trim();
    }

    relations() {
        return {
            company: Company,
            contactList: ContactList,
            callGroup: CallGroup,
            phoneNumbers: ContactPhoneNumberStore,
            user: EngineUser,
        };
    }

    toBackend(...args) {
        const res = super.toBackend(...args);

        delete res.contact_list_name;
        delete res.exact_urls;
        delete res.salesforce_urls;
        delete res.hubspot_urls;

        return res;
    }

    toBackendAll(options = {}) {
        const { phoneNumbers, ...nestedRelations } = options.nestedRelations || {};
        options.nestedRelations = nestedRelations;

        const res = super.toBackendAll(options);

        delete res.data[0].phone_numbers;

        if (phoneNumbers) {
            res.data[0].phone_number_data = [];
            // We loop over the models instead of using Store.toBackendAll
            // because we also need to include phone numbers that were not
            // changed so that they do not get deleted.
            // eslint-disable-next-line no-unused-vars
            for (const phoneNumber of this.phoneNumbers.models) {
                const { data, relations } = phoneNumber.toBackendAll({
                    ...options,
                    nestedRelations: phoneNumbers,
                });

                res.data[0].phone_number_data.push(...data);
                // eslint-disable-next-line no-unused-vars
                for (const [rel, relData] of Object.entries(relations)) {
                    if (res.relations[rel] === undefined) {
                        res.relations[rel] = relData;
                    } else {
                        res.relations[rel].push(...relData);
                    }
                }
            }
        }

        return res;
    }
}

export class ContactStore extends EngineStore {
    static backendResourceName = 'contact';
    Model = Contact;
}
