import { observable, computed } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { CalendarEventStore } from './CalendarEvent';

export const CALENDAR_TYPES = ['icloud'];

export const CALENDAR_TYPE_URLS = {
    icloud: 'https://caldav.icloud.com/',
};

export const CALENDAR_URL_TYPES = {};// eslint-disable-next-line no-unused-vars
for (const [type, url] of Object.entries(CALENDAR_TYPE_URLS)) {
    CALENDAR_URL_TYPES[url] = type;
}

export class CalendarAccount extends EngineModel {
    static backendResourceName = 'calendar_account';

    @observable baseUrl = '';
    @observable id = null;
    @observable username = '';
    @observable password = '';
    @observable name = '';
    @observable status = 'not_available';

    @computed get type() {
        return CALENDAR_URL_TYPES[this.baseUrl] || null;
    }

    relations() {
        return {
            calendarEvents: CalendarEventStore,
        };
    }

    getCalendars() {
        return this.wrapPendingRequestCount(
            this.api.get(`${this.urlRoot()}calendars/`, {
                base_url: this.baseUrl,
                username: this.username,
                password: this.password,
            })
        );
    }

    getEvents({ start, end }) {
        return this.wrapPendingRequestCount(
            this.api.get(`${this.url}events/${start.toISODate()}/${end.toISODate()}/`)
        );
    }
}

export class CalendarAccountStore extends EngineStore {
    static backendResourceName = 'calendar_account';
    Model = CalendarAccount;
}
