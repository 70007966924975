function getPlatform() {
    // This is not complete but should be reasonably accurate if you ignore
    // mobile, main thing is that there is no way to distinguish ChromeOS from
    // Linux.
    return (
        navigator.platform.startsWith('Mac')
        ? 'mac'
        : navigator.platform.startsWith('Linux ')
        ? 'linux'
        : navigator.platform.startsWith('Win')
        ? 'windows'
        : 'unknown'
    );
}

function normalize(keyCombo) {
    if (typeof keyCombo === 'string') {
        return normalize([keyCombo]);
    } else if (!Array.isArray(keyCombo)) {
        return normalize(keyCombo[getPlatform()] || keyCombo.default);
    } else if (keyCombo.some((key) => typeof key !== 'string')) {
        return keyCombo.flatMap(normalize);
    } else {
        return [keyCombo];
    }
}

const MODS = ['shift', 'ctrl', 'alt', 'meta'];

export function checkKeys(e, keys) {
    return normalize(keys).some((keys) => {
        const mods = keys.slice(0, keys.length - 1);
        const key = keys[keys.length - 1];
        return (
            MODS.every((mod) => e[`${mod}Key`] === mods.includes(mod)) &&
            (key.length === 1 ? e.keyCode === key.charCodeAt(0) : e.key === key)
        );
    });
}

const MOD_TEXT = {
    meta: {
        mac: String.fromCharCode(8984),
        windows: 'Windows+',
        default: 'Meta+',
    },
    shift: {
        mac: String.fromCharCode(8679),
        default: 'Shift+',
    },
    ctrl: {
        mac: String.fromCharCode(8963),
        default: 'Ctrl+',
    },
    alt: {
        mac: String.fromCharCode(8997),
        default: 'Alt+',
    },
};

const KEY_TEXT = {
    Backspace: { mac: String.fromCharCode(9003) },
    Delete: { mac: String.fromCharCode(8998) },
    ArrowLeft: { default: String.fromCharCode(8592) },
    ArrowUp: { default: String.fromCharCode(8593) },
    ArrowRight: { default: String.fromCharCode(8594) },
    ArrowDown: { default: String.fromCharCode(8595) },
    Escape: { default: 'Esc' },
};

export function showKeys(keys) {
    const platform = getPlatform();
    return (
        normalize(keys)
        .map((keys) => {
            const mods = keys.slice(0, keys.length - 1);
            const key = keys[keys.length - 1];
            return [
                ...mods.map((mod) => MOD_TEXT[mod][platform] || MOD_TEXT[mod].default),
                (KEY_TEXT[key] && (KEY_TEXT[key][platform] || KEY_TEXT[key].default)) || key,
            ].join('');
        })
        .join(' / ')
    );
}


export function handleKeys(e, handlers, { preventDefault = true, stopPropagation = false } = {}) {
// eslint-disable-next-line no-unused-vars
    for (const [keys, handler] of handlers) {
        if (checkKeys(e, keys)) {
            if (preventDefault) {
                e.preventDefault();
            }
            if (stopPropagation) {
                e.stopPropagation();
            }
            handler();
            return true;
        }
    }
    return false;
}
