import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Contact, ContactStore } from 'store/Contact';
import VoicemailInfo from 'component/VoicemailInfo';
import styled from 'styled-components';
import { Popup, Icon, Input, Dimmer, Loader, Table } from 'semantic-ui-react';
import { Model } from 'store/Base';
import { snakeToCamel } from 'helpers';
import { favoriteColor, favoriteIcon } from 'styles';
import phone from 'phone';
import UnknownAvatar from 'image/unknown_avatar.png';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Callable from 'component/Callable';
import { debounce, pick } from 'lodash';
import { FullDimmable, ItemButton } from 'spider/semantic-ui/Admin/Overview';
import { TYPE_ICONS as EXTENSION_TYPE_ICONS } from 'store/Extension';
import { VoicemailMessageStore as VoicemailStore } from 'store/VoicemailMessage'
import { VoicemailBoxStore } from 'store/VoicemailBox'
import { CallStore } from 'store/Call'
import { AudioPlayer } from 'component/TargetAudio';
import moment from 'moment'
import subscribe from 'decorator/subscribe';


const CONTACT_HEIGHT = 40;
const CONTACT_MARGIN_BOTTOM = 5;
const NUMBER_HEIGHT = 30;
const NUMBER_VERTICAL_MARGIN = 4;

export const VOICEMAIL_EXPIRATION_DAYS = 180

export const StyledCallable = styled(Callable)`
    &.ui.button.ui.button.ui.button.ui.button {
        font-size: 0.85714286rem !important;
        padding: calc(0.5833em - 1px) 0.833em calc(0.5833em - 1px) 2.75em !important;
        > i.icon {
            width: 2.1666em !important;
        }
        margin-right: 3px !important;
        margin-left: 3px !important;
        transition: transform 300ms ease;
        line-height: 1;
    }
`;

class SpecialVoicemailBoxStore extends VoicemailBoxStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        this.params.onlyRelevant = true;
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialVoicemailStore extends VoicemailStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        this.params.onlyRelevant = true;
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialCallStore extends CallStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

class SpecialContactStore extends ContactStore {
    @observable currentScope = { offset: 0, limit: 0 };

    fetch(...args) {
        return super.fetch(...args).then(action(() => {
            this.currentScope.offset = this.params.offset;
            this.currentScope.limit = this.params.limit;
        }));
    }
}

const DIAL_KEYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#',null,'+'];
const DIAL_KEY_ICONS = {
    '*': 'asterisk',
    '#': 'hashtag',
};

const MODEL_STORES = {
    message: SpecialVoicemailStore,
    box: SpecialVoicemailBoxStore,
    call: SpecialCallStore,
    contact: SpecialContactStore
};

const STORE_FILTERS = {
    message: [],
};

const STORE_RELATIONS = {
    box: ['messages'],
    message: [
        'call.legs',
        'call.to',
        'call.from.contact.user.devices.extension',
        'call.from.contact.user.dialPlan.extension',
        'call.from.contact.phoneNumbers',
        'voicemailBox.user',
        'voicemailBox.callGroup.callgroupUsers',
        'contact.phoneNumbers',
        'contact.user.devices',
    ],
    contact: [],
    call: [],
};

const MODEL_ORDER_BY = {
    message: '-call.started_at,call.from.contact.name,call.duration,id',
    box: '-type,name,id',
    call: 'duration,id',
    contact: 'last_name,first_name,id'
};

const STORE_ICONS = { // fix icons
    ...EXTENSION_TYPE_ICONS,
    message: 'message',
    box: 'box',
    users: 'user',
    call_group_list: 'users',
    contact_list: 'list',
    company: 'building',
};

const NoResults = styled.div`
    padding: 0 10px;
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    line-height: 1;
    margin-top: ${({ hide }) => hide ? 0 : 10}px;
    max-height: ${({ hide }) => hide ? 0 : 100}%;
    opacity: ${({ hide }) => hide ? 0.5 : 1};
    overflow: hidden;
    transition: margin-top 300ms ease, max-height 300ms ease, opacity 300ms ease;
`;

const ContactNumbers = styled.div`
    max-height: ${({ hide, numberLines = 0 }) => hide ? 0 : CONTACT_HEIGHT + numberLines * NUMBER_HEIGHT}px;
    transition: all 300ms ease;
    overflow: hidden;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 4px;
    margin-top: 6px;
`;

const GroupNumber = styled.div`
    opacity: 1;
    overflow: hidden;
    display: inline-block;
    transition: opacity 300ms ease;
    margin-top: ${NUMBER_VERTICAL_MARGIN / 2}px;
    margin-bottom: ${NUMBER_VERTICAL_MARGIN / 2}px;
`;

const ContactDimmer = styled(Dimmer)`
    background-color: rgba(255, 255, 255, 0.65) !important;
`;

const ExpandIcon = styled(Icon)`
    margin-right: 0 !important;
    float: right;
    cursor: pointer;
    opacity: 0.5 !important;
    &:hover {
        opacity: 1 !important;
    }
    transition: opacity 300ms ease;
`;

const ClearButton = styled(Icon)`
    position: absolute;
    top: 0;
    right: calc(1.625rem * 1 + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5rem !important;
    font-size: 1.1em !important;
    color: rgba(0, 0, 0, 0.435) !important;
    opacity: ${({ active, searchFocus }) => !active ? 0 : !searchFocus ? 0.6 : 1} !important;
    ${({ active }) => active ? `` : `
        pointer-events: none;
    `}
    transition: color 300ms ease, opacity 300ms ease, transform 300ms ease;
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
`;

const DialKeyButton = styled(Icon)`
    position: absolute;
    top: 0;
    right: calc(1.625rem * ${({ clearable }) => clearable ? 2 : 1} + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5rem !important;
    font-size: 1.1em !important;
    color: ${({ active }) => active ? favoriteColor : 'rgba(0, 0, 0, 0.435)'} !important;
    opacity: ${({ searchFocus }) => searchFocus ? 1 : 0.6} !important;
    transition: color 300ms ease, opacity 300ms ease, transform 300ms ease;
    &:hover {
        opacity: 1 !important;
    }
    cursor: pointer;
`;

const FavoriteToggle = styled(({ active, ...props }) => <Icon name={favoriteIcon} {...props} />)`
    position: absolute;
    top: 0;
    right: 0;
    right: calc(1.625rem * 0 + 0.523214285rem);
    width: 1.625rem !important;
    height: 100% !important;
    margin: 0 !important;
    text-align: center;
    line-height: 2.5em !important;
    color: ${({ active }) => active ? favoriteColor : 'rgba(0, 0, 0, 0.435)'} !important;
    cursor: pointer;
    opacity: ${({ searchFocus }) => searchFocus ? 1 : 0.6} !important;
    &:hover {
        opacity: 1 !important;
    }
    transition: color 500ms ease, transform 300ms ease, opacity 300ms ease;
`;

const CallDialKeysContainer = styled.div`
    margin: 5px -5px -5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CallDialKeys = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template: 2em 2em 2em 2em 2em / 3em 3em 3em;
    grid-gap: 5px;
`;

const CallDialKey = styled.div`
    font-weight: bold;
    text-align: center;
    width: 3em;
    height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.125);
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.25);
    }
    transition: background-color 300ms ease;
    > i.icon {
        font-size: 0.75em;
        line-height: 1;
        margin: 0 !important;
    }
`;

const SearchContainer = styled.div`
    > .ui.icon.input {
        > input {
            padding-right: 5.79642857em !important;
            border: unset !important;
            background-color: #E0E0E0 !important;
            border-radius: 999px !important;
        }
        > i.icon {
            opacity: 0.5 !important;
            right: calc(1.625rem * ${({ clearable }) => clearable ? 3 : 2} + 0.523214285rem);
            width: 1.625rem !important;
            transition: right 300ms ease;
        }
        opacity: ${({ hasFocus }) => hasFocus ? 1 : 0.6} !important;
        transition: opacity 300ms ease;
    }
    position: relative;
    margin: 10px;
`;

const VoicemailsContainer = styled.div`
    flex: 1 1 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        ${({ scrolled }) => scrolled ? `
            box-shadow: inset 0 14px 18px -14px rgba(0, 0, 0, 0.125);
        ` : ``}

        transition: box-shadow 300ms ease;
    }
`;

const VoicemailContact = styled.div`
    max-height: ${({ numberLines = 0 }) => CONTACT_MARGIN_BOTTOM + CONTACT_HEIGHT + numberLines * (NUMBER_HEIGHT + NUMBER_VERTICAL_MARGIN)}px;
    opacity: 1;
    overflow: hidden;
    transition: max-height 300ms ease, opacity 300ms ease;
`;

const FlatLink = styled(Link)`
    line-height: 1;
`;

const IconButton = styled(Icon)`
    font-size: 1.25em !important;
    color: rgba(0, 0, 0, 0.6);
    ${({ clickable, onClick }) => (clickable !== undefined ? clickable : onClick) ? `
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    ` : `
        opacity: 0.5 !important;
    `}
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
    transition: transform 300ms ease;
    line-height: 1;
`;

export const StyledVoicemailBox = styled.div`
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.05);
    line-height: 1;
    > i.icon {
        margin-right: 0.2rem;
    }
    overflow: hidden;
`;

const StyledVoicemailItem = styled.div`
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
`

const StyledTableRow = styled(Table.Row)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 10px 0 10px;
`

const StyledTableCell = styled(Table.Cell)`
    width: 100%;
`

const StyledVoicemailMessagesContainer = styled.div`
    margin: 0 10px;
    opacity: ${({ hide }) => hide ? 0.5 : 1};
    transition: margin-top 300ms ease, max-height 300ms ease, opacity 300ms ease;
`

const StyledDateFilter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
`

const StyledDateFilterButtonsContainer = styled.div`
    border-radius: 5px;
    overflow: hidden;
`

const StyledDateFilterButton = styled.button`
    background-color: #F5F5F5;
    color: #A7A7A7;
    font-size: 0.9em;
    border: none;
    padding: 10px;
    cursor: pointer;

    &.selected {
        background-color: #E0E0E0;
        color: #1D1D1D;
        font-weight: bold;
    }
`

const StyledDateFilterIconButton = styled.button`
    background-color: rgba(0,0,0,0);
    color: #A7A7A7;
    font-size: 0.9em;
    border: none;
    padding: 10px;
    cursor: pointer;

    &.selected {
        color: #1D1D1D;
        font-weight: bold;
    }
`

const StyledIconButton = styled(ItemButton)`
    background-color: #E0E0E0 !important;
    color:#5A5A5A !important;
    margin: 0 0 0 0.5rem !important;

    &:hover {
        background-color: #7A7A7A !important;
        color:#F0F0F0 !important;
    }

    &:active {
        background-color: #3E3E3E !important;
        color:#F0F0F0 !important;
    }
`

@observer
class CallButton extends Component {
    static propTypes = {
        internal: PropTypes.bool,
        number: PropTypes.string,
    };

    static defaultProps = {
        internal: false,
    };

    @computed get internalPhone() {
        return (
            window.viewStore.currentEngineUser.activeDevice.callerId.isNew &&
            window.viewStore.currentEngineUser.callerId.isNew &&
            window.viewStore.currentEngineClient.callerId.isNew
        );
    }

    render() {
        const { number, internal } = this.props;

        const clickable = number && (internal || !this.internalPhone);
        let button = (
            <IconButton
                name="phone"
                clickable={clickable}
                onClick={clickable ? () => phone.call(number) : undefined}
            />
        );

        if (number && !internal && this.internalPhone) {
            button = (
                <Popup
                    trigger={button}
                    content={t('contactTab.internalOnly')}
                />
            );
        }

        if (!clickable) {
            button = (
                <Popup
                    trigger={button}
                    content={t('contactTab.notAvailable')}
                />
            );
        }

        return button;
    }
}

export function updateStore(store, { added = [], updated = [], deleted = [] }) {
    store.models = store.models.filter((m) => !deleted.includes(m.id));
    // eslint-disable-next-line no-unused-vars
    for (const { id, ...changes } of added + updated) {
        let model = store.get(id);
        if (!model) {
            model = store.add();
            changes.id = id;
        }
        updateModel(model, changes);
    }
    store.removeById(deleted);
}

export function updateModel(model, changes) {
    const rels = model.relations();
    // eslint-disable-next-line no-unused-vars
    for (let [field, value] of Object.entries(changes)) {
        field = snakeToCamel(field);
        if (rels[field]) {
            if (model.__activeCurrentRelations.includes(field)) {
                if (rels[field].prototype instanceof Model) {
                    updateModel(model[field], value);
                } else {
                    updateStore(model[field], value);
                }
            }
        } else {
            model[field] = value;
        }
    }
}


@observer
class VoicemailItem extends Component {
    static propTypes = {
        voicemail: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.renderVoicemailPlayer = this.renderVoicemailPlayer.bind(this);
    }

    @observable hiddenNumbers = true;
    @observable openVoicemails = {};

    renderVoicemailPlayer(voicemail) {

        return (
            <StyledTableRow key={voicemail.cid}>
                <StyledTableCell >
                    <AudioPlayer value={voicemail.file} />
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    renderNumber({ number, device, disabled }) {

        if(!number) {
            return
        }

        return (
            <GroupNumber key={number}>
                <StyledCallable
                    number={ number }
                    device={ device }
                    disabled={disabled}
                />
            </GroupNumber>
        );
    }

    render() {
        const { voicemail } = this.props;

        const call = voicemail.call
        const contact = call.from.models[0].contact

        const key = (`contact_${contact.id}`);
        const contactNumbers = [];

        let numberLinesCount = 0;
        let pnCount = 0;
        let extensionCount = 0;

        const isExpired = moment().diff(call.startedAt, 'days') > VOICEMAIL_EXPIRATION_DAYS

        // eslint-disable-next-line no-unused-vars
        for (const number of contact.user.devices.models) {
            contactNumbers.push({ number: number.extension, device: number, disabled: !number.isRegistered});
                extensionCount++;
        } // eslint-disable-next-line no-unused-vars
        for (const number of contact.phoneNumbers.models) {
            contactNumbers.push({ number });
                pnCount++;
        }

        numberLinesCount = Math.round(extensionCount / 3) + Math.round(pnCount / 2)

        return (
                <StyledVoicemailItem data-test-voicemail>
                    <VoicemailContact
                        key={key}
                        numberLines={this.hiddenNumbers ? 0 : numberLinesCount}
                    >
                        <VoicemailInfo
                            to={`/contact/${contact.id}/call/overview`}
                            voicemail={voicemail}
                        >
                            <React.Fragment key={key}>
                                {
                                    numberLinesCount ? (
                                        <ExpandIcon
                                            name={this.hiddenNumbers ? 'chevron down' : 'chevron up'}
                                            onClick={() => {this.hiddenNumbers = !this.hiddenNumbers}}
                                        />
                                    ) : ''
                                }
                                {isExpired ? <Popup content={t('call.overview.voicemail.tooltip.disabled')} trigger={
                                <span>
                                    <StyledIconButton    // disabled buttons do not show popups, hence the span wrapping
                                        icon="voicemail"
                                        label={t('call.overview.voicemail.tooltip.listen')}
                                        active={!!this.openVoicemails[call.cid]}
                                        disabled={isExpired}
                                        onClick={() => this.openVoicemails[call.cid] = !this.openVoicemails[call.cid]}
                                        data-test-disabled-voicemail-button
                                    />
                                </span> }/> : <StyledIconButton
                                    icon="voicemail"
                                    label={t('call.overview.voicemail.tooltip.listen')}
                                    active={!!this.openVoicemails[call.cid]}
                                    disabled={isExpired}
                                    onClick={() => this.openVoicemails[call.cid] = !this.openVoicemails[call.cid]}
                                />}
                            </React.Fragment>
                        </VoicemailInfo>
                        <ContactNumbers
                            numberLines={numberLinesCount}
                            hide={this.hiddenNumbers} >

                            {contactNumbers.map(this.renderNumber)}
                        </ContactNumbers>
                    </VoicemailContact>
                    {this.openVoicemails[call.cid] ? this.renderVoicemailPlayer(voicemail) : null}
                </StyledVoicemailItem>
        );
    }
}

@subscribe
@observer
export default class VoicemailTab extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    @observable stores = [];
    @observable loadingLists = true;

    @observable search = '';
    @observable favoriteFilter = false;
    @observable searchFocus = false;
    @observable hidden = JSON.parse(localStorage.getItem('rexHiddenContacts') || '{}');
    @observable dialKeys = false;

    @observable scrollView = { top: 0, visibleBottom: 0, totalBottom: 0 };

    @observable dateFilter = 'week'

    constructor(...args) {
        super(...args);

        this.onScroll = this.onScroll.bind(this);
        this.fetchLists = this.fetchLists.bind(this);
        this.debouncedFetchLists = debounce(this.fetchLists, 300);
        this.fetchStores = this.fetchStores.bind(this);
        this.debouncedFetchStores = debounce(this.fetchStores, 300);
        this.renderVoicemailBox = this.renderVoicemailBox.bind(this);
        this.renderVoicemailBoxes = this.renderVoicemailBoxes.bind(this);
        this.getStore = this.getStore.bind(this);
    }

    componentDidMount() {
        this.fetchLists({ fullLoad: false, showLoad: true });

        this.contactSubscription = this.subscribe(
            {
                type: 'contact_change',
                client: window.viewStore.currentEngineClient.id,
                contact: '*',
            },
            action(({ data: { added = [], ...data } }) => { // eslint-disable-next-line no-unused-vars
                updateStore(this.store, data);
                if (
                    // Set change
                    added.length > 0 ||
                    (data.deleted && data.deleted.length > 0) ||
                    // Data change
                    data.updated.some(({ id, ...changes }) => (
                        // Ordering change
                        (changes.user && (
                            changes.user.base_status ||
                            changes.user.is_enabled ||
                            changes.user.active_sessions ||
                            changes.first_name ||
                            changes.last_name
                        )) ||
                        // Phone number search change
                        (this.phoneNumberSearch && changes.phone_numbers) ||
                        // Standard search change
                        (this.search && !this.phoneNumberSearch && (
                            changes.first_name ||
                            changes.last_name ||
                            (changes.company && changes.company.name)
                        ))
                    ))
                ) {
                    this.fetchLists({ showLoad: false });
                }
            }),
        );
    }

    componentWillUnmount() {
        this.contactSubscription.unsubscribe();
    }

    fetchLists({ fullLoad = true, showLoad = true } = {}) {
        if (showLoad) {
            this.loadingLists = true;
        }

        const stores = Object.keys(MODEL_STORES).map(key => {
            const type = key
            const TypeStore = MODEL_STORES[key]
            return {
                type: type,
                store: new TypeStore({
                    relations: STORE_RELATIONS[key],
                    params: {
                        order_by: MODEL_ORDER_BY[key],
                        ...(
                            STORE_FILTERS[key]
                            ? pick(this.filter, ['search', 'date'])
                            : {}
                        ),
                    },
                })
            }
        })

        let promise = this.fetchStores(stores);

        if (fullLoad) {
            return promise.then(() => {
                this.stores = stores
                this.loadingLists = false
            })
        } else {
            this.stores = stores;
            this.loadingLists = false
        }
    }

    @computed get filter() {
        const filter = {};

        if (this.phoneNumberSearch) {
            filter['search'] = this.phoneNumberSearch
        } else if (this.search) {
            filter['search'] = this.search;
        }

        if (this.dateFilter) {
            const currentDate = moment()

            if (this.dateFilter === 'none') {
                filter['date'] = ''
            }
            if (this.dateFilter === 'day') {
                filter['date'] = currentDate.subtract(1, 'days')
            }
            if (this.dateFilter === 'week') {
                filter['date'] = currentDate.subtract(7, 'days')
            }
        }

        return filter;
    }

    @action fetchStores(stores) {
        let { visibleBottom, totalBottom } = this.scrollView;

        if (stores === undefined) {
            stores = this.stores;
        }

        let promises = [];
        // eslint-disable-next-line no-unused-vars
        for (const { store } of stores) {

            const oldLimit = store.params.limit;
            let limit = oldLimit !== undefined ? oldLimit : 25;

            if (totalBottom < visibleBottom + 200) {
                limit += 25;
            }

            if (store.__state.totalRecords && limit > store.__state.totalRecords) {
                limit = store.__state.totalRecords;
            }

            if (oldLimit === undefined || limit > oldLimit) {
                store.params.offset = 0;
                store.params.limit = limit;
                promises.push(store.fetch());
            }
        }

        return Promise.all(promises);
    }

    @action onScroll(e) {

        // todo -> handle large amounts of voicemails

        this.scrollView.top = e.target.scrollTop;
        this.scrollView.visibleBottom = this.scrollView.top + e.target.clientHeight;
        this.scrollView.totalBottom = e.target.children[1].clientHeight;
        this.debouncedFetchStores();
    }

    @computed get phoneNumberLocalRegExp() {
        const prefix = window.viewStore.currentEngineUser.location.phoneNumberPrefix;
        const min = Math.max(1 - (prefix.length - 1), 0);
        const max = Math.max(14 - (prefix.length - 1), 0);
        const regexp = new RegExp(`^0\\d{${min},${max}}$`);
        return regexp;
    }

    @computed get phoneNumberSearch() {
        return (
            /^\+[1-9]\d{1,14}$/.test(this.search)
            ? this.search
            : this.phoneNumberLocalRegExp.test(this.search)
            ? (
                window.viewStore.currentEngineUser.location.phoneNumberPrefix +
                this.search.slice(1)
            )
            : null
        );
    }

    getStore(type) {
        return this.stores?.filter(store => store.type === type)?.map(stores => stores.store)?.pop()
    }

    renderVoicemailBoxes() {

        const messages = this.getStore('message')?.models

        const messagesByBox = {}

        messages.forEach(message => {
            if (message.voicemailBox.id in messagesByBox) {
                messagesByBox[message.voicemailBox.id].push(message)
            } else {
                messagesByBox[message.voicemailBox.id] = [message]
            }
        })

        const boxesToRender = []

        // eslint-disable-next-line no-unused-vars
        for (const boxId in messagesByBox) {
            boxesToRender.push(this.renderVoicemailBox(boxId, messagesByBox[boxId]))
        }

        return boxesToRender
    }

    renderVoicemailBox(boxId, messages) {

        const boxes = this.getStore('box')
        const voicemailBox = boxes.find(({id}) => id === parseInt(boxId))

        if(voicemailBox === undefined) {
            return null
        }

        const {type, id, name} = voicemailBox
        const key = id ? `${type}:${id}` : type;

        if(type === null || name === null){
            return null
        }

        const renderVoicemails = () => {
            return (
                <StyledVoicemailMessagesContainer items={messages.length}>
                    {!this.hidden[key] && messages.map(message => (<VoicemailItem key={message.cid} voicemail={message} />))}
                </StyledVoicemailMessagesContainer>
            )
        }

        return (
            <React.Fragment key={key}>
                <StyledVoicemailBox data-test-voicemailBox>
                    <Icon name={STORE_ICONS[type]} />
                    {' '}
                    {type === 'user' ? (
                        t(`voicemailTab.label.${type}`)
                    ) : name}
                    {` (${voicemailBox.messages.length})`}
                    <ExpandIcon
                        name={this.hidden[key] ? 'chevron down' : 'chevron up'}
                        onClick={action(() => {
                            if (this.hidden[key]) {
                                delete this.hidden[key];
                            } else {
                                this.hidden[key] = true;
                            }
                            localStorage.setItem('rexHiddenContacts', JSON.stringify(this.hidden));
                        })}
                    />
                </StyledVoicemailBox>
                {renderVoicemails()}
            </React.Fragment>
        );
    }

    containerNode = null;

    render() {
        return (
            <React.Fragment>
                <SearchContainer data-test-search-container hasFocus={this.searchFocus} clearable={this.search !== ''}>
                    <Input fluid autoFocus
                        hasFocus={this.searchFocus}
                        icon="search"
                        value={this.search}
                        onChange={(e, { value }) => {
                            this.search = value;
                            this.debouncedFetchLists();
                        }}
                        onFocus={() => this.searchFocus = true}
                        onBlur={() => this.searchFocus = false}
                        ref={(node) => this.searchInput = node}
                        placeholder={t('nav.search.placeholder')}
                    />
                    <ClearButton
                        name="delete"
                        searchFocus={this.searchFocus}
                        active={this.search !== ''}
                        onClick={(e) => {
                            this.search = '';
                            this.searchInput.focus();
                            this.fetchLists();
                        }}
                    />
                    <DialKeyButton
                        name="dialpad"
                        searchFocus={this.searchFocus}
                        clearable={this.search !== ''}
                        active={this.dialKeys}
                        onClick={() => this.dialKeys = !this.dialKeys}
                    />
                    {this.dialKeys && (
                        <CallDialKeysContainer>
                            <CallDialKeys>
                                {DIAL_KEYS.map((key) => (
                                    key === null ? <div /> : (
                                        <CallDialKey onClick={() => {
                                            this.search += key
                                            this.debouncedFetchLists();
                                        }}>
                                            {DIAL_KEY_ICONS[key] ? <Icon name={DIAL_KEY_ICONS[key]} /> : key}
                                        </CallDialKey>
                                    )
                                ))}
                            </CallDialKeys>
                        </CallDialKeysContainer>
                    )}
                    <FavoriteToggle
                        searchFocus={this.searchFocus}
                        active={this.favoriteFilter}
                        onClick={() => {
                            this.favoriteFilter = !this.favoriteFilter;
                            this.fetchLists();
                        }}
                    />
                </SearchContainer>
                <StyledDateFilter data-test-voicemail-date-filter >
                    <StyledDateFilterButtonsContainer>
                        <StyledDateFilterButton
                            className={this.dateFilter === 'day' ? 'selected' : ''}
                            onClick={() => {
                                this.dateFilter = 'day';
                                this.fetchLists({ showLoad: false });
                            }}
                        >
                            {t('voicemailTab.dateFilter.day')}
                        </StyledDateFilterButton>
                        <StyledDateFilterButton
                            className={this.dateFilter === 'week' ? 'selected' : ''}
                            onClick={() => {
                                this.dateFilter = 'week';
                                this.fetchLists({ showLoad: false });
                            }}
                        >
                            {t('voicemailTab.dateFilter.week')}
                        </StyledDateFilterButton>
                    </StyledDateFilterButtonsContainer>
                        <StyledDateFilterIconButton
                            className={this.dateFilter === 'none' ? 'selected' : ''}
                            onClick={() => {
                                this.dateFilter = 'none';
                                this.fetchLists({ showLoad: false });
                            }}
                        >
                            <Icon name='eye' />
                            {t('voicemailTab.dateFilter.all')}
                        </StyledDateFilterIconButton>
                </StyledDateFilter>
                <VoicemailsContainer data-test-voicemails-container scrolled={this.scrollView.top !== 0}>
                    <FullDimmable>
                        <ContactDimmer inverted active={this.loadingLists}>
                            <Loader inverted size="big" />
                        </ContactDimmer>
                        <Scrollbars onScroll={this.onScroll}>
                            {!this.loadingLists && this.stores.length > 0 ? this.renderVoicemailBoxes() : null}
                            {!this.loadingLists && this.stores.length === 0 && (this.phoneNumberSearch === null ? (
                                <NoResults>{t('contactTab.noResults')}</NoResults>
                            ) : (
                                <VoicemailContact>
                                    <VoicemailInfo user={new Contact({
                                        firstName: this.phoneNumberSearch,
                                        avatar: UnknownAvatar,
                                        company: { id: 1, name: t('contactTab.unknownPhoneNumber') },
                                    }, {
                                        relations: STORE_RELATIONS['contact'],
                                    })}>
                                        <FlatLink to={`/contact/add/${this.phoneNumberSearch}`}>
                                            <IconButton clickable name="user add" />
                                        </FlatLink>
                                        <CallButton number={this.search} />
                                    </VoicemailInfo>
                                </VoicemailContact>
                            ))}
                        </Scrollbars>
                    </FullDimmable>
                </VoicemailsContainer>
            </React.Fragment>
        );
    }
}
