import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { Contact } from 'store/Contact';

export const DESCRIPTION_ICONS = {
    business: 'building',
    mobile: 'mobile alternate',
    home: 'home',
};

export class ContactPhoneNumber extends EngineModel {
    static backendResourceName = 'contact_phone_number';

    @observable id = null;
    @observable phoneNumber = '';
    @observable description = '';
    @observable primary = false;

    relations() {
        return {
            contact: Contact,
        };
    }
}

export class ContactPhoneNumberStore extends EngineStore {
    static backendResourceName = 'contact_phone_number';
    Model = ContactPhoneNumber;
}
