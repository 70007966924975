import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { theme } from 'styles';
import { Icon } from 'semantic-ui-react';
import ImgLogo from 'image/logo.svg';
import { BUILD_INFO } from 'helpers';

const Sidebar = styled.section`
    flex: 0 0 auto;
    width: 50px;
    background-color: ${theme.secondaryColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 100;
    padding-bottom: 50px;
`;

const Button = styled.div`
    width: 50px;
    height: 50px;
    font-size: 1.25rem;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    ${({ active }) => active ? `
        background-color: ${theme.primaryColor};
        color: ${theme.secondaryColor};
    ` : `
        color: #fff;
    `}
    i.icon {
        margin: 0 !important;
    }
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5em;
        height: 1.5em;
    }
`;

const Build = styled.a`
    width: 50px;
    height: 50px;
    position: absolute;
    text-align: center;
    bottom: 40px;
    color: rgba(177, 177, 177, 0.6);
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

const CyLink = styled.a`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    #cy-logo-c {
        fill: rgba(255, 255, 255, 0.2);
        transition: fill 600ms ease;
    }
    #cy-logo-y {
        fill: rgba(255, 255, 255, 0.2);
        transition: fill 600ms ease;
    }
    &:hover {
        #cy-logo-c {
            fill: #ebbb12;
        }
        #cy-logo-y {
            fill: #fff;
        }
    }
`;

const Logo = styled.img`
    width: 40px;
    margin: 5px;
`;

const TABS = [
    {
        key: 'contacts',
        icon: <Icon name="users" />,
    },
    {
        key: 'voicemail',
        icon: <Icon className="voicemail" />,
    },
    {
        key: 'call schedule',
        icon: (
            <Icon.Group>
                <Icon name="calendar outline" style={{
                    fontSize: '130%',
                }} />
                <Icon className="phone alt" style={{
                    fontSize: '60%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, calc(-50% + 2px))',
                }} />
            </Icon.Group>
        ),
        wip: true,
    },
    {
        key: 'call queue',
        icon: <Icon name="call-queue" />,
        wip: true,
    },
];

@observer
export default class AppSidebar extends Component {
    static propTypes = {
        selected: PropTypes.string,
        onSelect: PropTypes.func,
        store: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);

        this.renderTab = this.renderTab.bind(this);
    }

    @observable tab = null;

    closeTab = () => {
        const { onSelect } = this.props;
        onSelect(null);
    }

    renderTab({ key, icon, wip = false }) {
        const { selected, onSelect } = this.props;

        if (wip) {
            // {wip && <WIPRibbon scale={0.75} />} wip banner code in case
            return
        } 

        return (
            <Button
                key={key}
                active={selected === key}
                className={
                    selected === key
                    ? `sidebar-button active`
                    : `sidebar-button inactive`
                }
                onClick={e => {
                    onSelect(key)

                    // Don't close it again
                    e.stopPropagation();
                }}
            >
                {typeof icon === 'function' ? icon(selected === key) : icon}
            </Button>
        );
    }

    render() {
        const { currentEngineUser } = this.props.store;
        const { version, branch } = BUILD_INFO;

        return (
            <Sidebar onClick={this.closeTab}>
                <Logo src={ImgLogo} alt="REX" />
                {(currentEngineUser.hasPermission('contact.manage_contact')) && TABS.map(this.renderTab)}
                <Build
                    href="/changelog"
                    onClick={(e) => e.stopPropagation()}
                >
                    {branch && branch !== 'production' && branch + ' '}
                    {version ? version : 'dev'}
                </Build>
                <CyLink
                    href="https://www.codeyellow.nl/"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                >
                    <svg width="50" height="50" viewBox="0 0 1 1">
                        <g transform="scale(0.8) translate(0.1, 0.1)">
                            <path id="cy-logo-c" d="
                                M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
                                l 0.28125,0.28125
                                l 0.125,-0.125
                                l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
                                l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
                                l 0.15625,0.15625
                                l 0.125,-0.125
                                l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
                            " />
                            <path id="cy-logo-y" d="
                                M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
                                l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
                                l -0.09375,-0.09375
                                l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
                                l -0.15625,-0.1562
                                l -0.125,0.125
                                l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
                                l -0.15625,0.15625
                            " />
                        </g>
                    </svg>
                </CyLink>
            </Sidebar>
        );
    }
}
