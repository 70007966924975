import { observable } from 'mobx';
import { CallGroupCallerId } from 'store/CallGroupCallerId';
import { EngineModel, EngineStore, Casts } from './Base';
import { Extension } from './Extension';
import { EngineUser } from './EngineUser';
import { ProvisioningModel } from './ProvisioningModel';
import { omit } from 'lodash';

export const TYPE_ICONS = {
    REX: 'rex-alt',
    'sip phone': 'phone-alt',
    'vamo handset': 'mobile-alt',
};

export class Device extends EngineModel {
    static backendResourceName = 'device';

    static TYPES = ['REX', 'sip phone', 'vamo handset'];

    @observable id = null;
    @observable name = '';
    @observable type = 'sip phone';
    @observable extensionNumber = null;
    @observable config = null;
    @observable callAnonymous = false;
    @observable isRegistered = false;
    @observable mac = '';
    @observable serial = '';
    @observable line = null;
    @observable timeout = 20;
    @observable hotdesking = false;
    @observable vamoVoicemailEnabled = false;
    @observable callWaitingEnabled = false;

    relations() {
        return {
            callerId: CallGroupCallerId,
            user: EngineUser,
            extension: Extension,
            provisioningModel: ProvisioningModel,
            registrations: DeviceRegistrationStore,
        };
    }

    toBackend(options = {}) {
        const res = omit(super.toBackend(options), 'config', 'extension_number');

        if (
            this.__activeCurrentRelations.includes('provisioningModel') &&
            this.provisioningModel.isNew
        ) {
            if (this.mac !== '') {
                res.mac = '';
            }
            if (this.serial !== '') {
                res.serial = '';
            }
            if (this.line !== null) {
                res.line = null;
            }
        }

        if (this.type !== 'sip phone') {
            res.hotdesking = false
        } else if (this.hotdesking) {
            res.user = null
        }

        return res;
    }
}

export class DeviceRegistration extends EngineModel {
    static backendResourceName = 'device_registration';

    @observable id = null;
    @observable status = '';
    @observable timestamp = null;
    @observable expires = null;
    @observable sourceIp = '';
    @observable username = '';
    @observable realm = '';
    @observable userAgent = '';
    @observable sipUri = '';

    relations() {
        return {
            device: Device
        };
    }

    casts() {
        return {
            timestamp: Casts.datetime,
        };
    }
}

export class DeviceStore extends EngineStore {
    static backendResourceName = 'device';
    Model = Device;
}

export class DeviceRegistrationStore extends EngineStore {
    static backendResourceName = 'device_registration';
    Model = DeviceRegistration;
}
