import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';

export const TYPE_ICONS = {
    call_queue_on_hold_sound: 'music',
    call_queue_announce_sound: 'call-queue',
    dial_plan_recording_message: 'microphone',
    dial_plan_audio_message: 'volume up',
    dial_plan_menu_message: 'sitemap'
};
export const TYPE_ICON_PROPS = {
    dial_plan_menu_message: { style: { transform: 'translate(-2px, 1px) rotate(270deg)' } },
};
export const TYPE_COLORS = {
    call_queue_on_hold_sound: '#41a85f',
    call_queue_announce_sound: '#ebbb12',
    dial_plan_recording_message: '#e04080',
    dial_plan_audio_message: '#41a85f',
    dial_plan_menu_message: '#f55555'
};

export class Media extends EngineModel {
    static backendResourceName = 'media';
    static fileFields = ['file'];
    static SOURCES = ['rex', 'kazoo'];
    static TYPES = [
        'call_queue_on_hold_sound',
        'call_queue_announce_sound',
        'dial_plan_recording_message',
        'dial_plan_audio_message',
        'dial_plan_menu_message'
    ];

    @observable id = null;
    @observable source = 'rex';
    @observable type = null;
    @observable name = '';
    @observable description = '';
    @observable file = null;

    @observable hash = null;
    @observable contentType = null;

    fileFields() {
        return this.constructor.fileFields;
    }
}

export class MediaStore extends EngineStore {
    static backendResourceName = 'media';

    Model = Media;
}
