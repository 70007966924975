import { observable } from 'mobx';
import { CalendarAccount } from './CalendarAccount';
import { EngineModel, EngineStore, Casts } from './Base';

export class CalendarEvent extends EngineModel {
    static backendResourceName = 'calendar_event';

    @observable id = null;
    @observable begin = null;
    @observable end = null;
    @observable description = '';

    casts() {
        return {
            begin: Casts.datetime,
            end: Casts.datetime,
        };
    }

    relations() {
        return {
            calendarAccount: CalendarAccount,
        }
    }
}

export class CalendarEventStore extends EngineStore {
    static backendResourceName = 'calendar_event';
    Model = CalendarEvent;
}
