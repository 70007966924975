import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { showKeys } from 'helpers/keys';

const StyledPopup = styled(Popup)`
    padding: 0.2rem 0.4rem !important;
    margin-bottom: 0.5rem !important;
    &::before {
        width: 0.4rem !important;
        height: 0.4rem !important;
        margin-left: -0.2rem !important;
        bottom: -0.2rem !important;
    }
`;

const Keys = styled.span`
    color: rgba(255, 255, 255, 0.65);
    ${({ noMargin }) => noMargin ? `` : `
        margin-left: 0.5rem;
    `}
`;

@observer
export default class Tooltip extends Component {
    static propTypes = {
        hide: PropTypes.bool,
        content: PropTypes.node,
        keys: PropTypes.any,
        children: PropTypes.node,
    };

    static defaultProps = {
        hide: false,
        content: null,
        children: null,
    };

    render() {
        const { hide, keys, content, children, ...props } = this.props;

        if (hide) {
            return children;
        }

        return (
            <StyledPopup inverted
                size="tiny"
                position="top center"
                trigger={children}
                content={
                    <React.Fragment>
                        {content}
                        {keys && (
                            <Keys noMargin={content === null}>
                                {showKeys(keys)}
                            </Keys>
                        )}
                    </React.Fragment>
                }
                {...props}
            />
        );
    }
}
