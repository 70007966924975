// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureCurrentUser(givenViewStore) {
    viewStore = givenViewStore;
}

export function getCurrentUser() {
    return viewStore.currentUser;
}

export function getCurrentClient() {
    return viewStore.currentClient;
}

export function getCurrentEngineUser() {
    return viewStore.currentEngineUser;
}

export function getCurrentEngineClient() {
    return viewStore.currentEngineClient;
}
