import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon, Header, Image, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { User } from 'store/User';
import { Contact } from 'store/Contact';
import { EngineUser } from 'store/EngineUser';
import defaultAvatar from 'image/default_avatar.png';
import { theme } from 'styles';
import styled from 'styled-components';
import { omit } from 'lodash';
import EngineImage from 'component/EngineImage';

export const AVAILABILITY_COLORS = {
    full: '#41a85f',
    calling: theme.primaryColor,
    partial: '#f37934',
    none: '#e14938',
    offline: '#cccccc',
    device: '#ad52f4',
};

export const AVAILABILITY_FG_COLORS = {
    full: '#FFF',
    calling: '#FFF',
    partial: '#FFF',
    none: '#FFF',
    offline: 'rgba(0, 0, 0, 0.5)',
    device: '#FFF',
};

export const STATUS_ICONS = {
    available: { name: 'user' },
    available_device: { name: 'phone' },
    available_internal: { name: 'phone-slash' },
    follow_up: { name: 'desktop' },
    training: { name: 'chalkboard-teacher' },
    lunch: { name: 'utensils' },
    bathroom: { name: 'toilet' },
    meeting: { name: 'users' },
    away_from_desk: { name: 'clock' },
    out_of_office: { name: 'door-open' },
    do_not_disturb: { name: 'minus circle' },
    on_holiday: { name: 'plane' },
    not_available: { className: 'user-slash' },
    calling: { name: 'phone volume' },
};

const ImageContainer = styled.span`
    position: relative;
    flex-shrink: 0;
`;

const ImageCorner = styled.div`
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 16px;
    height: 16px;
    line-height: 12px;
    background-color: ${({ color }) => color};
    color: ${({ fgColor }) => fgColor};
    border-radius: 50%;
    text-align: center;
    > i.icon {
        margin: 0 !important;
        font-size: 8px;
    }
`;

const AvatarEngineImage = styled((props) => <EngineImage avatar {...omit(props, 'noBorder', 'color')} />)`
    object-fit: cover;
    ${({ noBorder, color }) => noBorder ? `` : `
        border: 3px solid ${color};
    `}
`;

export const AvatarImage = styled((props) => <Image avatar {...omit(props, 'noBorder', 'color')} />)`
    object-fit: cover;
    ${({ noBorder, color }) => noBorder ? `` : `
        border: 3px solid ${color};
    `}
`;

@observer
export class SmallAvatar extends Component {
    static propTypes = {
        user: PropTypes.oneOfType([
            PropTypes.instanceOf(User).isRequired,
            PropTypes.instanceOf(Contact).isRequired,
            PropTypes.instanceOf(EngineUser).isRequired,
        ]).isRequired,
        online: PropTypes.bool,
        noBorder: PropTypes.bool,
    };

    static defaultProps = {
        online: false,
        noBorder: false,
    };

    render() {
        const { user, online, noBorder, ...rest } = this.props;

        const realUser = user instanceof Contact ? user.user : user;
        const realContact = user instanceof EngineUser ? user.contact : user;

        let status;
        let availability;

        if (realUser.isNew) {
            status = null;
            availability = null;
        } else {
            if (realUser.registeredDevice && !realUser.isOnline && !online) {
                status = 'available_device';
                availability = 'device';
            } else {
                status = online ? realUser.baseStatus : realUser.status;
                if (status === 'calling') {
                    availability = 'calling';
                } else {
                    availability = online ? realUser.baseAvailability : realUser.availability;
                    if (availability === 'none' && !online && !realUser.isOnline) {
                        availability = 'offline';
                    }
                }
            }
        }

        return (
            <ImageContainer>
                {user instanceof User ? (
                    <AvatarImage
                        {...rest}
                        src={realContact.avatar || defaultAvatar}
                        color={AVAILABILITY_COLORS[availability]}
                        noBorder={realUser.isNew || noBorder}
                    />
                ) : (
                    <AvatarEngineImage
                        {...rest}
                        src={realContact.avatar}
                        defaultSrc={defaultAvatar}
                        color={AVAILABILITY_COLORS[availability]}
                        noBorder={realUser.isNew || noBorder}
                    />
                )}
                {!(realUser.isNew || noBorder) && (
                    <ImageCorner
                        color={AVAILABILITY_COLORS[availability]}
                        fgColor={AVAILABILITY_FG_COLORS[availability]}
                    >
                        <Icon {...STATUS_ICONS[status]} />
                    </ImageCorner>
                )}
            </ImageContainer>
        );
    }
}

export class UserAvatar extends Component {
    static propTypes = {
        user: PropTypes.instanceOf(EngineUser).isRequired,
        withName: PropTypes.bool,
        className: PropTypes.string,
        baseStatus: PropTypes.bool,
    };

    static defaultProps = {
        baseStatus: false,
    };

    render() {
        const { user, baseStatus, withName, className, ...rest } = this.props;

        const userNode = (
            <span className={className} data-test-user-avatar={user.fullName}>
                <SmallAvatar user={user} baseStatus={baseStatus} {...rest} />
                {withName && <span data-test-full-name> {user.fullName}</span>}
            </span>
        );
        return (
            <Popup trigger={userNode} flowing>
                <Header as='h4'>{this.props.user.fullName}</Header>
                <div><strong>{t('user.field.email.label')}:</strong> {this.props.user.email}</div>
            </Popup>
        );
    }
};

export default UserAvatar;
