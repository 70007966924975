import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import phone from '../phone';
import { Extension, TYPE_ICONS as EXTENSION_TYPE_ICONS, EXTENSION_TYPE_COLORS } from 'store/Extension';
import { Device, TYPE_ICONS as DEVICE_TYPE_ICONS } from 'store/Device';
import { ContactPhoneNumber, DESCRIPTION_ICONS } from 'store/ContactPhoneNumber';
import { theme } from 'styles';

const StyledButton = styled(Button)`
    padding-top: calc(0.78571429em - 1px) !important;
    padding-bottom: calc(0.78571429em - 1px) !important;
    border: 1px solid #E0E1E2 !important;
    background-color: #F0F1F2 !important;
    > i.icon {
        color: ${({ color }) => color};
        background-color: #E8E9EA !important;
    }
    &:hover, &:active {
        border-color: #D8D9DA !important;
        background-color: #E8E9EA !important;
        > i.icon {
            background-color: #E0E1E2 !important;
        }
    }
`;

@observer
export default class CallButton extends Component {
    static propTypes = {
        number: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.instanceOf(Extension).isRequired,
            PropTypes.instanceOf(ContactPhoneNumber).isRequired,
        ]).isRequired,
        device: PropTypes.instanceOf(Device),
        disabled: PropTypes.bool,
    };

    constructor(...args) {
        super(...args);

        this.call = this.call.bind(this);
    }

    @computed get icon() {
        const { number, device } = this.props;

        if (typeof number === 'string' || typeof number === 'number') {
            return 'phone';
        } else if (number instanceof Extension) {
            if (
                number.type === 'device' &&
                (device || number.__activeCurrentRelations.includes('device'))
            ) {
                return DEVICE_TYPE_ICONS[(device || number.device).type];
            } else {
                return EXTENSION_TYPE_ICONS[number.type];
            }
        } else if (number instanceof ContactPhoneNumber) {
            // eslint-disable-next-line no-unused-vars
            for (const [key, icon] of Object.entries(DESCRIPTION_ICONS)) {
                if (number.description === t(`contactPhoneNumber.field.description.value.${key}`)) {
                    return icon;
                }
            }
            return 'phone';
        } else {
            throw new Error('cannot resolve icon');
        }
    }

    @computed get color() {
        const { number } = this.props;
        if (number instanceof Extension) {
            return EXTENSION_TYPE_COLORS[number.type];
        } else {
            return theme.secondaryColor;
        }
    }

    @computed get number() {
        const { number } = this.props;

        if (typeof number === 'string' || typeof number === 'number') {
            return number.toString();
        } else if (number instanceof Extension) {
            return number.extension;
        } else if (number instanceof ContactPhoneNumber) {
            return number.phoneNumber;
        } else {
            throw new Error('cannot resolve number');
        }
    }

    call() {
        return phone.call(this.number);
    }

    render() {
        const { number, ...props } = this.props;
        return (
            <StyledButton
                labelPosition="left"
                icon={this.icon}
                color={this.color}
                content={this.number}
                onClick={this.call}
                {...props}
            />
        );
    }
}
