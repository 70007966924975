import React from 'react';
import { action, observable, computed, toJS } from 'mobx';
import { Model as BModel, Store as BStore, BinderApi, Casts as BCasts } from 'mobx-spine';
import { uniq, mapKeys, mapValues, get, forIn } from 'lodash';
import moment from 'moment';
import { PUBLIC_URL, snakeToCamel, FRONTEND_API_BASE_URL } from 'helpers';
import concatWithoutOverlap from 'helpers/concatWithoutOverlap';
import { Popup, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { LocalTime } from 'js-joda';

const RE_SPLIT_FIRST_RELATION = /([^.]+)\.(.+)/;

class MyApi extends BinderApi {
    baseUrl = FRONTEND_API_BASE_URL;
    socketUrl = null;

    fetchStore({ url, data, requestOptions }) {
        return this.get(url, data, requestOptions).then(res => {
            return {
                response: res,
                data: res.data,
                repos: res.with,
                relMapping: res.with_mapping,
                reverseRelMapping: res.with_related_name_mapping,
                totalRecords: res.meta.total_records,
                meta: res.meta,
            };
        });
    }

    __request(method, url, data, { skipTestUrl = false, ...options } = {}) {
        if (skipTestUrl) {
            const oldTestUrl = this.__testUrl;
            this.__testUrl = () => { };
            try {
                return super.__request(method, url, data, options);
            } finally {
                this.__testUrl = oldTestUrl;
            }
        } else {
            return super.__request(method, url, data, options);
        }
    }
}

const cache = {};

class EngineApi extends BinderApi {
    baseUrl = null;
    socketUrl = `${PUBLIC_URL || ''}/ws/`;

    constructor(...args) {
        super(...args);
        this.getFileUrl = this.getFileUrl.bind(this);
    }

    getFileUrl(url) {
        if (url instanceof Blob || url instanceof File) {
            return Promise.resolve(URL.createObjectURL(url));
        } else if (typeof url !== 'string' || !url.startsWith('/api/')) {
            return Promise.resolve(url);
        }

        const key = url;
        if (cache[key] !== undefined) {
            return cache[key];
        }

        // Strip leading '/api'
        url = url.slice(4);

        // Parse params
        const params = {};
        const searchPos = url.indexOf('?');
        if (searchPos !== -1) {
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] in new URLSearchParams(url.slice(searchPos)).entries()) {
                params[key] = value;
            }
            url = url.slice(0, searchPos);
        }

        cache[key] = (
            this.get(url, params, { responseType: 'blob' })
                .then((res) => URL.createObjectURL(res))
        );
        return cache[key];
    }

    fetchStore({ url, data, requestOptions }) {
        return this.get(url, data, requestOptions).then(res => {
            return {
                response: res,
                data: res.data,
                repos: res.with,
                relMapping: res.with_mapping,
                reverseRelMapping: res.with_related_name_mapping,
                totalRecords: res.meta.total_records,
                meta: res.meta,
            };
        });
    }
}

export const myApi = new MyApi();
export const engineApi = new EngineApi();

// Stolen from mobx spine, since it's not exported.
export function parseBackendValidationErrors(response) {
    const valErrors = get(response, 'data.errors');
    if (response.status === 400 && valErrors) {
        return valErrors;
    }
    return null;
}

export class Model extends BModel {
    api = myApi;
    static idPrefix = '';
    static idColor = '';

    getUrl() {
        return '';
    }

    @observable __actuallyUsefulErrors = {}

    @computed get _id() {
        return this.constructor.idPrefix + (this.id ? this.id : ' New');
    }

    getLabel({ hover, ...props } = {}) {
        let label = (
            <Label color={this.constructor.idColor} {...props}>
                {this._id}
            </Label>
        );
        if (hover !== undefined) {
            label = (
                <Popup trigger={label} content={hover} />
            );
        }
        return label;
    }

    getLink(props = {}) {
        return this.getLabel({
            as: Link,
            to: this.getUrl(),
            style: { textDecoration: 'none' },
            ...props,
        });
    }

    @computed get actuallyUsefulErrors() {
        return this.__actuallyUsefulErrors;
    }

    markChanged(field) {
        if (!this.__changes.includes(field)) {
            this.__changes.push(field);
        }
    }

    getAllFlattenedWildcardErrors() {
        let errors = this.getWildcardErrors();

        this.__activeCurrentRelations.forEach(attr => {
            errors = errors.concat(this[attr].getWildcardErrors());
        });
        return uniq(errors);
    }

    getWildcardErrors() {
        return toJS(this.backendValidationErrors['*']) || [];
    }

    // Overwrite to for better backend validation.
    @action
    parseValidationErrors(valErrors) {
        const bname = this.constructor.backendResourceName;

        if (valErrors[bname]) {
            const id = this.getInternalId();
            // When there is no id or negative id, the backend may use the string 'null'. Bit weird, but eh.
            const errorsForModel =
                valErrors[bname][id] || valErrors[bname]['null'];
            if (errorsForModel) {
                const camelCasedErrors = mapKeys(errorsForModel, (value, key) =>
                    snakeToCamel(key)
                );
                const formattedErrors = mapValues(
                    camelCasedErrors,
                    valError => {
                        return valError.map(obj => obj.message || obj.code); // Show backend message if available. T16564
                    }
                );
                this.__backendValidationErrors = formattedErrors;
                this.__actuallyUsefulErrors = camelCasedErrors;
            }
        }

        this.__activeCurrentRelations.forEach(currentRel => {
            this[currentRel].parseValidationErrors(valErrors);
        });
    }

    restore() {
        return this.api.post(this.url);
    }

    __scopeBackendResponse({ data, targetRelName, repos, mapping, reverseMapping }) {
        let scopedData = null;
        let relevant = false;
        const scopedRepos = {};
        const scopedRelMapping = {};
        const scopedReverseRelMapping = {};

        if (!data) {
            return null;
        }

        forIn(mapping, (repoName, backendRelName) => {
            const repository = repos[repoName];
            // For backwards compatibility, reverseMapping is optional (for now)
            const reverseRelName = reverseMapping ? reverseMapping[backendRelName] : null;
            const relName = this.constructor.fromBackendAttrKey(backendRelName);

            if (targetRelName === relName) {
                const relKey = data[this.constructor.toBackendAttrKey(relName)];
                if (relKey !== undefined) {
                    relevant = true;
                    scopedData = this.__parseRepositoryToData(relKey, repository);
                } else if (repository && reverseRelName) {
                    const pk = data[this.constructor.primaryKey];
                    relevant = true;
                    scopedData = this.__parseReverseRepositoryToData(reverseRelName, pk, repository);

                    if (this.relations()[relName].prototype instanceof Model) {
                        if (scopedData.length === 1) {
                            scopedData = scopedData[0];
                        } else if (scopedData.length === 0) {
                            scopedData = 0;
                        } else {
                            throw new Error('multiple related models found for model');
                        }
                    }
                }
                return;
            }

            if (relName.startsWith(`${targetRelName}.`)) {
                // If we have town.restaurants and the targetRel = town
                // we need "restaurants" in the repository
                relevant = true;
                const backendRelNames = backendRelName.match(RE_SPLIT_FIRST_RELATION);
                const scopedBackendRelName = backendRelNames[2];
                scopedRepos[repoName] = repository;
                scopedRelMapping[scopedBackendRelName] = repoName;
                scopedReverseRelMapping[scopedBackendRelName] = reverseMapping ? reverseMapping[backendRelName] : null;
            }
        });

        if (!relevant) {
            return null;
        }

        return { scopedData, scopedRepos, scopedRelMapping, scopedReverseRelMapping };
    }
}

export class EngineModel extends Model {
    api = engineApi;
}

export class Store extends BStore {
    api = myApi;

    @observable meta = {};

    markChanged() {
        this.__setChanged = true;
    }

    getByCid(cid) {
        return this.models.find(model => model.cid === cid);
    }

    getWildcardErrors() {
        let errors = [];
        this.models.forEach(model => {
            errors = errors.concat(model.getWildcardErrors());
        });
        return errors;
    }

    ignoreSetChanges(func) {
        const hasSetChanges = this.hasSetChanges;
        const res = func();

        if (res instanceof Promise) {
            res.catch(() => { }).then(() => {
                if (!hasSetChanges) {
                    this.clearSetChanges();
                }
            });

            return res;
        } else if (!hasSetChanges) {
            this.clearSetChanges();
        }

        return res;
    }

    fromBackend({ meta = {}, ...rest }) {
        this.meta = meta;
        return super.fromBackend(rest);
    }
}

export class EngineStore extends Store {
    api = engineApi;
}

export const api = myApi;

export const Casts = {
    ...BCasts,
    decimal: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return value.replace(/,/g, '').replace('.', ',');
        },
        toJS(attr, value) {
            if (value === null || value === '') {
                return null;
            }
            return value.replace(/\./g, '').replace(',', '.');
        },
    },
    durationMinutes: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return moment.duration(value, 'minutes');
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }
            // https://github.com/CodeYellowBV/mobx-spine/issues/57
            if (value === 0) {
                return 0;
            }
            return value.asMinutes();
        },
    },
    duration: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }

            return moment.duration(value);
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return value.toISOString();
        },
    },
    nullableDatetime: {
        parse(attr, value) {
            return Casts.datetime.parse(attr, value);
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return Casts.datetime.toJS(attr, value);
        },
    },
    file: {
        parse(attr, value) {
            if (value) {
                return concatWithoutOverlap(myApi.baseUrl, value);
            }

            return null;
        },
        toJS(attr, value) {
            return value;
        },
    },
    time: {
        parse(attr, value) {
            if (value) {
                return LocalTime.parse(value);
            }

            return null;
        },
        toJS(attr, value) {
            if (value) {
                return value.toString();
            }

            return value;
        },
    }
};


export function OrderedStore(UnorderedStore, orderingField) {
    return class extends UnorderedStore {
        comparator = orderingField;

        addPos(pos, ...args) {
            this.forEach((obj, i) => {
                if (i >= pos) {
                    obj.setInput(orderingField, i + 1);
                }
            });

            const model = this.add(...args);
            model[orderingField] = pos;
            this.sort();

            return model;
        }

        addFirst(...args) {
            return this.addPos(0, ...args);
        }

        _newModel(model = null, i) {
            const m = super._newModel(model);

            // if (!(orderingField in model)) {
            if (this.length > 0) {
                const nextOrdering = Math.max(...this.map(aModel => aModel[orderingField])) + 1;
                m[orderingField] = isFinite(nextOrdering) ? nextOrdering : this.length + 1;
            } else {
                m[orderingField] = i;
            }
            // }

            return m;
        }
    }
}


export function subscribe(room, callback, targetApi = engineApi) {
    const result = targetApi.socket.subscribe({
        onPublish: callback,
        room,
    });
    const socket = targetApi.socket;

    result.unsubscribe = function() {
        // Fix a rare bug where cypress switches to another view before we are
        // subscribed to this view which sometimes causes cypress to crash
        if (result !== undefined) {
            socket.unsubscribe(result);
        }
    }

    return result;
}
