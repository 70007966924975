import React from 'react';
import styled from 'styled-components';
import { parseToRgb } from 'polished';

export function readableColor(color) {
    const { red, green, blue } = parseToRgb(color);
    var yiq = (red * 299 + green * 587 + blue * 114) / 1000;
    return yiq >= 150 ? 'rgba(0, 0, 0, 0.87)' : '#fff';
}

export const Ribbon = styled(({ children = null, ...props }) => (
    <div {...props}><div>{children}</div></div>
))`
    position: absolute;
    top: ${({ topOffset }) => topOffset ? `calc(-1.5rem - ${topOffset})` : '-1.5rem'};
    right: ${({ rightOffset }) => rightOffset ? `calc(-1.5rem - ${rightOffset})` : '-1.5rem'};
    width: 3rem;
    height: 3rem;
    border-style: solid;
    border-width: 1rem;
    border-color: transparent;
    border-bottom-color: ${({ color }) => color};
    color: ${({ color }) => readableColor(color)};
    font-size: 0.6rem;
    line-height: 3rem;
    > div {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    transform: scale(${({ scale = 1 }) => scale}) rotate(45deg);
    opacity: 50%;
`;

export const WIPRibbon = ({ color = '#ad52f4', children = 'WIP', ...props }) => (// eslint-disable-line
    <Ribbon color={color} {...props}>{children}</Ribbon>
);
