import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { theme } from 'styles';
import styled, { css, injectGlobal } from 'styled-components';
import { EngineUser } from 'store/EngineUser';
import { RexCall } from 'phone';
import { Image, Icon } from 'semantic-ui-react';
import { mix, opacify } from 'polished';
import EngineImage from 'component/EngineImage';
import DefaultAvatar from 'image/default_avatar.png';
import Tooltip from 'component/Tooltip';
import { handleKeys } from 'helpers/keys';
import { showNotification } from 'helpers/notification';
import NumberModal from 'component/NumberModal';

const CALL_BG = '#FFF';
const INCOMING_CALL_BG = mix(0.75, CALL_BG, theme.primaryColor);
const GREEN = '#34bd6a';
const RED = '#f55555';
const DKGREY = '#42444e';

export const END_STATUSES = ['ended', 'not_answered', 'mic_not_granted'];

const ANSWER_KEYS = ['alt', 'A'];
const END_KEYS = ['alt', 'E'];

const HOLD_KEYS = ['alt', 'H']
const UNHOLD_KEYS = HOLD_KEYS;
const MUTE_KEYS = ['alt', 'M'];
const UNMUTE_KEYS = MUTE_KEYS;
const TRANSFER_KEYS = ['alt', 'T'];

const DIAL_KEY_KEYS = {
    '0': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '#': '#',
    '*': '*',
};

const CallDetails = styled.span`
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${opacify(-0.15, CALL_BG)};
    border-radius: 5px;
    padding: 0 5px;
    font-weight: bold;
`;

const CallParticipants = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.75em 0;
`;

const CallParticipant = styled.div`
    display: flex;
    align-items: center;
    height: 1.5em;
    line-height: 1.5em;
    > img {
        width: 3em;
        height: 3em;
        object-fit: cover;
        border-radius: 1.5em;
        border: 2px solid #FFF;
        margin-right: 5px;
    }
    &:nth-child(odd) > img {
        margin-right: calc(1.5em + 5px);
    }
    &:nth-child(even) > img {
        margin-left: 1.5em;
    }
`;

injectGlobal`
    @keyframes incoming-call-container-animation {
        0% { background-color: ${CALL_BG} };
        100% { background-color: ${INCOMING_CALL_BG} };
    }
    @keyframes incoming-call-avatar-animation {
        0% { border-color: ${CALL_BG} };
        100% { border-color: ${INCOMING_CALL_BG} };
    }
    @keyframes incoming-call-details-animation {
        0% { background-color: ${opacify(-0.15, CALL_BG)} };
        100% { background-color: ${opacify(-0.15, INCOMING_CALL_BG)} };
    }
`;

const CallContainer = styled.div`
    margin: 10px;
    padding: 5px;
    background-color: ${CALL_BG};
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.125);
    ${({ ended }) => ended ? `
        opacity: 0.75;
    ` : ``}
    ${({ incoming }) => incoming ? css`
        animation: incoming-call-container-animation 1s alternate infinite;
        ${CallParticipant} > img {
            animation: incoming-call-avatar-animation 1s alternate infinite;
        }
        ${CallDetails} {
            animation: incoming-call-details-animation 1s alternate infinite;
        }
    ` : ``}
    overflow: hidden;
    height: ${({ participants, buttons, dialKeys }) => `calc(${1.5 + participants * 1.5 + (buttons ? 2 : 0) + (dialKeys ? 8 : 0)}em + ${12 + (buttons ? 11 : 0) + (dialKeys ? 25 : 0)}px)`};
    ${({ hide }) => hide ? `
        margin: -1px 10px;
        height: 0;
        padding: 0 5px;
        opacity: 0 !important;
    ` : ``}
    &:focus {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        border-color: ${GREEN};
        outline: none;
        &:focus::before {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            pointer-events: none;
            border: 2px solid ${GREEN}
            border-radius: 5px;
        }
    }
    transition:
        margin 300ms ease,
        height 300ms ease,
        box-shadow 300ms ease;
        padding 300ms ease,
        opacity 300ms ease;
`;

const CallDialKeysContainer = styled.div`
    margin: 5px -5px -5px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.0625);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CallDialKeys = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template: 2em 2em 2em 2em / 3em 3em 3em;
    grid-gap: 5px;
`;

const CallDialKey = styled.div`
    font-weight: bold;
    text-align: center;
    width: 3em;
    height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.125);
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.25);
    }
    transition: background-color 300ms ease;
    > i.icon {
        font-size: 0.75em;
        line-height: 1;
        margin: 0 !important;
    }
`;

const CallButtons = styled.div`
    height: calc(2em + 11px);
    line-height: 2em;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.0625);
    border-top: 1px solid rgba(0, 0, 0, 0.09375);
    padding: 5px;
    margin: 5px -5px -5px;
`;

const CallButton = styled.div`
    width: 2em;
    height: 2em;
    border-radius: 1em;
    line-height: calc(2em - 4px);
    text-align: center;
    margin-left: 5px;
    &:first-child {
        margin-left: 0;
    }
    border: 2px solid ${({ color }) => color};
    ${({ active, color }) => active ? `
        background-color: #FFF;
        color: ${color};
    ` : `
        background-color: ${color};
        color: #FFF;
    `}
    > i.icon {
        margin: 0 !important;
    }
    ${({ onClick }) => onClick ? `
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    ` : `
        opacity: 0.45;
    `}
    ${({ hidden }) => hidden ? `
        margin-left: -1em !important;
        margin-right: -1em !important;
        transform: scale(0);
        opacity: 0;
    ` : ``}
    transition:
        transform 300ms ease,
        opacity 300ms ease,
        color 300ms ease,
        background-color 300ms ease,
        margin-left 300ms ease,
        margin-right 300ms ease;
`;

const DURATION_PARTS = [
    { unit: 's', amount: 60 },
    { unit: 'm', amount: 60 },
    { unit: 'h', amount: 24 },
    { unit: 'd' },
];

function formatDuration(duration) {
    const parts = [];

    for (const { unit, amount } of DURATION_PARTS) { // eslint-disable-line
        let durationPart;
        if (amount === undefined) {
            durationPart = duration;
            duration = 0;
        } else {
            durationPart = duration % amount;
            duration = Math.floor(duration / amount);
        }

        parts.push(durationPart.toString() + unit);

        if (duration === 0) {
            break;
        }
    }

    return parts.reverse().join(' ');
}

const DIAL_KEYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];
const DIAL_KEY_ICONS = {
    '*': 'asterisk',
    '#': 'hashtag',
};

@observer
export default class Call extends Component {
    static propTypes = {
        user: PropTypes.instanceOf(EngineUser).isRequired,
        call: PropTypes.instanceOf(RexCall).isRequired,
    };

    @observable dialKeys = false;
    @observable transfer = false;

    container = null;

    constructor(...args) {
        super(...args);
        this.renderParticipant = this.renderParticipant.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.container) {
                this.container.focus()
            }
            else {
                showNotification({
                    type: 'error',
                    message: t(`call.status.ended_unexpectedly`),
                    dismissAfter: 5000,
                });
            }
        }, 1000);
    }

    onKeyDown(e) {
        if (e.target !== this.container) {
            return;
        }

        const { call } = this.props;

        const keys = [
            [END_KEYS, call.end],
        ];

        if (call.status === 'active') {
            if (call.muted) {
                keys.push([UNMUTE_KEYS, call.unmute]);
            } else {
                keys.push([MUTE_KEYS, call.mute]);
            }
            if (call.onHold) {
                keys.push([UNHOLD_KEYS, call.unhold]);
            } else {
                keys.push([HOLD_KEYS, call.hold]);
            }
            // eslint-disable-next-line no-unused-vars
            for (const [dialKey, dialKeyKeys] of Object.entries(DIAL_KEY_KEYS)) {
                keys.push([dialKeyKeys, () => call.sendKey(dialKey)]);
            }
            keys.push([TRANSFER_KEYS, () => this.transfer = true]);
        } else if (call.status === 'dialing') {
            if (call.incoming) {
                keys.push([ANSWER_KEYS, call.answer]);
            }
        }

        handleKeys(e, keys);
    }

    renderParticipant({ contact, number }) {
        return (
            <CallParticipant key={number}>
                {contact.isNew ? (
                    <React.Fragment>
                        <Image src={DefaultAvatar} />
                        {number}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <EngineImage
                            src={contact.avatar}
                            defaultSrc={DefaultAvatar}
                        />
                        {contact.name}
                    </React.Fragment>
                )}
            </CallParticipant>
        );
    }

    render() {
        const { user, call } = this.props;

        return (
            <CallContainer buttons
                tabIndex="0"
                innerRef={(ref) => this.container = ref}
                key={call.id}
                ended={END_STATUSES.includes(call.status)}
                incoming={call.status === 'dialing' && call.incoming}
                hide={call.new || call.old}
                participants={
                    call.participants.length +
                    (!['dialing', 'not_answered'].includes(call.status) ? 1 : 0)
                }
                dialKeys={this.dialKeys}
                onKeyDown={this.onKeyDown}
            >
                <CallParticipants>
                    {!['dialing', 'not_answered'].includes(call.status) && (
                        this.renderParticipant({
                            contact: user.contact,
                            number: null,
                        })
                    )}
                    {call.participants.map(this.renderParticipant)}
                </CallParticipants>
                <CallDetails>{t(
                    `call.status.${call.status === 'dialing' && call.incoming ? 'incoming' : call.status}`,
                    { duration: formatDuration(call.duration) },
                )}</CallDetails>
                <CallButtons>
                    <CallButton
                        hidden={call.status !== 'active'}
                        color={DKGREY}
                        active={this.dialKeys}
                        onClick={() => this.dialKeys = !this.dialKeys}
                    >
                        <Icon name="dialpad" />
                    </CallButton>
                    <Tooltip
                        content={t('call.tooltip.transfer')}
                        keys={TRANSFER_KEYS}
                    >
                        <CallButton
                            hidden={call.status !== 'active'}
                            color={DKGREY}
                            onClick={() => this.transfer = true}
                        >
                            <Icon name="share" />
                        </CallButton>
                    </Tooltip>
                    <Tooltip
                        content={t(`call.tooltip.${call.muted ? 'unmute' : 'mute'}`)}
                        keys={call.muted ? UNMUTE_KEYS : MUTE_KEYS}
                    >
                        <CallButton
                            hidden={call.status !== 'active'}
                            color={DKGREY}
                            active={call.muted}
                            onClick={call.muted ? call.unmute : call.mute}
                        >
                            <Icon name="microphone slash" />
                        </CallButton>
                    </Tooltip>
                    <Tooltip
                        content={t('call.tooltip.end')}
                        keys={END_KEYS}
                    >
                        <CallButton
                            color={RED}
                            onClick={END_STATUSES.includes(call.status) ? undefined : call.end}
                        >
                            <Icon name="phone" style={{
                                transform: 'translateY(1px) rotate(225deg)',
                                height: '100%',
                            }} />
                        </CallButton>
                    </Tooltip>
                    <Tooltip
                        content={t(`call.tooltip.${
                            call.incoming && call.status === 'dialing'
                            ? 'answer'
                            : call.onHold
                            ? 'unhold'
                            : 'hold'
                        }`)}
                        keys={
                            call.incoming && call.status === 'dialing'
                            ? ANSWER_KEYS
                            : call.onHold
                            ? UNHOLD_KEYS
                            : HOLD_KEYS
                        }
                    >
                        <CallButton
                            color={GREEN}
                            onClick={
                                END_STATUSES.includes(call.status) ||
                                (call.status === 'dialing' & !call.incoming)
                                ? undefined
                                : call.status === 'dialing'
                                ? call.answer
                                : call.onHold
                                ? call.unhold
                                : call.hold
                            }
                        >
                            <Icon name={
                                call.incoming && call.status === 'dialing'
                                ? 'phone'
                                : call.onHold
                                ? 'play'
                                : 'pause'
                            } />
                        </CallButton>
                    </Tooltip>
                </CallButtons>
                <CallDialKeysContainer>
                    <CallDialKeys>
                        {DIAL_KEYS.map((key) => (
                            <CallDialKey onClick={() => call.sendKey(key)}>
                                {DIAL_KEY_ICONS[key] ? <Icon name={DIAL_KEY_ICONS[key]} /> : key}
                            </CallDialKey>
                        ))}
                    </CallDialKeys>
                </CallDialKeysContainer>
                <NumberModal
                    open={this.transfer}
                    onClose={() => {
                        this.transfer = false;
                        this.container.focus();
                    }}
                    onSelect={call.transfer}
                />
            </CallContainer>
        );
    }
}
