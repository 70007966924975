import { observable } from 'mobx';
import { EngineModel, EngineStore } from './Base';
import { VoicemailBox } from './VoicemailBox';
import { Call } from './Call';
import { Contact } from './Contact'

export class VoicemailMessage extends EngineModel {
    static backendResourceName = 'voicemail_message';

    @observable id = null;
    @observable file = null;
    @observable startedAt = null;

    relations() {
        return {
            voicemailBox: VoicemailBox,
            call: Call,
            contact: Contact
        }
    }
}

export class VoicemailMessageStore extends EngineStore {
    static backendResourceName = 'voicemail_message';

    Model = VoicemailMessage;
}
