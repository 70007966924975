export default {
    nav: {
        top: {
            dashboard: 'Dashboard',
            contacts: 'Contacts',
            callHistory: 'Call History',
            reports: 'Reports',
            reseller: 'Clients',
            company: 'Company',
            docs: 'Docs',
            integrations: 'Integrations',
        },
        company: {
            users: 'Users',
            devices: 'Devices',
            publicPhoneNumbers: 'Phone Numbers',
            dialPlans: 'Dial Plans',
            callGroups: 'Call Groups',
            callQueues: 'Call Queues',
            contactLists: 'Contact Lists',
            locations: 'Locations',
            settings: 'Settings',
            media: 'Media',
            extensions: 'Extensions',
        },
        integrations: {
            integration: 'Integrations',
            errors: 'Sync Errors'
        },
        search: {
            placeholder: 'Search & call..',
        },
    },
    callQueue: {
        overview: {
            title: 'Call Queues',
            addButton: 'Add Call Queue',
        },
        edit: {
            title: 'Edit Call Queue',
        },
        create: {
            title: 'New Call Queue',
        },
        field: {
            name: {
                label: 'Name'
            },
            extension: {
                label: 'Extension'
            },
            timeout: { label: 'Timeout' },
            timeoutDestination: { label: 'Timeout Destination' },
            callQueueCallGroups: { label: 'Call Groups' },
            callQueueUsers: { label: 'Users' },
            ringStrategy: {
                label: 'Ring Strategy',
                value: {
                    ring_all: 'Ring All',
                    longest_idle_agent: 'Longest Idle Agent',
                    round_robin: 'Round Robin',
                    top_down: 'Top Down',
                    ring_progressively: 'Ring Progressively',
                },
            },
            onHoldSound: { label: 'On Hold Sound' },
            announceSound: { label: 'Announce Sound' },
            announceInterval: { label: 'Announce Interval' },
            prefixCallerId: { label: 'Prefix Caller ID' },
            maxMembers: { label: 'Max Members' },
            breakouts: {
                label: 'Breakouts',
            },
        },
    },
    callQueueBreakout: {
        field: {
            destination: {
                label: 'Destination',
                value: {
                    notFilled: 'No breakout',
                },
            },
        },
    },
    device: {
        overview: {
            title: 'Devices',
            addButton: 'Add Device'
        },
        edit: {
            title: 'Edit Device'
        },
        create: {
            title: 'New Device'
        },
        field: {
            callerId: { label: 'Caller ID' },
            callAnonymous: { label: 'Call Anonymous' },
            callWaitingEnabled: { label: 'Call waiting' },
            name: {
                label: 'Name'
            },
            extension: {
                label: 'Extension'
            },
            extensionNumber: {
                label: 'Extension'
            },
            user: {
                label: 'User'
            },
            isRegistered: {
                label: 'Registered'
            },
            isAssigned: {
                label: 'Assigned',
            },
            type: {
                label: 'Type',
                value: {
                    REX: 'REX',
                    'sip phone': 'SIP Phone',
                    'vamo handset': 'VaMo Handset',
                },
            },
            config: {
                label: 'SIP Config',
                host: 'Host',
                username: 'Username',
                password: 'Password',
            },
            provisioningModel: { label: 'Provisioning Model' },
            mac: { label: 'MAC address' },
            serial: { label: 'Serial number' },
            line: {
                label: 'Line',
                value: 'Line {{line}}',
            },
            timeout: { label: 'Ring Timeout' },
            hotdesking: { label: 'Hotdesking' },
            vamoVoicemailEnabled: { label: "Voicemail" }
        },
        isRegistered: {
            call: 'Call: {{number}}',
            noCall: 'This device is not registered'
        },
        registrations: {
            label: 'Latest Registrations',
            timestamp: 'Date & Time',
            status: 'Status',
            sourceIp: 'Source IP',
            username: 'Username',
            realm: 'Realm',
            userAgent: 'User Agent',
            expires: 'Expires',
        },
    },
    dialPlan: {
        overview: {
            title: 'Dial Plans',
            addButton: 'Add Dial Plan',
        },
        edit: {
            title: 'Edit Dial Plan',
            tooltip: {
                cut: 'Cut',
                copy: 'Copy',
                paste: 'Paste',
                undo: 'Undo',
                redo: 'Redo',
                delete: 'Delete',
                expand: 'Expand',
                compress: 'Compress',
                deselect: 'Close',
            },
            nodeRep: `{{type}} node`,
            action: {
                cut: 'Cut {{node}}',
                paste: 'Paste {{node}}',
                delete: 'Remove {{node}}',
                add: 'Add {{node}}',
                move: 'Move {{node}}',

                addMenuButton: 'Add menu button',
                deleteMenuButton: 'Remove menu button',
                changeMenuLabel: 'Change menu label',
                changeMenuMessage: 'Change menu message',
                changeMenuTimeout: 'Change menu timeout',
                toggleMenuPrefixCallerId: 'Toggle menu prefix caller ID',
                toggleHolidayNode: 'Status node mode set to holiday',

                changeTimezone: 'Change timeslots timezone',
                changeTimeslotLabel: 'Change timeslot label',
                addTimeslotDay: 'Add timeslot day',
                deleteTimeslotDay: 'Remove timeslot day',
                changeTimeslotStartTime: 'Change timeslot start time',
                changeTimeslotEndTime: 'Change timeslot end time',
                addTimeslotFilter: 'Add timeslot filter',
                deleteTimeslotFilter: 'Remove timeslot filter',
                addTimeslot: 'Add timeslot',
                deleteTimeslot: 'Remove timeslot',
                changeExceptionStartDay: 'Change exception start day',
                changeExceptionEndDay: 'Change exception end day',
                addExceptionDay: 'Add exception day',
                deleteExceptionDay: 'Remove exception day',
                changeExceptionStartTime: 'Change exception start time',
                changeExceptionEndTime: 'Change exception end time',
                addExceptionFilter: 'Add exception filter',
                deleteExceptionFilter: 'Remove exception filter',
                addException: 'Add exception',
                deleteException: 'Remove exception',

                changeRepeatLevel: 'Change repeat',

                changeAudioMessage: 'Change audio message',

                changeTransferType: 'Change transfer type',
                changeTransferExtension: 'Change transfer extension',
                changeTransferContact: 'Change transfer contact',
                changeTransferContactPhoneNumber: 'Change transfer contact phone number',
                changeTransferPhoneNumber: 'Change transfer phone number',
                toggleTransferKeepCallerId: 'Toggle transfer keep caller id',
                toggleTransferTimeout: 'Toggle transfer timeout',
                changeTransferTimeout: 'Change transfer timeout',
                deleteTransferDestination: 'Delete transfer destination',
                addTransferDestination: 'Add transfer destination',

                changeVoicemailType: 'Change voicemail type',
                changeVoicemailBox: 'Change voicemail box',

                changeRecordMessage: 'Change record message',

                toggleStatusOptionStatus: 'Toggle status option status',

                changePrefixPrefix: 'Change prefix',
                changePrefixApplyTo: 'Change prefix apply to',

                changeStatusLabel: 'Change status label',
                changeStatusOptionLabel: 'Change status option label',
                deleteStatusOption: 'Delete status option',
                addStatusOption: 'Add status option',
                SelectTransferCallerPhoneNumber: 'Select different number of origin',
            },
            error: {
                hasDetailErrors: 'Node has invalid details',
                emptyNode: 'Node cannot be empty',

                missingAudioMessage: 'Audio message is missing',

                blankTimeslotLabel: 'Timeslot label is empty',

                missingMenuMessage: 'Menu message is missing',
                blankMenuLabel: 'Menu label is empty',

                missingTransferExtension: 'No transfer to selected',
                missingTransferContact: 'No transfer to selected',
                invalidTransferPhoneNumber: 'Invalid phone number',
                tooLowTransferTimeout: 'Timeout must be at least 5s',

                missingVoicemailBox: 'No box selected',

                missingRecordMessage: 'Record message is missing',

                disabledRecordNode: 'Record Calls should be enabled.',

                blankPrefix: 'Prefix is empty',

                blankStatusLabel: 'Status label is empty',
                blankStatusOptionLabel: 'Status option label is empty',
            },
        },
        delete: {
            header: 'Delete Dial Plan',
            content: 'Are you sure you want to delete this dial plan? This will also delete all corresponding dial plan status dashboard tiles.',
            cancel: 'Cancel',
            confirm: 'Delete'
        },
        create: {
            title: 'New Dial Plan',
        },
        field: {
            name: {
                label: 'Name',
            },
            user: {
                label: 'User'
            },
            extension: {
                label: 'Extension'
            },
            node: {
                type: {
                    audio: {
                        label: 'Audio',
                        field: {
                            message: { label: 'Message' },
                        },
                    },
                    user_status: {
                        label: 'Status',
                        field: {
                            options: {
                                label: 'Options',
                                info: 'The selected statuses determine when you are available in call queues.'
                            },
                        },
                    },
                    dial_plan_status: {
                        label: 'Status',
                        field: {
                            label: { label: 'Label' },
                            holidayNode: {
                                label: 'Holiday Planning',
                                info: 'Uses the configured holiday periods to determine which status should be used'
                            },
                            options: {
                                label: 'Options',
                                addButton: 'Add Option',
                            },
                        },
                    },
                    timeslots: {
                        label: 'Timeslots',
                        field: {
                            timezone: {
                                label: 'Timezone',
                            },
                            timeslots: {
                                label: 'Timeslots',
                                addButton: 'Add Timeslot',
                                start: { before: 'Between' },
                                end: { before: 'and', after: '' },
                            },
                            exceptions: {
                                label: 'Exceptions',
                                empty: 'No exceptions added yet',
                                addButton: 'Add Exception',
                                start: { before: 'Between' },
                                end: { before: 'and', after: '' },
                            },
                            days: {
                                shortValue: {
                                    monday: 'Mo',
                                    tuesday: 'Tu',
                                    wednesday: 'We',
                                    thursday: 'Th',
                                    friday: 'Fr',
                                    saturday: 'Sa',
                                    sunday: 'Su',
                                },
                            },
                        },
                    },
                    menu: {
                        label: 'Menu',
                        field: {
                            message: { label: 'Message' },
                            timeout: { label: 'Timeout' },
                            options: { label: 'Options' },
                            prefix_caller_id: { label: 'Prefix Caller ID' },
                        },
                    },
                    record: {
                        label: 'Record',
                        field: {
                            message: { label: 'Message' },
                            enabled: {
                                label: 'Record Calls',
                                info: 'By checking this box you confirm that you selected an audio message that informs the caller that the call is being recorded and for what purpose.',
                            },
                        },
                    },
                    repeat: {
                        label: 'Repeat',
                        field: {
                            levels: {
                                label: 'Repeat',
                                dialPlan: 'Entire Dial Plan',
                                menu: '{{count}} Menu Up',
                                menu_plural: '{{count}} Menus Up',
                            },
                        },
                    },
                    transfer: {
                        label: 'Transfer',
                        field: {
                            destination: {
                                label: 'Destination',
                                value: {
                                    call_group: 'Call Group',
                                    call_queue: 'Call Queue',
                                    user: 'User',
                                    dial_plan: 'Dial Plan',
                                    device: 'Device',
                                    contact: 'Contact',
                                    phone_number: 'Phone Number',
                                    ring_all: 'Ring All',
                                },
                                addButton: 'Add Destination',
                            },
                            timeout: {
                                label: 'Timeout',
                                disabled: 'Timeout is not supported for this type of destination.',
                            },
                            keep_caller_id: {
                                label: 'Keep Caller ID',
                            },
                            caller_phone_number: {
                                label: 'Number of origin',
                            },
                        },
                    },
                    voicemail: {
                        label: 'Voicemail',
                        field: {
                            type: {
                                value: {
                                    call_group: 'Call Group',
                                    user: 'User',
                                },
                            },
                            box: { label: 'Box' },
                        },
                    },
                    end: {
                        label: 'End',
                    },
                    prefix: {
                        label: 'Prefix',
                        field: {
                            prefix: { label: 'Prefix' },
                            applyTo: {
                                label: 'Apply To',
                                value: {
                                    original: 'Original',
                                    current: 'Current',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    extension: {
        overview: {
            title: 'Extensions',
            moveTooltip: 'Move',
            moveButton: 'Move ({{count}})',
            moveModal: {
                title: 'Move Extension',
                title_plural: 'Move Extensions',
                extension: 'Extension',
                extension_plural: 'Extensions',
                moveButton: 'Move ({{count}})',
            },
            extensionRange: {
                user_dial_plan: 'Users ({{count}})',
                rex_device: 'REX Devices ({{count}})',
                sip_phone_device: 'SIP Phones ({{count}})',
                call_group: 'Call Groups ({{count}})',
                call_queue: 'Call Queues ({{count}})',
                company_dial_plan: 'Dial Plans ({{count}})',
                vamo_handset_device: 'VaMo Handsets ({{count}})',
            },
        },
        field: {
            extension: {
                label: 'Extension',
            },
            type: {
                label: 'Type',
                value: {
                    call_group: 'Call Group',
                    call_queue: 'Call Queue',
                    user: 'User',
                    dial_plan: 'Dial Plan',
                    device: 'Device',
                },
            },
            name: { label: 'Name' },
        },
    },
    clientCustomInfoRabbitmq: {
        field: {
            customInfoRabbitmqServer: {
                label: "Rabbit MQ server location",
                info: 'Here you can configure the Rabbit MQ server location to which custom info sent to REX is to be sent towards'
            },
            enabled: {
                label: "Active",
                info: "Indication if the rabbit MQ is currently active, can be togged yourself. Will auto disable if the rabbitMQ could not be launched"
            },

            customInfoRabbitmqPort: {
                label: "Rabbit MQ server port",
                info: 'Here you can configure the Rabbit MQ port to which custom info sent to REX is to be sent towards'
            },
            customInfoRabbitmqUsername: {
                label: "Rabbit MQ server username",
                info: 'The username to be used when connecting to the company specific rabbit MQ'
            },
            customInfoRabbitmqPassword: {
                label: "Rabbit MQ server password",
                info: 'The Password to be used when connecting to the company specific rabbit MQ. Note this will not be shown again once saved, so an empty field is always to be expected after a refresh'
            },
            customInfoRabbitmqVhost: {
                label: "Rabbit MQ Vhost",
                info: 'The vhost of the rabbitmq queue to be connected to, the rabbitmq default for this is '
            },
            customInfoRabbitmqQueue: { label: "Rabbit MQ Queue", info: 'The queue of the rabbitmq to be connected to' },
            connectionError: {
                label: "Reason connection error"
            },
        },
    },
    client: {
        overview: {
            title: 'Clients',
            activateButton: 'Activate Client',
            activateModal: {
                title: 'Activate Client',
                noCustomers: 'No customers found.',
                activateButton: 'Activate',
                customerModal: {
                    title: 'Activate {{name}}',
                    activateButton: 'Activate & Continue',
                    activateAndViewButton: 'Activate & View',
                },
            },
        },
        create: {
            title: 'New Client'
        },
        edit: {
            title: 'Edit Client'
        },
        field: {
            logo: {
                label: 'Logo',
                info: 'Supported image file types: JPG, JPEG, PNG.'
            },
            onHoldMusic: {
                label: 'On hold music',
                info: "Select client-wide on hold music."
            },
            callRestriction: {
                label: "Call restrictions",
                value: {
                    'internal': "Internal",
                    'nl_no_svc': 'Regional',
                    'nl_all': 'National',
                    'benelux': 'Benelux',
                    'europe': 'Europe',
                    'world_limited': 'Semi global',
                    'none': 'None',

                },
                info: "Set up client-wide call restrictions."
            },
            callerId: {
                label: 'Company Caller ID',
                info: 'Here you can set the default caller ID for the entire company.',
            },
            enableCustomSipHeaderCallerid: {
                label: "Custom Caller ID via API calls",
                info: "Enabling this feature allows the Caller ID to be configured per call in API calls for any future devices created."
            },

            name: {
                label: 'Name'
            },
            slug: {
                label: 'Slug'
            },
            url: {
                label: 'URL',
            },
            isReseller: {
                label: 'Reseller'
            },
            token: {
                label: 'Token'
            },
            enabledUserStatuses: {
                label: 'Settings user statuses',
                info: 'Here you can determine which statuses are available within the company. Calls normally only come in to agents who are available. This behavior can be adjusted with these settings. It can be determined in which statuses calls from the caller group are offered to the user and which groups of users have access to configure this themselves.'
            },
            recordDeleteGroups: {
                label: 'Recording delete rights.',
                info: 'User groups which can manually delete recordings.',
            },
            recordPurgeDays: {
                label: 'Delete recordings after',
                suffix: ' days',
                info: 'Recordings will be automatically deleted after specified number of days.',
            },
            recordCalls: {
                label: 'Record outbound calls',
                info: 'When Call Recording is turned on, by default all outgoing calls will be recorded. These recordings should only be used for internal reference purposes and should be handled with care and in compliance with GDPR regulations.',
            },
            recordOverrideGroups: {
                label: 'Recording settings rights',
                info: 'User groups which can configure own recording setting',
            },
            agentAvailableOverrideGroups: {
                label: 'Queue availability settings rights',
                info: 'User groups which can configure for which statuses they are considered available in call queues.',
            },
            hotdesking: { label: 'Hotdesking' },
            audioLanguage: {
                label: 'audio language',
                info: 'the language in which messages are played like the voicemail menu',
                value: {
                    'en-US': 'English',
                    'nl-NL': 'Dutch',
                }
            },

        },
    },
    calendarAccount: {
        field: {
            baseUrl: { label: 'Caldav URL' },
            type: { label: 'Type', other: 'Other' },
            username: { label: 'Username' },
            password: { label: 'Password' },
            name: { label: 'Calendar' },
            appSpecificPassword: { label: 'App Specific Password' },
        },
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
            bulkActionsButton: {
                label: 'Bulk Actions ({{count}})',
                action: {
                    enable: { label: 'Enable ({{count}})' },
                    disable: { label: 'Disable ({{count}})' },
                },
            },
        },
        create: {
            title: 'New User',
        },
        edit: {
            title: 'Edit User',
            tabs: {
                details: {
                    label: 'Details',
                },
                callerId: {
                    label: 'Caller ID',
                },
                agentStatus: {
                    label: 'Agent Status',
                },
                voicemail: {
                    label: 'Voicemail',
                },
                dialPlan: {
                    label: 'Dial Plan',
                },
                app: {
                    label: 'App',
                    download: 'Download',
                    appStoreButton: 'App Store',
                    playStoreButton: 'Play Store',
                    qrCode: 'QR Code',
                },
            },
            weekdays: {
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
            },
            addCalendarModal: {
                title: 'Add Calendar',
            },
        },
        profile: {
            title: 'Profile',
        },
        field: {
            callerId: {
                label: 'Caller ID',
                perDevice: 'Per Device',
            },
            callAnonymous: {
                label: 'Call Anonymous',
                perDevice: 'Per Device',
            },
            avatar: { label: 'Avatar' },
            fullName: { label: 'Name' },
            callRestrictionOverride: { label: 'Call region', info: 'Override user call region restrictions.' },
            location: { label: 'Location' },
            name: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            enabled: { label: 'Enabled' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            isActive: { label: 'Active User'},
            status: {
                label: 'Status',
                value: {
                    available: 'Available',
                    available_device: 'Available (on device)',
                    available_internal: 'Available internal',
                    follow_up: 'Follow up',
                    training: 'Training',
                    lunch: 'Lunch',
                    bathroom: 'Bathroom',
                    meeting: 'Meeting',
                    away_from_desk: 'Away from desk',
                    do_not_disturb: 'Do not disturb',
                    out_of_office: 'Out of office',
                    on_holiday: 'On holiday',
                    not_available: 'Not available',
                    contact: 'Contact',
                },
            },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    superadmin: 'Super Admin',
                    admin: 'Admin',
                    caller: 'Caller',
                    manager: 'Manager',
                    reseller: 'Reseller',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: 'Language'
            },
            calendars: {
                label: 'Calendars',
                none: 'No calendars configured yet',
            },
            extension: {
                label: 'Extension',
            },
            recordCalls: {
                label: 'Record Calls',
                value: {
                    true: 'Yes',
                    default: 'Default ({{default}})',
                    false: 'No',
                },
            },
            agentAvailableUserStatuses: {
                label: 'Available statuses',
                override: 'Queue availability settings',
                info: 'The selected statuses determine when you are available in call queues.'
            },
            voicemailNotification: {
                label: 'Voicemail Notification',
                value: {
                    user: 'User',
                    override: 'Override',
                    do_not_send: 'Do Not Send',
                },
            },
            voicemailNotificationOverrideEmails: { label: 'Voicemail Notification Email Addresses' },
            hotdesking: { label: 'Hotdesking' },
            hotdeskPin: { label: 'Hotdesk PIN' },
        },
        login: {
            title: '{{client}} Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
        },
        activate: {
            title: 'Activate account',
            intro: 'Welcome to REX! After you have set your password you can immediately get started.',
            activateButton: 'Activate Account',
            saveSuccess: 'Your account has been succesfully activated.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        disable: 'Disable',
        enable: 'Enable',
        view: 'View',
        restore: 'Restore',
        editUser: 'Edit user',
    },
    form: {
        restoreConfirmation: "Are you sure you want to restore this?",
        actions: 'Actions',
        additionLabel: 'Add ',
        repeat: 'Repeat',
        saveButton: 'Save',
        addButton: 'Add',
        cancelButton: 'Cancel',
        confirmButton: 'Confirm',
        backButton: 'Back',
        saveAndNewButton: 'Save & Create New',
        deleteConfirmation: 'Are you sure you want to delete {{name}}?',
        yes: 'Yes',
        either: 'Either',
        no: 'No',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        startDate: 'Start Date',
        endDate: 'End Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        longerThan: 'Longer than',
        shorterThan: 'Shorter than',
        date: 'Date',
        time: 'Time',
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: "We’re very sorry, but the application has crashed.",
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        period: {
            am: 'AM',
            pm: 'PM',
        },
    },
    callButton: {
        call: 'Call',
    },
    settings: {
        title: 'Settings',
        tabs: {
            general: { label: 'General' },
            recording: { label: 'Call Recording' },
            holidays: { label: 'Holidays' },
            customInfo: { label: 'Custom Info' }
        },
        status: {
            label: 'Status',
            enabled: 'Enabled',
            agentAvailable: 'Agent Available',
        },
    },
    callGroup: {
        overview: {
            title: 'Call Groups',
            addButton: 'Add Call Group',
        },
        edit: {
            title: 'Edit Call Group',
            addUserModal: {
                title: 'New Users',
                addButton: 'Add ({{count}})',
            },
            tabs: {
                edit: { label: 'Edit' },
                voicemail: { label: 'Voicemail' },
            },
        },
        create: {
            title: 'New Call Group',
        },
        field: {
            name: { label: 'Name' },
            memberCount: { label: 'Members' },
            extension: { label: 'Extension' },
            users: { label: 'Users' },
            callGroupCallerIds: {
                label: 'Allowed Caller IDs',
                info: 'Here you can set which caller IDs are available to use for the users in the call group.',
            },
            ringStrategy: {
                label: 'Ring Strategy',
                value: {
                    simultaneous: 'Simultaneous',
                    single: 'In Order',
                    weighted_random: 'Random',
                },
            },
            groupTimeout: { label: 'Group Timeout' },
            userTimeout: { label: 'User Timeout' },
            repeats: { label: 'Repeats' },
            prefixCallerId: { label: 'Prefix Caller ID' },
            voicemailNotification: {
                label: 'Voicemail Notification',
                value: {
                    users: 'Users',
                    override: 'Override',
                    do_not_send: 'Do Not Send',
                },
            },
            voicemailNotificationOverrideEmails: { label: 'Voicemail Notification Email Addresses' },
        },
    },
    dashboard: {
        editButton: 'Edit',
        saveButton: 'Save',
        discardButton: 'Discard',
        settingsButton: 'Settings',
        deleteButton: 'Delete',
        default: 'My dashboard',
        reseller: 'Get started',
        new: 'New dashboard',
        addBlockModal: {
            title: 'New Block',
        },
        settingsModal: {
            title: 'Settings',
            submitButton: 'Submit',
            settings: {
                name: { label: 'Name' },
                columns: { label: 'Columns' },
                rows: { label: 'Rows' },
                scope: { label: 'Scope' },
            },
        },
        blockSettingsModal: {
            title: 'Block Settings',
        },
        block: {
            title: {
                label: 'Title',
                value: {
                    devices: 'Devices',
                    active_calls: 'Active Calls',
                    available_employees: '# Agents Available',
                    calls_per_department: 'Call Group Chart',
                    call_types: 'Call Types',
                    calls_missed: 'Calls Missed',
                    calls_received: 'Calls Received',
                    calls_placed: 'Calls Placed',
                    call_stat: 'Call Statistic',
                    average_duration: 'Average Duration',
                    total_duration: 'Total Duration',
                    call_history: 'Call History',
                    voicemails: 'Voicemails',
                    callbacks: 'Callbacks',
                    clock: 'Clock',
                    game: '2048',
                    sudoku: 'Sudoku',
                    tetris: 'Tetris',
                    docs: 'Docs',
                    dial_plan_status: 'Dial Plan Status',
                },
            },
            call_stat: {
                error: 'Could not fetch data.',
                notApplicable: 'N/A',
                filters: {
                    label: 'Filters',
                    info: 'With these settings you can filter the data that is used to calculate the statistic.',
                },
                field: {
                    stat: {
                        label: 'Statistic',
                        value: {
                            count: 'Count',
                            average_duration: 'Average Duration',
                            total_duration: 'Total Duration',
                        },
                    },
                    groupBy: {
                        label: 'Group By',
                        value: {
                            company: 'Company',
                            origin: 'Origin',
                        },
                    },
                },
            },
            docs: {
                field: {
                    path: { label: 'Path' },
                },
            },
            clock: {
                timeUnavailable: 'Time Unavailable',
                field: {
                    format: {
                        label: 'Format',
                        value: {
                            digital_24h: 'Digital 24h',
                            digital_12h: 'Digital 12h',
                            analog: 'Analog',
                        },
                    },
                    timezone: { label: 'Timezone' },
                    seconds: { label: 'Include Seconds' },
                },
            },
            activeCalls: {
                header: 'Active Calls',
            },
            callHistory: {
                noCalls: 'No Calls',
                viewLink: 'View All',
                field: {
                    scope: {
                        label: 'Scope',
                        value: {
                            personal: 'Personal',
                            company: 'Company',
                        },
                    },
                },
            },
            voicemails: {
                noVoicemails: 'No Voicemails',
                viewLink: 'View All',
                field: {
                    box: {
                        label: 'Box',
                        value: {
                            all: 'All',
                            personal: 'Personal',
                        }
                    },
                },
            },
            dial_plan_status: {
                field: {
                    id: { label: 'Dial Plan Status Node' },
                },
            },
        },
        field: {
            scope: {
                label: 'Scope',
                value: {
                    day: 'Today',
                    week: 'This Week',
                    month: 'This Month',
                    quarter: 'This Quarter',
                    year: 'This Year',
                },
            },
        },
    },
    contactList: {
        overview: {
            title: 'Contact Lists',
            addButton: 'Add Contact List',
        },
        create: {
            title: 'New Contact List',
        },
        edit: {
            title: 'Edit Contact List',
        },
        field: {
            name: { label: 'Name' },
            memberCount: { label: 'Members' },
            contactCount: { label: 'Contacts' },
            members: { label: 'Members' },
        },
    },
    contact: {
        overview: {
            title: 'Contacts',
            addButton: 'Add Contact',
            callHistoryButton: 'Call History',
        },
        create: {
            title: 'New Contact',
        },
        edit: {
            title: 'Edit Contact',
            shareButton: 'Share',
            moveButton: 'Move',
            similar: {
                title: 'Similar Contacts',
            },
        },
        field: {
            avatar: { label: 'Avatar' },
            name: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            company: { label: 'Company' },
            contactList: { label: 'Contact List' },
            phoneNumbers: {
                label: 'Phone Numbers',
                none: 'No phone numbers',
            },
        },
        mergeModal: {
            title: 'Similar Contact Found',
            title_plural: 'Similar Contacts Found',
            headers: {
                original: 'Original',
                similar: 'Similar',
                merged: 'Merged',
            },
            newButton: 'New Contact',
            mergeButton: 'Merge Contact',
        },
    },
    contactPhoneNumber: {
        field: {
            description: {
                label: 'Description',
                value: {
                    business: 'Business',
                    mobile: 'Mobile',
                    home: 'Home',
                },
            },
            primary: { label: 'Primary' },
            phoneNumber: { label: 'Phone Number' },
        },
    },
    contactTab: {
        label: {
            users: 'Users',
            call_group: 'Call Groups',
            call_queue: 'Call Queues',
            dial_plan: 'Dial Plans',
            device: 'Devices',
        },
        noResults: 'No contacts found',
        unknownPhoneNumber: 'Unknown phone number',
        internalOnly: 'You do not have an outbound phone number and thus cannot call externally.',
        notAvailable: 'This contact has no phone numbers',
    },
    voicemailTab: {
        label: {
            user: 'Personal',
            call_group: 'Call Groups',
            call_queue: 'Call Queues',
            dial_plan: 'Dial Plans',
            device: 'Devices',
        },
        noResults: 'No contacts found',
        unknownPhoneNumber: 'Unknown phone number',
        internalOnly: 'You do not have an outbound phone number and thus cannot call externally.',
        notAvailable: 'This contact has no phone numbers',
        dateFilter: {
            day: '24 hours',
            week: 'week',
            all: 'Show all',
        }
    },
    call: {
        overview: {
            title: 'Call History',
            unknown: 'Unknown',
            stats: {
                calls: 'Calls',
                duration: 'Duration',
                averageDuration: 'Avg. Duration',
            },
            voicemail: {
                tooltip: {
                    listen: 'Listen to Voicemail',
                    listen_plural: 'Listen to Voicemails',
                    disabled: 'Voicemail is not available anymore'
                },
            },
            recording: {
                tooltip: {
                    listen: 'Listen to Recording',
                    listen_plural: 'Listen to Recordings',
                },
                deleteConfirm: 'Are you sure you want to delete this Recording?'
            },
        },
        field: {
            origin: {
                label: 'Origin',
                value: {
                    inbound: 'Inbound',
                    outbound: 'Outbound',
                    internal: 'Internal',
                    missed: 'Missed',
                    failed: 'Failed',
                },
            },
            callId: ' - call ID: ',
            from: {
                label: 'From',
                name: {
                    label: 'Name'
                },
                number: {
                    label: 'Number'
                }
            },
            to: {
                label: 'To',
                name: {
                    label: 'Name'
                },
                number: {
                    label: 'Number'
                }
            },
            startedAt: { label: 'Start' },
            duration: { label: 'Duration' },
            hasVoicemails: { label: 'Has Voicemail' },
            hasRecordings: { label: 'Has Recording' },
        },
        status: {
            incoming: 'Incoming',
            dialing: 'Dialing...',
            active: '{{duration}}',
            ended: 'Ended',
            not_answered: 'Not answered',
            mic_not_granted: 'Mic not granted',
            ended_unexpectedly: 'Call ended unexpectedly. If you keep having this problem, please contact your systems administrator.',
        },
        tooltip: {
            answer: 'Answer',
            end: 'End',
            hold: 'Hold',
            unhold: 'Unhold',
            mute: 'Mute',
            unmute: 'Unmute',
            transfer: 'Transfer',
        },
        incoming: 'Incoming Call',
    },
    numberModal: {
        type: {
            user: {
                label: 'User',
            },
            call_group: {
                label: 'Call Group',
            },
            call_queue: {
                label: 'Call Queue',
            },
            dial_plan: {
                label: 'Dial Plan',
            },
            device: {
                label: 'Device',
            },
            contact: {
                label: 'Contact',
            },
            number: {
                label: 'Number',
            },
        },
    },
    accountDropdown: {
        status: { label: 'Status' },
        device: { label: 'Active Device' },
        profile: { label: 'Profile' },
        settings: {
            label: 'Settings',
            allowNotifications: {
                enable: 'Enable Notifications',
                disable: 'Disable Notifications',
            },
        },
        callHistory: { label: 'Call History' },
        recordCallsOverride: {
            start: { label: 'Record Calls' },
            stop: { label: 'Do Not Record Calls' },
        },
        logout: { label: 'Log out' },
        error: {
            deviceNotRegistered: "This device isn't registered, so you can't use it as your active device",
            micNotGranted: "REX needs permission to use your microphone otherwise people can't hear you. Please check your browser settings before you start yelling at your computer screen.",
        },
    },
    publicPhoneNumber: {
        overview: {
            title: 'Phone Numbers',
        },
        edit: {
            title: 'Edit {{phoneNumber}}',
            callerId: {
                label: 'Caller ID Availability',
                info: 'The users specified here can use this phone number as caller id. Users are specified either directly or indirectly through call groups.',
            },
        },
        field: {
            phoneNumber: { label: 'Phone Number' },
            extension: { label: 'Destination' },
            type: {
                label: 'Type',
                value: {
                    regular: 'Public',
                    spoofed: 'Spoofed',
                    vamo: 'VaMo',
                },
            },
            callGroupCallerIds: {
                label: 'Call Groups',
                info: 'Users in these call groups can use this phone number as caller id.',
            },
            userCallerIds: {
                label: 'Users',
                info: 'These users can use this phone number as caller id.',
            },
        },
    },
    location: {
        overview: {
            title: 'Locations',
            addButton: 'Add Location',
        },
        edit: {
            title: 'Edit Location'
        },
        create: {
            title: 'New Location'
        },
        field: {
            name: { label: 'Name' },
            phoneNumberPrefix: { label: 'Phone Number Prefix' },
            default: { label: 'Default' },
            defaultLanguage: {
                label: "Default language",
            }
        },
    },
    brandingMessage: 'Powered by Code Yellow',
    pageNotFoundMessage: 'Page not found',
    voicemailBox: {
        field: {
            audioFile: { label: 'Audio File' },
        },
    },
    media: {
        overview: {
            title: 'Media',
            addButton: 'Add Media',
            info: 'Supported audio file types: MP3, WAV.'
        },
        create: {
            title: 'New Media',
        },
        edit: {
            title: 'Edit Media',
        },
        field: {
            name: { label: 'Name' },
            description: { label: 'Description' },
            file: { label: 'File' },
            type: {
                label: 'Type',
                value: {
                    call_queue_on_hold_sound: 'On Hold Music',
                    call_queue_announce_sound: 'Call Queue Announce',
                    dial_plan_recording_message: 'Recording Message',
                    dial_plan_audio_message: 'Audio Message',
                    dial_plan_menu_message: 'Menu Message'
                },
            },
        },
        placeholder: 'Start typing to search or add media',
        protectedError: {
            audioDialPlanNode: 'This media is used in a dial plan audio message.',
            callQueue: 'This media is used in a call queue on hold sound.',
            menuDialPlanNode: 'This media is used in a dial plan menu message.',
            recordDialPlanNode: 'This media is used in a dial plan recording notice.',
        },
    },
    exactIntegration: {
        overview: {
            authButton: 'Activate Exact Integration',
            syncButton: 'Sync Exact Integration',
            linkResult: {
                ok: 'Exact Online link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Exact Online.',
                    invalid_client: 'Invalid client sent to Exact Online.',
                    invalid_grant: 'Invalid grant sent to Exact Online.',
                    invalid_scope: 'Invalid scope sent to Exact Online. ',
                    unauthorized_client: 'Unauthorized client sent to Exact Online.',
                    unsupported_grant_type: 'Unsupported grant type sent to Exact Online.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        account: 'Synced {{count}} account from Exact.',
                        account_plural: 'Synced {{count}} accounts from Exact.',
                        contact: 'Synced {{count}} contact from Exact.',
                        contact_plural: 'Synced {{count}} contacts from Exact.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Exact token expired.',
                },
            },
        },
        field: {
            name: { label: 'Name' },
            division: { label: 'Division ID' },
        },
    },
    syncError: {
        overview: {
            title: 'Sync Errors',
        },
        field: {
            id: { label: 'ID' },
            message: { label: 'Message' },
            integration: { label: 'Integration' },
        }
    },
    integration: {
        overview: {
            title: 'Integrations',
            notActivated: 'Integration is not yet activated.',
        },
        create: {
            title: 'New Integration',
        },
        edit: {
            title: 'Edit Integration',
        },
        field: {
            type: {
                label: 'Type',
                value: {
                    exact: 'Exact',
                    salesforce: 'Salesforce',
                    hubspot: 'Hubspot',
                },
            },
            name: { label: 'Name' },
        },
    },
    salesforceIntegration: {
        field: {
            clientUri: {
                label: 'Client URI',
                info: "By default REX uses login.salesforce.com, but you can fill in another URI if the client has it's own instance. Don't fill in https:// or a trailing /",
            },
        },
        overview: {
            authButton: 'Activate Salesforce integration',
            syncButton: 'Sync Salesforce Integration',
            linkResult: {
                ok: 'Salesforce link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Salesforce.',
                    invalid_client: 'Invalid client sent to Salesforce.',
                    invalid_grant: 'Invalid grant sent to Salesforce.',
                    invalid_scope: 'Invalid scope sent to Salesforce. ',
                    unauthorized_client: 'Unauthorized client sent to Salesforce.',
                    unsupported_grant_type: 'Unsupported grant type sent to Salesforce.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        account: 'Synced {{count}} account from Salesforce.',
                        account_plural: 'Synced {{count}} accounts from Salesforce.',
                        contact: 'Synced {{count}} contact from Salesforce.',
                        contact_plural: 'Synced {{count}} contacts from Salesforce.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Salesforce token expired.',
                },
            },
        },
    },
    hubspotIntegration: {
        overview: {
            authButton: 'Activate Hubspot integration',
            syncButton: 'Sync Hubspot integration',
            linkResult: {
                ok: 'Hubspot link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Hubspot.',
                    invalid_client: 'Invalid client sent to Hubspot.',
                    invalid_grant: 'Invalid grant sent to Hubspot.',
                    invalid_scope: 'Invalid scope sent to Hubspot. ',
                    unauthorized_client: 'Unauthorized client sent to Hubspot.',
                    unsupported_grant_type: 'Unsupported grant type sent to Hubspot.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        company: 'Synced {{count}} comapny from Hubspot.',
                        company_plural: 'Synced {{count}} companies from Hubspot.',
                        contact: 'Synced {{count}} contact from Hubspot.',
                        contact_plural: 'Synced {{count}} contacts from Hubspot.',
                        engagement: 'Synced {{count}} call to Hubspot.',
                        engagement_plural: 'Synced {{count}} calls to Hubspot.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Salesforce token expired.',
                },
            },
        },
    },
    holidaySlot:{
        create: {
            title: "Add holiday slot",
            past: "New holiday slots can't end in the past"
        },
        edit: {
            title: "Edit holiday slot"
        },
        field: {
            name: {
                label: "Name"
            },
            startedAt: {
                label: "Start"
            },
            stoppedAt: {
                label: "Stop"
            }
        }
    },
    errorMessages: {
        fileTypeError: 'Invalid file upload. Supported image file types: JPG, JPEG, PNG.',
    },
};
