import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { Button, MenuRow, NavMenu, NavItem } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { pick } from 'lodash';
import ImgLogo from 'image/logo.svg';
import UserInfo from 'component/UserInfo';
import { AVAILABILITY_COLORS, STATUS_ICONS } from 'component/UserAvatar';
import { Link } from 'react-router-dom';
import { WIPRibbon } from 'component/Ribbon';
import { TYPE_ICONS as DEVICE_TYPE_ICONS } from 'store/Device';


// Apparently you are not allowed to use <a href="#">, but need a button. So create a button which looks like a link
const MenuButton = styled.button`
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    > i.icon:first-child {
        margin-left: -1.58em !important;
        margin-right: 0.4em !important;
    }
    line-height: 1.4285em;
    ${({ active, activeColor }) => active !== undefined ? `
        ${active ? `
            color: ${activeColor};
            font-weight: bold;
        ` : `
            color: rgba(0, 0, 0, 0.5);
            &:hover {
                color: rgba(0, 0, 0, 0.87);
            }
        `}
        transition: color 300ms ease, font-weight 300ms ease;
    ` : ``}
`;

const FakeNavItem = styled.div` // hidden until ready to be released
    color: rgba(0, 0, 0, 0.6);
    font-size: 1em;
    font-weight: bold;
    padding: 0 10px;
    margin: 0 10px;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 50px;
    display: none; 
`;

const MenuLink = styled(Link)`
    display: block;
    width: 100%;
    text-align: left;
    line-height: 1.4285em;
    > i.icon:first-child {
        margin-left: -1.58em !important;
        margin-right: 0.4em !important;
    }
`;

const Logo = styled.img`
    height: 40px;
    margin: 5px;
`;

const ColorIcon = styled(({ color, ...props }) => <Icon {...props} />)`
    color: ${({ color }) => color};
`;

// Copy pasta from re-cy-cle
const TopMenu = styled.section`
    display: flex;
    align-items: stretch;
    flex-direction: column;

    position: relative;
    z-index: 75;

    ${MenuRow}:empty {
        height: 5px;
    }

    .nav-item {
        color: rgba(0, 0, 0, 0.6);
        font-size: 1em;
        font-weight: bold;
    }
`;

const AccountDropdown = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    padding-top: 45px;
    overflow: hidden;
    ${({ open, height }) => open ? `
        ${height !== null && `height: ${height}px;`}
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    ` : `
        height: 50px;
    `}
    pointer-events: none;
    transition: height 300ms ease, box-shadow 300ms ease;
`;

const AccountDropdownContent = styled.div`
    pointer-events: auto;
    padding: 5px 15px 15px calc(55px + 0.25em);
    background-color: #FFF;
    cursor: auto;
    a {
        cursor: pointer;
        color: inherit !important;
    }
`;

const AccountDropdownDivider = styled.hr`
    border: 0;
    height: 1px;
    background-color: #e0e0e0;
    margin: 0.5rem 0;
    &:first-child {
        margin-top: 0;
    }
`;

const AccountDropdownLabel = styled.div`
    font-weight: bold;
`;

function isChildNodeOf(node, parentNode) {
    while (node !== null) {
        if (node === parentNode) {
            return true;
        }
        node = node.parentElement;
    }
    return false;
}

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    @observable accountDropdownOpen = false;
    @observable accountDropdownHeight = null;

    onClick = (e) => {
        if (this.accountDropdownOpen && !isChildNodeOf(e.target, this.accountDropdown)) {
            this.accountDropdownOpen = false;
        } else if (!this.accountDropdownOpen && isChildNodeOf(e.target, this.userInfo)) {
            this.accountDropdownOpen = true;
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.onClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClick);
    }

    handleLogout = () => {
        const { store } = this.props;
        return store.performLogout();
    };

    renderAccountDropdownStatus = (status) => {
        const { store } = this.props;
        return (
            <MenuButton
                key={status}
                active={store.currentEngineUser.baseStatus === status}
                onClick={() => store.currentEngineUser.changeStatus(status)}
                activeColor={AVAILABILITY_COLORS[store.currentEngineUser.statusAvailabilities[status]]}
            >
                <ColorIcon
                    color={AVAILABILITY_COLORS[store.currentEngineUser.statusAvailabilities[status]]}
                    {...STATUS_ICONS[status]}
                />
                {t(`user.field.status.value.${status}`)}
            </MenuButton>
        );
    };

    renderAccountDropdownDevice = (device, i) => {
        const { store } = this.props;

        const active = (
            store.currentEngineUser.activeDevice.isNew
                ? device.type === 'REX'
                : store.currentEngineUser.activeDevice.id === device.id
        );

        let callerId = null;
        if (!device.callerId.isNew) {
            callerId = device.callerId.phoneNumber;
        } else if (!store.currentEngineUser.callerId.isNew) {
            callerId = store.currentEngineUser.callerId.phoneNumber;
        } else if (!store.currentEngineClient.callerId.isNew) {
            callerId = store.currentEngineClient.callerId.phoneNumber;
        }

        return (
            <MenuButton
                key={device.cid}
                active={active}
                onClick={() => store.changeActiveDevice(device)}
                activeColor="#5555ff"
                className={active ? 'device-button active' : 'device-button inactive'}
            >
                <ColorIcon name={DEVICE_TYPE_ICONS[device.type]} />
                {device.name}
                {callerId && ` (${callerId})`}
            </MenuButton>
        );
    };

    @computed get canOverrideRecordCalls() {
        return window.viewStore.currentEngineUser.groups.models.some(
            (group) => window.viewStore.currentEngineClient.recordOverrideGroups.models.some(
                (recordOverrideGroup) => recordOverrideGroup.id === group.id
            )
        );
    }

    @computed get recordCalls() {
        return (
            window.viewStore.currentEngineUser.recordCallsOverride !== null
                ? window.viewStore.currentEngineUser.recordCallsOverride
                : this.defaultRecordCalls
        );
    }

    @computed get defaultRecordCalls() {
        return (
            window.viewStore.currentEngineUser.recordCalls !== null
                ? window.viewStore.currentEngineUser.recordCalls
                : window.viewStore.currentEngineClient.recordCalls)
    }

    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    }

    renderAccountMenu = () => {
        const { store } = this.props;

        return (
            <UserInfo online
                user={store.currentEngineUser}
                style={{
                    position: 'relative',
                    flex: '0 0 auto',
                    cursor: 'pointer',
                }}
                innerRef={(ref) => {
                    if (ref) {
                        this.userInfo = ref;
                    }
                }}
            >
                <Icon name="user secret" style={{
                    color: '#e14938',
                    opacity: store.currentEngineUser.callAnonymousOverride ? 1 : 0,
                    transition: 'opacity 300ms ease',
                }} />
                <Icon name={this.defaultRecordCalls ? 'microphone slash' : 'microphone'} style={{
                    color: '#e14938',
                    opacity: (this.recordCalls !== this.defaultRecordCalls) ? 1 : 0,
                    transition: 'opacity 300ms ease',
                }} />
                <Icon
                    name={`chevron ${this.accountDropdownOpen ? 'up' : 'down'}`}
                    style={{
                        color: 'rgba(0, 0, 0, 0.5)',
                        cursor: 'pointer',
                    }}
                />
                <AccountDropdown
                    innerRef={(ref) => {
                        if (ref) {
                            this.accountDropdown = ref;
                            this.accountDropdownHeight = 45 + ref.children[0].clientHeight;
                        }
                    }}
                    open={this.accountDropdownOpen}
                    height={this.accountDropdownHeight}
                >
                    <AccountDropdownContent>
                        {(store.currentEngineUser.hasPermission('contact.manage_contact')) && this.renderStatusesDevices()}
                        <MenuLink data-test-profile to="/profile/edit" onClick={() => this.accountDropdownOpen = false}>
                            <Icon name="user" />
                            {t('accountDropdown.profile.label')}
                        </MenuLink>
                        {(store.currentEngineUser.hasPermission('contact.manage_contact')) && (
                            <MenuLink data-test-history to={`/profile/call/overview`} onClick={() => this.accountDropdownOpen = false}>
                                <Icon name="history" />
                                {t('accountDropdown.callHistory.label')}
                            </MenuLink>
                        )}
                        <MenuButton data-test-logout onClick={this.handleLogout}>
                            <Icon name="sign out" />
                            {t('accountDropdown.logout.label')}
                        </MenuButton>
                    </AccountDropdownContent>
                </AccountDropdown>
            </UserInfo>
        );
    };

    renderStatusesDevices = () => {
        const { store } = this.props;

        return (
            <React.Fragment>
                <AccountDropdownDivider />
                <AccountDropdownLabel>{t('accountDropdown.status.label')}</AccountDropdownLabel>
                {store.currentEngineClient.enabledUserStatuses.map(this.renderAccountDropdownStatus)}
                {store.currentEngineUser.devices.length >= 1 && (
                    <React.Fragment>
                        <AccountDropdownDivider />
                        <AccountDropdownLabel>{t('accountDropdown.device.label')}</AccountDropdownLabel>
                        {store.currentEngineUser.devices.map(this.renderAccountDropdownDevice)}
                    </React.Fragment>
                )}
                <AccountDropdownDivider />
                <AccountDropdownLabel>{t('accountDropdown.settings.label')}</AccountDropdownLabel>
                <MenuButton
                    active={store.allowNotifications}
                    onClick={() => store.allowNotifications = !store.allowNotifications}
                    activeColor="#e14938"
                >
                    <Icon name={store.allowNotifications ? 'bell slash' : 'bell'} />
                    {t(`accountDropdown.settings.allowNotifications.${store.allowNotifications ? 'disable' : 'enable'}`)}
                </MenuButton>
                <MenuButton
                    active={store.currentEngineUser.callAnonymousOverride}
                    onClick={() => {
                        const anonymous = !store.currentEngineUser.callAnonymousOverride;
                        store.currentEngineUser.callAnonymousOverride = anonymous;
                        return (
                            store.currentEngineUser
                                .save({ mapData: (data) => pick(data, 'id', 'call_anonymous_override') })
                                .catch((e) => {
                                    store.currentEngineUser.callAnonymousOverride = !anonymous;
                                    return Promise.reject(e);
                                })
                        );
                    }}
                    activeColor="#e14938"
                >
                    <Icon name="user secret" />
                    {t('user.field.callAnonymous.label')}
                </MenuButton>
                {this.canOverrideRecordCalls && (
                    <MenuButton
                        active={this.recordCalls !== this.defaultRecordCalls}
                        activeColor="#e14938"
                        onClick={() => {
                            const oldValue = store.currentEngineUser.recordCallsOverride;
                            store.currentEngineUser.recordCallsOverride = (
                                this.recordCalls === this.defaultRecordCalls
                                    ? !this.defaultRecordCalls
                                    : null
                            );
                            return (
                                store.currentEngineUser
                                    .save({ mapData: (data) => pick(data, 'id', 'record_calls_override') })
                                    .catch((e) => {
                                        store.currentEngineUser.recordCallsOverride = oldValue;
                                        return Promise.reject(e);
                                    })
                            );
                        }}
                    >
                        <Icon name={this.defaultRecordCalls ? 'microphone slash' : 'microphone'} />
                        {t(`accountDropdown.recordCallsOverride.${this.defaultRecordCalls ? 'stop' : 'start'}.label`)}
                    </MenuButton>
                )}
                <AccountDropdownDivider />
            </React.Fragment>
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog" />
                </React.Fragment>
            </NavMenu>
        );
    };

    render() {
        const { currentUser, currentEngineUser } = this.props.store;

        let menu;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            menu = (
                <TopMenu>
                    <MenuRow>
                        <Logo src={ImgLogo} alt="REX" />
                    </MenuRow>
                </TopMenu>
            );
        } else {
            menu = currentUser.isSuperuser ? (
                <TopMenu>
                    <MenuRow>
                        <NavMenu>
                            {this.renderAccountMenu()}
                            <NavItem
                                title={t('nav.company.users')}
                                to="/company/user/overview"
                                activePath="/company/user/"
                            />
                        </NavMenu>
                    </MenuRow>
                    <MenuRow />
                </TopMenu>
            ) : (
                <TopMenu>
                    <MenuRow>
                        <NavMenu>
                            {this.renderAccountMenu()}
                            <NavItem
                                title={t('nav.top.dashboard')}
                                to="/dashboard"
                            />
                            {(currentEngineUser.hasPermission('contact.manage_contact')) && (
                                <NavItem
                                    title={t('nav.top.callHistory')}
                                    to="/call/overview"
                                    activePath="/call/"
                                />
                            )}
                            {(currentEngineUser.hasPermission('contact.manage_contact')) && (
                                <NavItem
                                    title={t('nav.top.contacts')}
                                    to="/contact/overview"
                                    activePath="/contact/"
                                />
                            )}
                            <FakeNavItem>
                                {t('nav.top.reports')}
                                <WIPRibbon rightOffset="10px" />
                            </FakeNavItem>
                            {(currentEngineUser.hasPermission('client.create_client')) && (
                                <NavItem
                                    title={t('nav.top.reseller')}
                                    to="/client/overview"
                                    activePath="/client/"
                                />
                            )}
                            {(currentEngineUser.hasPermission('client.edit_user')) && (
                                <NavItem
                                    title={t('nav.top.company')}
                                    to="/company/user/overview"
                                    activePath="/company/"
                                />
                            )}
                            <NavItem
                                title={t('nav.top.docs')}
                                to="/docs"
                            />
                            {(currentEngineUser.hasPermission('exact.authorize_exactintegration')) && (
                                <NavItem
                                    title={t('nav.top.integrations')}
                                    to="/integrations/integration/overview"
                                    activePath="/integrations/"
                                />
                            )}
                        </NavMenu>
                    </MenuRow>
                    <MenuRow />
                </TopMenu>
            );
            menu = (
                <React.Fragment>
                    {menu}
                    <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic size='small'>
                        <Header icon='archive' content={t('user.account.logoutButton')} />
                        <Modal.Content>
                            <p>
                                {t('user.account.logout.confirm')}
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                                <Icon name='remove' /> {t('form.no')}
                            </Button>
                            <Button color='green' inverted onClick={() => {
                                this.props.store.performLogout().then(() => {
                                    this.showLogoutModal = false;
                                });
                            }}>
                                <Icon name='checkmark' /> {t('form.yes')}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </React.Fragment>
            );
        }
        return menu;
    }
}
