import {observable} from 'mobx';
import {PublicPhoneNumber} from 'store/PublicPhoneNumber';
import {EngineModel, EngineStore} from 'store/Base';
import {GroupStore} from 'store/EngineUser';
import {EngineClientCustomInfoRabbitmq} from "./EngineClientCustomInfoRabbitmq";
import { Media } from 'store/Media'

export class EngineClient extends EngineModel {
    static backendResourceName = 'client';
    static CALL_RESTRICTIONS = [
        'internal',
        'nl_no_svc',
        'nl_all',
        'benelux',
        'europe',
        'world_limited',
        'none',
    ];
    static AUDIO_LANGUAGES = [
        'nl-NL',
        'en-US',
    ];


    @observable id = null;
    @observable name = '';
    @observable slug = '';
    @observable enabledUserStatuses = [];
    @observable agentAvailableUserStatuses = ['available'];
    @observable recordCalls = false;
    @observable recordPurgeDays = 14;
    @observable enableCustomSipHeaderCallerid = false;
    @observable hotdesking = false;
    @observable callRestriction = 'europe';
    @observable audioLanguage = 'en-US';

    relations() {
        return {
            onHoldMusic: Media,
            callerId: PublicPhoneNumber,
            recordOverrideGroups: GroupStore,
            recordDeleteGroups: GroupStore,
            customInfoRabbitmq: EngineClientCustomInfoRabbitmq,
            agentAvailableOverrideGroups: GroupStore,
        };
    }
}

export class EngineClientStore extends EngineStore {
    static backendResourceName = 'client';
    Model = EngineClient;
}
