export default {
    nav: {
        top: {
            dashboard: 'Dashboard',
            contacts: 'Contacten',
            callHistory: 'Gesprekshistorie',
            reports: 'Rapporten',
            reseller: 'Klanten',
            company: 'Bedrijf',
            docs: 'Documenten',
            integrations: 'Integraties',
        },
        company: {
            users: 'Gebruikers',
            devices: 'Toestellen',
            publicPhoneNumbers: 'Telefoonnummers',
            dialPlans: 'Belplannen',
            callGroups: 'Belgroepen',
            callQueues: 'Wachtrijen',
            contactLists: 'Contactlijsten',
            locations: 'Locaties',
            settings: 'Instellingen',
            media: 'Media',
            extensions: 'Extensies',
        },
        integrations: {
            integration: 'Integraties',
            errors: 'Synchronisatiefouten'
        },
        search: {
            placeholder: 'Zoek & bel..',
        },
    },
    callQueue: {
        overview: {
            title: 'Wachtrijen',
            addButton: 'Wachtrij toevoegen',
        },
        edit: {
            title: 'Wachtrij bewerken',
        },
        create: {
            title: 'Nieuwe wachtrij',
        },
        field: {
            name: {
                label: 'Naam'
            },
            extension: {
                label: 'Extensie'
            },
            timeout: { label: 'Timeout' },
            timeoutDestination: { label: 'Timeout Bestemming' },
            callQueueCallGroups: { label: 'Bel groepen' },
            callQueueUsers: { label: 'Gebruikers' },
            ringStrategy: {
                label: 'Belstrategie',
                value: {
                    ring_all: 'Bel iedereen',
                    longest_idle_agent: 'Beschikbare gebruiker',
                    round_robin: 'Rouleren',
                    top_down: 'Op volgorde',
                    ring_progressively: 'Ring progressively',
                },
            },
            onHoldSound: { label: 'Wachtmuziek' },
            announceSound: { label: 'Aankondiging' },
            announceInterval: { label: 'Announce Interval' },
            prefixCallerId: { label: 'Prefix Caller ID' },
            maxMembers: { label: 'Max wachtenden' },
            breakouts: {
                label: 'Breakouts',
            },
        },
    },
    callQueueBreakout: {
        field: {
            destination: {
                label: 'Bestemming',
                value: {
                    notFilled: 'Breakout optie',
                },
            },
        },
    },
    device: {
        overview: {
            title: 'Toestellen',
            addButton: 'Toestel toevoegen'
        },
        edit: {
            title: 'Toestel bewerken'
        },
        create: {
            title: 'Nieuw toestel'
        },
        field: {
            callerId: { label: 'Afzender' },
            callAnonymous: { label: 'Bel anoniem' },
            callWaitingEnabled: { label: 'Wisselgesprek' },

            name: {
                label: 'Naam'
            },
            extension: {
                label: 'Extensie'
            },
            extensionNumber: {
                label: 'Extensie'
            },
            user: {
                label: 'Gebruiker'
            },
            isRegistered: {
                label: 'Geregistreerd'
            },
            isAssigned: {
                label: 'Toegekend',
            },
            type: {
                label: 'Type',
                value: {
                    REX: 'REX',
                    'sip phone': 'SIP toestel',
                    'vamo handset': 'VaMo handset',
                },
            },
            config: {
                label: 'SIP Instellingen',
                host: 'Host',
                username: 'Username',
                password: 'Password',
            },
            provisioningModel: { label: 'Provisioning Model' },
            mac: { label: 'MAC address' },
            serial: { label: 'Serial number' },
            line: {
                label: 'Line',
                value: 'Line {{line}}',
            },
            timeout: { label: 'Bel Timeout' },
            hotdesking: { label: 'Hotdesking' },
            vamoVoicemailEnabled: { label: "Voicemail" }
        },
        isRegistered: {
            call: 'Call: {{number}}',
            noCall: 'Het toestel is niet geregistreerd'
        },
        registrations: {
            label: 'Recente registraties',
            timestamp: 'Datum & Tijd',
            status: 'Status',
            sourceIp: 'Bron adres',
            username: 'Gebruikersnaam',
            realm: 'Domein',
            userAgent: 'User Agent',
            expires: 'Verloopt',
        },
    },
    dialPlan: {
        overview: {
            title: 'Belplannen',
            addButton: 'Belplan toevoegen',
        },
        edit: {
            title: 'Belplan bewerken',
            tooltip: {
                cut: 'Knippen',
                copy: 'Kopieren',
                paste: 'Plakken',
                undo: 'Ongedaan maken',
                redo: 'Opnieuw',
                delete: 'Verwijderen',
                expand: 'Uitklappen',
                compress: 'Comprimeren',
                deselect: 'Sluiten',
            },
            nodeRep: `{{type}} node`,
            action: {
                cut: 'Knippen {{node}}',
                paste: 'Plakken {{node}}',
                delete: 'Verwijder {{node}}',
                add: 'Voeg {{node}} toe',
                move: 'Verplaats {{node}}',

                addMenuButton: 'Add menu button',
                deleteMenuButton: 'Remove menu button',
                changeMenuLabel: 'Change menu label',
                changeMenuMessage: 'Wijzig menu bericht',
                changeMenuTimeout: 'Wijzig menu timeout',
                toggleMenuPrefixCallerId: 'Toggle menu prefix caller ID',
                toggleHolidayNode: 'Status node gewijzigd naar vakantie',


                changeTimezone: 'Change timeslots timezone',
                changeTimeslotLabel: 'Change timeslot label',
                addTimeslotDay: 'Add timeslot day',
                deleteTimeslotDay: 'Remove timeslot day',
                changeTimeslotStartTime: 'Change timeslot start time',
                changeTimeslotEndTime: 'Change timeslot end time',
                addTimeslotFilter: 'Add timeslot filter',
                deleteTimeslotFilter: 'Remove timeslot filter',
                addTimeslot: 'Add timeslot',
                deleteTimeslot: 'Remove timeslot',
                changeExceptionStartDay: 'Change exception start day',
                changeExceptionEndDay: 'Change exception end day',
                addExceptionDay: 'Add exception day',
                deleteExceptionDay: 'Remove exception day',
                changeExceptionStartTime: 'Change exception start time',
                changeExceptionEndTime: 'Change exception end time',
                addExceptionFilter: 'Add exception filter',
                deleteExceptionFilter: 'Remove exception filter',
                addException: 'Add exception',
                deleteException: 'Remove exception',

                changeRepeatLevel: 'Change repeat',

                changeAudioMessage: 'Change audio message',

                changeTransferType: 'Change transfer type',
                changeTransferExtension: 'Change transfer extension',
                changeTransferContact: 'Change transfer contact',
                changeTransferContactPhoneNumber: 'Change transfer contact phone number',
                changeTransferPhoneNumber: 'Change transfer phone number',
                toggleTransferKeepCallerId: 'Toggle transfer keep caller id',
                toggleTransferTimeout: 'Toggle transfer timeout',
                changeTransferTimeout: 'Change transfer timeout',
                deleteTransferDestination: 'Delete transfer destination',
                addTransferDestination: 'Add transfer destination',

                changeVoicemailType: 'Change voicemail type',
                changeVoicemailBox: 'Change voicemail box',

                changeRecordMessage: 'Change record message',

                toggleStatusOptionStatus: 'Toggle status option status',

                changePrefixPrefix: 'Change prefix',
                changePrefixApplyTo: 'Change prefix apply to',

                changeStatusLabel: 'Change status label',
                changeStatusOptionLabel: 'Change status option label',
                deleteStatusOption: 'Delete status option',
                addStatusOption: 'Add status option',
                SelectTransferCallerPhoneNumber: 'Verander afzender nummer dial plan tranfer',
            },
            error: {
                hasDetailErrors: 'Node has invalid details',
                emptyNode: 'Node cannot be empty',

                missingAudioMessage: 'Audio message is missing',

                blankTimeslotLabel: 'Timeslot label is empty',

                missingMenuMessage: 'Menu message is missing',
                blankMenuLabel: 'Menu label is empty',

                missingTransferExtension: 'No transfer to selected',
                missingTransferContact: 'No transfer to selected',
                invalidTransferPhoneNumber: 'Invalid phone number',
                tooLowTransferTimeout: 'Timeout must be at least 5s',

                missingVoicemailBox: 'No box selected',

                missingRecordMessage: 'Record message is missing',

                disabledRecordNode: 'Record Calls should be enabled.',

                blankPrefix: 'Prefix is empty',

                blankStatusLabel: 'Status label is empty',
                blankStatusOptionLabel: 'Status option label is empty',
            },
        },
        delete: {
            header: 'Verwijder belplan',
            content: 'Weet je zeker dat je dit belplan wilt verwijderen? Dit zal ook alle bijbehorende belplan status dashboard blokken verwijderen.',
            cancel: 'Annuleren',
            confirm: 'Verwijderen'
        },
        create: {
            title: 'Nieuw belplan',
        },
        field: {
            name: {
                label: 'Naam',
            },
            user: {
                label: 'Gebruiker'
            },
            extension: {
                label: 'Extensie'
            },
            node: {
                type: {
                    audio: {
                        label: 'Audio',
                        field: {
                            message: { label: 'Bericht' },
                        },
                    },
                    user_status: {
                        label: 'Status',
                        field: {
                            options: {
                                label: 'Opties',
                                info: 'Deze instelling bepaalt bij welke statussen de gebruiker beschikbaar blijft in de wachtrij, dus of gesprekken aangeboden worden of niet',
                            },
                        },
                    },
                    dial_plan_status: {
                        label: 'Status',
                        field: {
                            label: { label: 'Label' },
                            holidayNode: {
                                label: 'Vakantie planning',
                                info: "Gebruikt de ingestelde vakantie periodes om te bepalen welke status moet worden gebruikt."
                            },
                            options: {
                                label: 'Opties',
                                addButton: 'Optie toevoegen',
                            },
                        },
                    },
                    timeslots: {
                        label: 'Tijdsloten',
                        field: {
                            timezone: {
                                label: 'Tijdzone',
                            },
                            timeslots: {
                                label: 'Tijdsloten',
                                addButton: 'Tijdslot toevoegen',
                                start: { before: 'tussen' },
                                end: { before: 'en', after: '' },
                            },
                            exceptions: {
                                label: 'Uitzonderingen',
                                empty: 'Nog geen uitzonderingen',
                                addButton: 'Uitzondering toevoegen',
                                start: { before: 'tussen' },
                                end: { before: 'en', after: '' },
                            },
                            days: {
                                shortValue: {
                                    monday: 'ma',
                                    tuesday: 'di',
                                    wednesday: 'wo',
                                    thursday: 'do',
                                    friday: 'vr',
                                    saturday: 'za',
                                    sunday: 'zo',
                                },
                            },
                        },
                    },
                    menu: {
                        label: 'Menu',
                        field: {
                            message: { label: 'Bericht' },
                            timeout: { label: 'Timeout' },
                            options: { label: 'Opties' },
                            prefix_caller_id: { label: 'Afzendernummer prefix' },
                        },
                    },
                    record: {
                        label: 'Opname',
                        field: {
                            message: { label: 'Bericht' },
                            enabled: {
                                label: 'Gespreksopname',
                                info: 'Hierbij bevestig ik dat een audiobericht is ingesteld wat de beller informeert dat het gesprek opgenomen wordt.',
                            },
                        },
                    },
                    repeat: {
                        label: 'Herhaal',
                        field: {
                            levels: {
                                label: 'Herhaal',
                                dialPlan: 'Gehele belplan',
                                menu: '{{count}} Menu Up',
                                menu_plural: '{{count}} Menus Up',
                            },
                        },
                    },
                    transfer: {
                        label: 'Doorschakelen',
                        field: {
                            destination: {
                                label: 'Bestemming',
                                value: {
                                    call_group: 'Belgroep',
                                    call_queue: 'Wachtrij',
                                    user: 'Gebruiker',
                                    dial_plan: 'Belplan',
                                    device: 'Toestel',
                                    contact: 'Contact',
                                    phone_number: 'Telefoonnummer',
                                    ring_all: 'Bel iedereen',
                                },
                                addButton: 'Bestemming toevoegen',
                            },
                            timeout: {
                                label: 'Timeout',
                                disabled: 'Timeout is niet ondersteund voor deze bestemming.',
                            },
                            keep_caller_id: {
                                label: 'Nummerweergave behouden',
                            },
                            caller_phone_number: {
                                label: 'Afzender nummer',
                            },
                        },
                    },
                    voicemail: {
                        label: 'Voicemail',
                        field: {
                            type: {
                                value: {
                                    call_group: 'Belgroep',
                                    user: 'Gebruiker',
                                },
                            },
                            box: { label: 'Box' },
                        },
                    },
                    end: {
                        label: 'Einde',
                    },
                    prefix: {
                        label: 'Prefix',
                        field: {
                            prefix: { label: 'Prefix' },
                            applyTo: {
                                label: 'Toepassen op',
                                value: {
                                    original: 'Origineel',
                                    current: 'Huidig',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    extension: {
        overview: {
            title: 'Extensies',
            moveTooltip: 'Wijzig',
            moveButton: 'Wijzig ({{count}})',
            moveModal: {
                title: 'Wijzig extensie',
                title_plural: 'Wijzig extensies',
                extension: 'Extensie',
                extension_plural: 'Extensies',
                moveButton: 'Wijzig ({{count}})',
            },
            extensionRange: {
                user_dial_plan: 'Gebruikers ({{count}})',
                rex_device: 'REX Toestellen ({{count}})',
                sip_phone_device: 'SIP toestellen ({{count}})',
                call_group: 'Belgroepen ({{count}})',
                call_queue: 'Wachtrijen ({{count}})',
                company_dial_plan: 'Belplannen ({{count}})',
                vamo_handset_device: 'VaMo handsets ({{count}})',
            },
        },
        field: {
            extension: {
                label: 'Extensie',
            },
            type: {
                label: 'Type',
                value: {
                    call_group: 'Belgroep',
                    call_queue: 'Wachtrij',
                    user: 'Gebruiker',
                    dial_plan: 'Belplan',
                    device: 'Toestel',
                },
            },
            name: { label: 'Naam' },
        },
    },
    clientCustomInfoRabbitmq: {
        field: {
            customInfoRabbitmqServer: {
                label: "Rabbit MQ server location",
                info: 'Here you can configure the Rabbit MQ server location to which custom info send to rex is to be sent towards'
            },
            enabled: {
                label: "Active",
                info: "Indication if the rabbit MQ is currently active, can be togged yourself. Will auto disable if the rabbitMQ could not be launched"
            },

            customInfoRabbitmqPort: {
                label: "Rabbit MQ server port",
                info: 'Here you can configure the Rabbit MQ port to which custom info send to rex is to be sent towards'
            },
            customInfoRabbitmqUsername: {
                label: "Rabbit MQ server username",
                info: 'The username to be used when connecting to the company specific rabbit MQ'
            },
            customInfoRabbitmqPassword: {
                label: "Rabbit MQ server password",
                info: 'The Password to be used when connecting to the company specific rabbit MQ. Note this will not be shown again once saved, so an empty field is always to be expected after a refresh'
            },
            customInfoRabbitmqVhost: {
                label: "Rabbit MQ Vhost",
                info: 'The vhost of the rabbitmq queue to be connected to, the rabbitmq default for this is '
            },
            customInfoRabbitmqQueue: { label: "Rabbit MQ Queue", info: 'The queue of the rabbitmq to be connected to' },
            connectionError: {
                label: "Reason connection error"
            },
        },
    },
    client: {
        overview: {
            title: 'Klanten',
            activateButton: 'Activeer klant',
            activateModal: {
                title: 'Activeer klant',
                noCustomers: 'Geen klanten gevonden.',
                activateButton: 'Activeer',
                customerModal: {
                    title: 'Activeer {{name}}',
                    activateButton: 'Activeer & doorgaan',
                    activateAndViewButton: 'Activeer & openen',
                },
            },
        },
        create: {
            title: 'Nieuwe klant'
        },
        edit: {
            title: 'Klant bewerken'
        },
        field: {
            logo: {
                label: 'Logo',
                info: 'Ondersteunde bestandstypes: JPG, JPEG, PNG.'
            },
            onHoldMusic: {
                label: 'Wachtmuziek',
                info: "Selecteer wachtmuziek"
            },
            callRestriction: {
                label: "Regiobeperking",
                value: {
                    'internal': "Intern",
                    'nl_no_svc': 'Regionaal',
                    'nl_all': 'Nationaal',
                    'benelux': 'Benelux',
                    'europe': 'Europees',
                    'world_limited': 'Semi globaal',
                    'none': 'Geen',

                },
                info: "Gespreksbeperkingen instellen."
            },
            callerId: {
                label: 'Bedrijfsafzendernummer',
                info: 'Hier stel je het afzendernummer in voor het bedrijf.',
            },
            enableCustomSipHeaderCallerid: {
                label: "Custom Caller ID via API calls",
                info: "Enabling this feature allows the Caller ID to be configured per call in API calls for any future devices created."
            },

            name: {
                label: 'Naam'
            },
            slug: {
                label: 'Slug'
            },
            url: {
                label: 'URL',
            },
            isReseller: {
                label: 'Reseller'
            },
            token: {
                label: 'Token'
            },
            enabledUserStatuses: {
                label: 'Instellingen gebruikersstatussen',
                info: 'Hier kan worden bepaald welke statussen beschikbaar zijn binnen het bedrijf. Gesprekken komen normaal gesproken alleen binnen bij agents die beschikbaar zijn. Met deze instellingen kan dit gedrag aangepast worden. Er kan bepaald worden bij welke statussen gesprekken uit de belgroep aangeboden worden aan de gebruiker en welke groepen gebruikers toegang hebben tot het zelf instellen hiervan.'
            },
            recordDeleteGroups: {
                label: 'Rechten verwijderen gespreksopnames',
                info: 'Gebruikersgroepen die handmatig gespreksopnames kunnen verwijderen',
            },
            recordPurgeDays: {
                label: 'Opnames verwijderen na',
                suffix: ' dagen',
                info: 'Opnames worden automatisch verwijderd na dit aantal dagen.',
            },
            recordCalls: {
                label: 'Uitgaande gesprekken opnemen',
                info: 'Als gespreksopname is ingeschakeld worden alle uitgaande gesprekken opgenomen. Deze gesprekken zijn alleen voor intern gebruik en moeten met zorg en in overeenstemming met de GDPR behandeld worden.',
            },
            recordOverrideGroups: {
                label: 'Rechten zelf instellen gespreksopname',
                info: 'Gebruikersgroepen die de eigen gespreksopname-instellingen kunnen wijzigen',
            },
            agentAvailableOverrideGroups: {
                label: 'Rechten instellen beschikbaarheid in wachtrij',
                info: 'Gebruikersgroepen die de eigen beschikbaarheid in de wachtrij aan de hand van hun status kunnen instellen',
            },
            hotdesking: { label: 'Hotdesking' },
            audioLanguage: {
                label: 'audio taal',
                info: 'de taal waarin u gesproken meldingen hoort zoals het voicemail menu',
                value: {
                    'en-US': 'Engels',
                    'nl-NL': 'Nederlands',
                }
            },
        },
    },
    calendarAccount: {
        field: {
            baseUrl: { label: 'Caldav URL' },
            type: { label: 'Type', other: 'Other' },
            username: { label: 'Gebruikersnaam' },
            password: { label: 'wachtwoord' },
            name: { label: 'Agenda' },
            appSpecificPassword: { label: 'App Specific Password' },
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Gebruiker toevoegen',
            bulkActionsButton: {
                label: 'Bulk acties ({{count}})',
                action: {
                    enable: { label: 'Inschakelen ({{count}})' },
                    disable: { label: 'Uitschakelen ({{count}})' },
                },
            },
        },
        create: {
            title: 'Nieuwe gebruiker',
        },
        edit: {
            title: 'Gebruiker bewerken',
            tabs: {
                details: {
                    label: 'Details',
                },
                callerId: {
                    label: 'Afzendernummer',
                },
                agentStatus: {
                    label: 'Gebruikersstatus',
                },
                voicemail: {
                    label: 'Voicemail',
                },
                dialPlan: {
                    label: 'Belplan',
                },
                app: {
                    label: 'App',
                    download: 'Download',
                    appStoreButton: 'App Store',
                    playStoreButton: 'Play Store',
                    qrCode: 'QR Code',
                },
            },
            weekdays: {
                monday: 'maandag',
                tuesday: 'dinsdag',
                wednesday: 'woensdag',
                thursday: 'donderdag',
                friday: 'vrijdag',
                saturday: 'zaterdag',
                sunday: 'zondag',
            },
            addCalendarModal: {
                title: 'Kalender toevoegen',
            },
        },
        profile: {
            title: 'Profiel',
        },
        field: {
            callerId: {
                label: 'Afzendernummer',
                perDevice: 'Per toestel',
            },
            callAnonymous: {
                label: 'Bel anoniem',
                perDevice: 'Per toestel',
            },
            avatar: { label: 'Avatar' },
            fullName: { label: 'Naam' },
            callRestrictionOverride: { label: 'Regiobeperking', info: 'Overschrijf bel restricties gebruiker' },
            location: { label: 'Locatie' },
            name: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            enabled: { label: 'Ingeschakeld' },
            email: { label: 'Email' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Aangemaakt op' },
            isActive: { label: 'Gebruiker is actief'},
            status: {
                label: 'Status',
                value: {
                    available: 'Beschikbaar',
                    available_device: 'Beschikbaar (op toestel)',
                    available_internal: 'Beschikbaar intern',
                    follow_up: 'Opvolgen',
                    training: 'Training',
                    lunch: 'Lunch',
                    bathroom: 'Toilet',
                    meeting: 'Vergadering',
                    away_from_desk: 'Niet op mijn werkplek',
                    do_not_disturb: 'Niet storen',
                    out_of_office: 'Niet op kantoor',
                    on_holiday: 'Op vakantie',
                    not_available: 'Niet beschikbaar',
                    contact: 'Contact',
                },
            },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Superuser',
                    superadmin: 'Super Admin',
                    admin: 'Admin',
                    caller: 'Caller',
                    manager: 'Manager',
                    reseller: 'Reseller',
                },
                empty: 'Geen groepen toegekend',
            },
            language: {
                label: 'Taal'
            },
            calendars: {
                label: "Agenda's",
                none: "Geen agenda's ingesteld",
            },
            extension: {
                label: 'Extensie',
            },
            recordCalls: {
                label: 'Gesprekken opnemen',
                value: {
                    true: 'Ja',
                    default: 'Standaard ({{default}})',
                    false: 'Nee',
                },
            },
            agentAvailableUserStatuses: {
                label: 'Beschikbare statussen',
                override: 'Instellen beschikbaarheid wachtrij',
                info: 'Deze instelling bepaalt bij welke statussen de gebruiker beschikbaar blijft in de wachtrij, dus of gesprekken aangeboden worden of niet',
            },
            voicemailNotification: {
                label: 'Voicemailnotificatie',
                value: {
                    user: 'Gebruiker',
                    override: 'Aangepast',
                    do_not_send: 'Niet versturen',
                },
            },
            voicemailNotificationOverrideEmails: { label: 'Email-adressen' },
            hotdesking: { label: 'Hotdesking' },
            hotdeskPin: { label: 'Hotdesk PIN' },
        },
        login: {
            title: '{{client}} login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Gebruikersnaam en wachtwoord komen niet overeen',
                unknown: 'Onbekende fout, status code: {{status}}',
            },
            loginButton: 'Aanmelden',
        },
        passwordForgot: {
            title: 'Wachtwoord vergeten',
            requestButton: 'Email verzenden',
            requestedEmailText:
                'Er is een e-mail naar dit adres verzonden met een herstel code.',
        },
        resetPassword: {
            title: 'Reset wachtwoord',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is opnieuw ingesteld.',
        },
        activate: {
            title: 'Activeer account',
            intro: 'Welkom bij REX! Nadat je  het wachtwoord hebt ingesteld kun je meteen aan de slag.',
            activateButton: 'Activeer account',
            saveSuccess: 'Je account is geactiveerd.',
        },
        changePassword: {
            title: 'Wijzig wachtwoord',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Account details',
            logoutButton: 'Afmelden',
            info: {
                title: 'Account informatie',
            },
            password: {
                title: 'Wijzig wachtwoord',
            },
            logout: {
                title: 'Afmelden',
                confirm: 'Weet je zeker dat je wilt afmelden?'
            },
        },
    },
    myFilter: {
        custom: 'Aangepast',
        action: {
            unsetDefault: 'Standaard uitschakelen',
            setDefault: 'Standaard gebruiken',
            setCurrent: 'Huidige filters opslaan',
            delete: 'Verwijder',
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Bewerken',
        delete: 'Verwijderen',
        disable: 'Uitschakelen',
        enable: 'Inschakelen',
        view: 'Bekijken',
        restore: 'Herstellen',
        editUser: 'Gebruiker bewerken',
    },
    form: {
        restoreConfirmation: "Weet je zeker dat je dit terug wilt zetten?",
        actions: 'Acties',
        additionLabel: 'Toevoegen ',
        repeat: 'Herhaal',
        saveButton: 'Opslaan',
        addButton: 'Toevoegen',
        cancelButton: 'Annuleren',
        confirmButton: 'Bevestigen',
        backButton: 'Terug',
        saveAndNewButton: 'Opslaan en nieuwe aanmaken',
        deleteConfirmation: 'Weet je zeker dat je {{name}} wilt verwijderen?',
        yes: 'Ja',
        either: 'Beide',
        no: 'Nee',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 van $2 geselecteerd',
            noneSelectedText: 'Niet geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Opgeslagen',
            deleteSuccess: 'Verwijderd',
            saveError: 'Fout bij opslaan: ({{status}})',
            saveValError: 'Niet alle velden zijn juist ingevuld',
            saveAuthError: 'Onjuiste toegang',
            newAppVersion:
                'Er is een nieuwe versie beschikbaar. Herlaad de pagina.',
        },
        startDate: 'Startdatum',
        endDate: 'Einddatum',
        startTime: 'Starttijd',
        endTime: 'Eindtijd',
        longerThan: 'Langer dan',
        shorterThan: 'Korter dan',
        date: 'Datum',
        time: 'Tijd',
    },
    crash: {
        heading: 'Fout opgetreden',
        subHeading1: "Onze excuses, er is een fout opgetreden in de applicatie.",
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'ma',
            tuesday: 'di',
            wednesday: 'wo',
            thursday: 'do',
            friday: 'vr',
            saturday: 'za',
            sunday: 'zo',
        },
        month: {
            january: 'januari',
            february: 'februari',
            march: 'maart',
            april: 'april',
            may: 'mei',
            june: 'juni',
            july: 'juli',
            august: 'augustus',
            september: 'september',
            october: 'oktober',
            november: 'november',
            december: 'december',
        },
        period: {
            am: 'AM',
            pm: 'PM',
        },
    },
    callButton: {
        call: 'Bellen',
    },
    settings: {
        title: 'Instellingen',
        tabs: {
            general: { label: 'Algemeen' },
            recording: { label: 'Gespreksopname' },
            holidays: { label: 'Vakantie' },
            customInfo: { label: 'Aangepaste informatie' }
        },
        status: {
            label: 'Status',
            enabled: 'Ingeschakeld',
            agentAvailable: 'Wachtrijgebruiker beschikbaar',
        },
    },
    callGroup: {
        overview: {
            title: 'Bel groepen',
            addButton: 'Belgroep toevoegen',
        },
        edit: {
            title: 'Belgroep bewerken',
            addUserModal: {
                title: 'Nieuwe gebruikers',
                addButton: 'Toevoegen ({{count}})',
            },
            tabs: {
                edit: { label: 'Bewerken' },
                voicemail: { label: 'Voicemail' },
            },
        },
        create: {
            title: 'Nieuwe belgroep',
        },
        field: {
            name: { label: 'Naam' },
            memberCount: { label: 'Deelnemers' },
            extension: { label: 'Extensie' },
            users: { label: 'Gebruikers' },
            callGroupCallerIds: {
                label: 'Toegestane afzenders',
                info: 'Je kunt hier instellen welke afzendernummers beschikbaar zijn voor leden van de groep.',
            },
            ringStrategy: {
                label: 'Bel strategie',
                value: {
                    simultaneous: 'Tegelijk',
                    single: 'In volgorde',
                    weighted_random: 'Willekeurig',
                },
            },
            groupTimeout: { label: 'Groep timeout' },
            userTimeout: { label: 'Gebruiker timeout' },
            repeats: { label: 'Herhalingen' },
            prefixCallerId: { label: 'Afzendernummer prefix' },
            voicemailNotification: {
                label: 'Voicemail notificatie',
                value: {
                    users: 'Gebruikers',
                    override: 'Aangepast',
                    do_not_send: 'Niet versturen',
                },
            },
            voicemailNotificationOverrideEmails: { label: 'Voicemail Notificatie Email Adressen' },
        },
    },
    dashboard: {
        editButton: 'Bewerken',
        saveButton: 'Opslaan',
        discardButton: 'Annuleren',
        settingsButton: 'Instellingen',
        deleteButton: 'Verwijderen',
        default: 'Mijn dashboard',
        reseller: 'Get started',
        new: 'Nieuw dashboard',
        addBlockModal: {
            title: 'Nieuw blok',
        },
        settingsModal: {
            title: 'Instellingen',
            submitButton: 'Verzenden',
            settings: {
                name: { label: 'Naam' },
                columns: { label: 'Kolommen' },
                rows: { label: 'Rijen' },
                scope: { label: 'Bereik' },
            },
        },
        blockSettingsModal: {
            title: 'Blok instellingen',
        },
        block: {
            title: {
                label: 'Titel',
                value: {
                    devices: 'Toestellen',
                    active_calls: 'Actieve gesprekken',
                    available_employees: '# Agents beschikbaar',
                    calls_per_department: 'Belgroepen',
                    call_types: 'Gesprekstypen',
                    calls_missed: 'Gesprekken gemist',
                    calls_received: 'Gesprekken ontvangen',
                    calls_placed: 'Gesprekken gestart',
                    call_stat: 'Gespreksstatistieken',
                    average_duration: 'Gemiddelde duur',
                    total_duration: 'Totale duur',
                    call_history: 'Gespreks geschiedenis',
                    voicemails: 'Voicemails',
                    callbacks: 'Terugbel verzoeken',
                    clock: 'Klok',
                    game: '2048',
                    sudoku: 'Sudoku',
                    tetris: 'Tetris',
                    docs: 'Documenten',
                    dial_plan_status: 'Belplan status',
                },
            },
            call_stat: {
                error: 'Kan geen gegevens ophalen.',
                notApplicable: 'n.v.t',
                filters: {
                    label: 'Filters',
                    info: 'De filter instellingen kunnen worden gebruikt om statistieken te berekenen.',
                },
                field: {
                    stat: {
                        label: 'Statistieken',
                        value: {
                            count: 'Aantal',
                            average_duration: 'Gemiddelde duur',
                            total_duration: 'Totale duur',
                        },
                    },
                    groupBy: {
                        label: 'Groeperen',
                        value: {
                            company: 'Bedrijf',
                            origin: 'Afzender',
                        },
                    },
                },
            },
            docs: {
                field: {
                    path: { label: 'Pad' },
                },
            },
            clock: {
                timeUnavailable: 'Tijd niet beschikbaar',
                field: {
                    format: {
                        label: 'Formaat',
                        value: {
                            digital_24h: 'Digitaal 24u',
                            digital_12h: 'Digitaal 12u',
                            analog: 'Analoog',
                        },
                    },
                    timezone: { label: 'Tijdzone' },
                    seconds: { label: 'Toon seconden' },
                },
            },
            activeCalls: {
                header: 'Actiev gesprekken',
            },
            callHistory: {
                noCalls: 'Geen gesprekken',
                viewLink: 'Toon alle',
                field: {
                    scope: {
                        label: 'Scope',
                        value: {
                            personal: 'Persoonlijk',
                            company: 'Bedrijf',
                        },
                    },
                },
            },
            voicemails: {
                noVoicemails: 'Geen voicemails',
                viewLink: 'Toon alle',
                field: {
                    box: {
                        label: 'Box',
                        value: {
                            all: 'Alle',
                            personal: 'Persoonlijk',
                        }
                    },
                },
            },
            dial_plan_status: {
                field: {
                    id: { label: 'Belplan status node' },
                },
            },
        },
        field: {
            scope: {
                label: 'Bereik',
                value: {
                    day: 'Vandaag',
                    week: 'Deze week',
                    month: 'Deze maand',
                    quarter: 'Dit kwartaal',
                    year: 'Dit jaar',
                },
            },
        },
    },
    contactList: {
        overview: {
            title: 'Contactlijsten',
            addButton: 'Contactlijst toevoegen',
        },
        create: {
            title: 'Nieuwe contactlijst',
        },
        edit: {
            title: 'Contactlijst bewerken',
        },
        field: {
            name: { label: 'Naam' },
            memberCount: { label: 'Leden' },
            contactCount: { label: 'Contacten' },
            members: { label: 'Leden' },
        },
    },
    contact: {
        overview: {
            title: 'Contacten',
            addButton: 'Contact toevoegen',
            callHistoryButton: 'Gesprekshistorie',
        },
        create: {
            title: 'Nieuw contact',
        },
        edit: {
            title: 'Contact bewerken',
            shareButton: 'Delen',
            moveButton: 'Verplaatsen',
            similar: {
                title: 'Soortgelijke contacten',
            },
        },
        field: {
            avatar: { label: 'Avatar' },
            name: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            company: { label: 'Bedrijf' },
            contactList: { label: 'Contactlijst' },
            phoneNumbers: {
                label: 'Telefoonnummers',
                none: 'Geen nummers',
            },
        },
        mergeModal: {
            title: 'Gelijk contact gevonden',
            title_plural: 'Gelijk contacten gevonden',
            headers: {
                original: 'Origineel',
                similar: 'Gelijke',
                merged: 'Samengevoegd',
            },
            newButton: 'Nieuw contact',
            mergeButton: 'Contact samenvoegen',
        },
    },
    contactPhoneNumber: {
        field: {
            description: {
                label: 'Label',
                value: {
                    business: 'Zakelijk',
                    mobile: 'Mobiel',
                    home: 'Thuis',
                },
            },
            primary: { label: 'Primair' },
            phoneNumber: { label: 'Telefoonnummer' },
        },
    },
    contactTab: {
        label: {
            users: 'Gebruikers',
            call_group: 'Belgroepen',
            call_queue: 'Wachtrijen',
            dial_plan: 'Belplannen',
            device: 'Toestellen',
        },
        noResults: 'Geen contacten gevonden',
        unknownPhoneNumber: 'Onbekend nummer',
        internalOnly: 'Er is geen extern nummer ingesteld waardoor u niet extern kunt bellen.',
        notAvailable: 'Dit contact heeft geen telefoonnummers',
    },
    voicemailTab: {
        label: {
            user: 'Persoonlijke',
            call_group: 'Belgroepen',
            call_queue: 'Wachtrijen',
            dial_plan: 'Belplannen',
            device: 'Toestellen',
        },
        noResults: 'Geen contacten gevonden',
        unknownPhoneNumber: 'Onbekend nummer',
        internalOnly: 'Er is geen extern nummer ingesteld waardoor u niet extern kunt bellen.',
        notAvailable: 'Dit contact heeft geen telefoonnummers',
        dateFilter: {
            day: '24 uur',
            week: 'week',
            all: 'Alles tonen',
        }
    },
    call: {
        overview: {
            title: 'Gesprekshistorie',
            unknown: 'Onbekend',
            stats: {
                calls: 'Gesprekken',
                duration: 'Duur',
                averageDuration: 'Gem. duur',
            },
            voicemail: {
                tooltip: {
                    listen: 'Luisteren naar voicemail',
                    listen_plural: 'Luisteren naar voicemails',
                    disabled: 'Voicemail is niet meer beschikbaar'
                },
            },
            recording: {
                tooltip: {
                    listen: 'Luisteren naar opname',
                    listen_plural: 'Luisteren naar opnames',
                },
                deleteConfirm: 'Deze opname verwijderen?'
            },
        },
        field: {
            origin: {
                label: 'Afzender',
                value: {
                    inbound: 'Inkomend',
                    outbound: 'Uitgaand',
                    internal: 'Intern',
                    missed: 'Gemist',
                    failed: 'Mislukt',
                },
            },
            callId: ' - call ID: ',
            from: {
                label: 'Vanaf',
                name: {
                    label: 'Naam'
                },
                number: {
                    label: 'Nummer'
                }
            },
            to: {
                label: 'Naar',
                name: {
                    label: 'Naam'
                },
                number: {
                    label: 'Nummer'
                }
            },
            startedAt: { label: 'Start' },
            duration: { label: 'Duur' },
            hasVoicemails: { label: 'Met Voicemail' },
            hasRecordings: { label: 'Met opname' },
        },
        status: {
            incoming: 'Inkomend',
            dialing: 'Bellen...',
            active: '{{duration}}',
            ended: 'Beeindigd',
            not_answered: 'Niet beantwoord',
            mic_not_granted: 'Geen toegang tot microfoon',
            ended_unexpectedly: 'Het gesprek is onverwacht beeindigd. Als dit vaker gebeurt, neem dan contact op met uw beheerder.',
        },
        tooltip: {
            answer: 'Beantwoorden',
            end: 'Einde',
            hold: 'Hold',
            unhold: 'Unhold',
            mute: 'Mute',
            unmute: 'Unmute',
            transfer: 'Doorschakelen',
        },
        incoming: 'Inkomend gesprek',
    },
    numberModal: {
        type: {
            user: {
                label: 'Gebruiker',
            },
            call_group: {
                label: 'Belgroep',
            },
            call_queue: {
                label: 'Wachtrij',
            },
            dial_plan: {
                label: 'Belplan',
            },
            device: {
                label: 'Toestel',
            },
            contact: {
                label: 'Contact',
            },
            number: {
                label: 'Nummer',
            },
        },
    },
    accountDropdown: {
        status: { label: 'Status' },
        device: { label: 'Actief toestel' },
        profile: { label: 'Profiel' },
        settings: {
            label: 'Instellingen',
            allowNotifications: {
                enable: 'Notificaties inschakelen',
                disable: 'Notificaties uitschakelen',
            },
        },
        callHistory: { label: 'Gesprekshistorie' },
        recordCallsOverride: {
            start: { label: 'Gesprekken opnemen' },
            stop: { label: 'Geen gesprekken opnemen' },
        },
        logout: { label: 'Log out' },
        error: {
            deviceNotRegistered: "Dit toestel is niet geregistreerd en kan niet als actief toestel worden gebruikt",
            micNotGranted: "REX heeft toegang nodig tot je microfoon. Controleer de toegangsinstellingen van je browser.",
        },
    },
    publicPhoneNumber: {
        overview: {
            title: 'Telefoonnummers',
        },
        edit: {
            title: '{{phoneNumber}} bewerken',
            callerId: {
                label: 'Beschikbaarheid afzendernummer',
                info: 'De geselecteerde gebruikers kunnen het afzendernummer gebruiken. Dit kan ok indirect door het gebruik van groepen.',
            },
        },
        field: {
            phoneNumber: { label: 'Telefoonnummer' },
            extension: { label: 'Bestemming' },
            type: {
                label: 'Type',
                value: {
                    regular: 'Publiek',
                    spoofed: 'Spoofed',
                    vamo: 'VaMo',
                },
            },
            callGroupCallerIds: {
                label: 'Belgroepen',
                info: 'Gebruikers in deze groepen kunnen het afzendernummer instellen.',
            },
            userCallerIds: {
                label: 'Gebruikers',
                info: 'Deze gebruiker skunnen het afzendernummer instellen.',
            },
        },
    },
    location: {
        overview: {
            title: 'Locaties',
            addButton: 'Locatie toevoegen',
        },
        edit: {
            title: 'Locatie bewerken'
        },
        create: {
            title: 'Nieuwe locatie'
        },
        field: {
            name: { label: 'Naam' },
            phoneNumberPrefix: { label: 'Telefoonnummer prefix' },
            default: { label: 'Standaard' },
            defaultLanguage: { 'label': "Standaardtaal" }
        },
    },
    brandingMessage: 'Powered by Code Yellow',
    pageNotFoundMessage: 'Pagina niet gevonden',
    voicemailBox: {
        field: {
            audioFile: { label: 'Audiobestand' },
        },
    },
    media: {
        overview: {
            title: 'Media',
            addButton: 'Media toevoegen',
            info: 'Ondersteunde bestandstypes: MP3, WAV.'
        },
        create: {
            title: 'Nieuwe media',
        },
        edit: {
            title: 'Media bewerken',
        },
        field: {
            name: { label: 'Naam' },
            description: { label: 'Omschrijving' },
            file: { label: 'Bestand' },
            type: {
                label: 'Type',
                value: {
                    call_queue_on_hold_sound: 'Wachtmuziek',
                    call_queue_announce_sound: 'Wachtrij-aankondiging',
                    dial_plan_recording_message: 'Gesprek opnemen',
                    dial_plan_audio_message: 'Audiobericht',
                    dial_plan_menu_message: 'Keuzemenu',
                },
            },
        },
        placeholder: 'Start met typen om te zoeken of een nieuw mediabestand aan te maken',
        protectedError: {
            audioDialPlanNode: 'Dit mediabestand wordt gebruikt in een belplan audiobericht.',
            callQueue: 'Dit mediabestand wordt gebruikt als wachtmuziek.',
            menuDialPlanNode: 'Dit mediabestand wordt gebruikt in een belplan menu bericht.',
            recordDialPlanNode: 'Dit mediabestand wordt gebruikt als notificatie om gesprekken op te nemen.',
        },
    },
    exactIntegration: {
        overview: {
            authButton: 'Activate Exact Integration',
            syncButton: 'Sync Exact Integration',
            linkResult: {
                ok: 'Exact Online link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Exact Online.',
                    invalid_client: 'Invalid client sent to Exact Online.',
                    invalid_grant: 'Invalid grant sent to Exact Online.',
                    invalid_scope: 'Invalid scope sent to Exact Online. ',
                    unauthorized_client: 'Unauthorized client sent to Exact Online.',
                    unsupported_grant_type: 'Unsupported grant type sent to Exact Online.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        account: 'Synced {{count}} account from Exact.',
                        account_plural: 'Synced {{count}} accounts from Exact.',
                        contact: 'Synced {{count}} contact from Exact.',
                        contact_plural: 'Synced {{count}} contacts from Exact.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Exact token expired.',
                },
            },
        },
        field: {
            name: { label: 'Name' },
            division: { label: 'Division ID' },
        },
    },
    syncError: {
        overview: {
            title: 'Sync Errors',
        },
        field: {
            id: { label: 'ID' },
            message: { label: 'Message' },
            integration: { label: 'Integration' },
        }
    },
    integration: {
        overview: {
            title: 'Integrations',
            notActivated: 'Integration is not yet activated.',
        },
        create: {
            title: 'New Integration',
        },
        edit: {
            title: 'Edit Integration',
        },
        field: {
            type: {
                label: 'Type',
                value: {
                    exact: 'Exact',
                    salesforce: 'Salesforce',
                    hubspot: 'Hubspot',
                },
            },
            name: { label: 'Name' },
        },
    },
    salesforceIntegration: {
        field: {
            clientUri: {
                label: 'Client URI',
                info: "By default REX uses login.salesforce.com, but you can fill in another URI if the client has it's own instance. Don't fill in https:// or a trailing /",
            },
        },
        overview: {
            authButton: 'Activate Salesforce integration',
            syncButton: 'Sync Salesforce Integration',
            linkResult: {
                ok: 'Salesforce link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Salesforce.',
                    invalid_client: 'Invalid client sent to Salesforce.',
                    invalid_grant: 'Invalid grant sent to Salesforce.',
                    invalid_scope: 'Invalid scope sent to Salesforce. ',
                    unauthorized_client: 'Unauthorized client sent to Salesforce.',
                    unsupported_grant_type: 'Unsupported grant type sent to Salesforce.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        account: 'Synced {{count}} account from Salesforce.',
                        account_plural: 'Synced {{count}} accounts from Salesforce.',
                        contact: 'Synced {{count}} contact from Salesforce.',
                        contact_plural: 'Synced {{count}} contacts from Salesforce.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Salesforce token expired.',
                },
            },
        },
    },
    hubspotIntegration: {
        overview: {
            authButton: 'Activate Hubspot integration',
            syncButton: 'Sync Hubspot integration',
            linkResult: {
                ok: 'Hubspot link successful.',
                error: {
                    invalid_request: 'Invalid request sent to Hubspot.',
                    invalid_client: 'Invalid client sent to Hubspot.',
                    invalid_grant: 'Invalid grant sent to Hubspot.',
                    invalid_scope: 'Invalid scope sent to Hubspot. ',
                    unauthorized_client: 'Unauthorized client sent to Hubspot.',
                    unsupported_grant_type: 'Unsupported grant type sent to Hubspot.',
                },
            },
            syncResult: {
                ok: {
                    nothing: 'Nothing to sync.',
                    created: {
                        company: 'Synced {{count}} comapny from Hubspot.',
                        company_plural: 'Synced {{count}} companies from Hubspot.',
                        contact: 'Synced {{count}} contact from Hubspot.',
                        contact_plural: 'Synced {{count}} contacts from Hubspot.',
                        engagement: 'Synced {{count}} call to Hubspot.',
                        engagement_plural: 'Synced {{count}} calls to Hubspot.',
                    },
                    errors: '{{count}} error occurred during sync.',
                    errors_plural: '{{count}} errors occurred during sync.',
                },
                error: {
                    token_expired: 'Salesforce token expired.',
                },
            },
        },
    },
    holidaySlot:{
        create: {
            title: "Voeg vakantie slot toe",
            past: "Nieuwe vakantie sloten mogen niet eindigen in het verleden"
        },
        edit: {
            title: "Wijzig vakantie slot"
        },
        field: {
            name: {
                label: "Naam"
            },
            startedAt: {
                label: "Start"
            },
            stoppedAt: {
                label: "Stop"
            }
        }
    },
    errorMessages: {
        fileTypeError: 'Ongeldige upload van bestanden. Ondersteunde bestandstypes: JPG, JPEG, PNG.',
    },
};
