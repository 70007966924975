import React from 'react';
import { observer } from 'mobx-react';
import BaseLogin from 'spider/semantic-ui/Login';
import TextLogo from 'image/logo_text.svg';
import styled from 'styled-components';
import { Body, ContentContainer } from 're-cy-cle';
import { Sidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview';
import TrippyBackground from 'component/TrippyBackground';
import { BrandingContainer } from 'spider/semantic-ui/SidebarScreen';
import { Link } from 'react-router-dom';

const DarkSidebar = styled(Sidebar)`
    background-color: #2d303b;

    &, label, h2 {
        color: #fff !important;
    }

    h2 {
        font-size: 1.3rem;
    }

    input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${BrandingContainer} {
        color: rgba(255, 255, 255, 0.25);
        &:hover {
            color: #fff;
            filter: unset;
        }
    }
`;

const BlockLink = styled(Link)`
    display: block;
    margin: 1.5em auto;
`;

const HeaderImg = styled.img`
    height: 6em;
    position: relative;
    left: -1em;
`;

export function sidebarDarkTheme(Screen) {
    return observer(class extends Screen {
        renderHeader() {
            return (
                <React.Fragment>
                    <BlockLink to="/">
                        <HeaderImg src={TextLogo} />
                    </BlockLink>
                    {super.renderHeader()}
                </React.Fragment>
            );
        }

        render() {
            return (
                <Body>
                    <ContentContainer>
                        <DarkSidebar>
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {this.renderBranding()}
                        </DarkSidebar>
                        <TrippyBackground>
                        </TrippyBackground>
                    </ContentContainer>
                </Body>
            );
        }
    });
}

@sidebarDarkTheme
export default class Login extends BaseLogin {
    renderHeader() {
        return null;
    }
}
