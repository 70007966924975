import { subscribe } from 'store/Base';

/**
 * Adds a `this.subscribe` function so that websockets are automatically
 * unsubscribed when the Component unmounts.
 */
export default function(SomeComponent) {
    return class extends SomeComponent {
        /**
         * Contains all websocket subscriptions.
         */
        subscriptions = [];

        subscribe = (...args) => {
            const supscription = subscribe(...args);

            this.subscriptions.push(supscription);

            return supscription;
        }

        unsubscribe = () => {
            this.subscriptions.forEach(s => s.unsubscribe());
            this.subscriptions = [];
        }

        componentWillUnmount() {
            super.componentWillUnmount();

            this.unsubscribe();
        }
    }
}
