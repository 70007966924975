import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore, { ClosableSocket } from './spider/store/View';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from './styles.js';
import { t } from './i18n';
// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { Router } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import configureSentry, { setUserSentry } from './sentry';
import { PUBLIC_URL, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification } from 'helpers/notification';
import { configurePermission } from 'spider/component/Permission';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import { Modal } from 'semantic-ui-react';
import RightDivider from 'spider/component/RightDivider';
import 'moment-duration-format';
import { api, engineApi, subscribe } from 'store/Base';
import { User } from 'store/User';
import { Client } from 'store/Client';
import { EngineUser } from 'store/EngineUser';
import { EngineClient } from 'store/EngineClient';
import { configureTranslation } from 'daycy';
import { generateUrl } from 'spine-high-templar';
import phone from 'phone';
import { showNotification } from 'helpers/notification';
import { updateModel } from 'container/Tabs/Contact';
import { createBrowserHistory } from 'history';

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/react-image-crop.css';
import 'style/semantic-ui.css';
import 'react-image-lightbox/style.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';
import '@material-design-icons/font';
import 'style/material-design-icons/icons.css';

window.t = t;

navigator.getUserMedia = (
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
);

configureTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

class SpecialViewStore extends ViewStore {
    @observable engineApi = null;
    @observable netlocSuffix = '';
    @observable currentClient = new Client();
    @observable currentEngineUser = new EngineUser({}, {
        relations: [
            'location',
            'contact',
            'devices.callerId',
            'activeDevice.callerId',
            'callerId',
            'groups.permissions'
        ],
    });
    @observable currentEngineClient = new EngineClient({}, {
        relations: [
            'callerId',
            'recordOverrideGroups',
            'recordDeleteGroups',
            'agentAvailableOverrideGroups',
        ],
    });
    @observable extensionRanges = {};

    constructor({ api, engineApi, user, socketUrl = null, fetchBootstrap = true }) {
        super({ api, user, socketUrl, fetchBootstrap });
        this.engineApi = engineApi;
        this.handleEngineBootstrap = this.handleEngineBootstrap.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);

        this.engineApi.onRequestError = this.handleRequestError;
    }

    @observable _allowNotifications = localStorage.getItem('allowNotifications') === 'true';

    get allowNotifications() {
        return this._allowNotifications;
    }

    set allowNotifications(value) {
        const callback = () => {
            if (value && Notification.permission === 'granted') {
                this._allowNotifications = true;
                localStorage.setItem('allowNotifications', 'true');
            } else {
                this._allowNotifications = false;
                localStorage.removeItem('allowNotifications');
            }
        }

        if (value) {
            try {
                Notification.requestPermission().then(callback)
            } catch {
                Notification.requestPermission(callback)
            }
        } else {
            callback()
        }
    }

    @action handleBootstrap(res) {
        configOverride(res);
        this.netlocSuffix = res.netloc_suffix;
        this.currentClient = new Client(res.client);
        this.engineApi.baseUrl = res.config_override['ENGINE_API_BASE_URL'];
        if (res.engine_session_token === null) {
            delete this.engineApi.defaultHeaders['X-Session-Token'];
            delete this.api.defaultHeaders['X-Session-Token'];
            this.engineApi.socketUrl = `${PUBLIC_URL || ''}/ws/`;
        } else {
            this.engineApi.defaultHeaders['X-Session-Token'] = res.engine_session_token;
            this.api.defaultHeaders['X-Session-Token'] = res.engine_session_token;
            this.engineApi.socketUrl = `${PUBLIC_URL || ''}/ws/?session_token=${encodeURIComponent(res.engine_session_token)}`;
        }
        this.fetchEngineBootstrap();
        return super.handleBootstrap(res);
    }

    fetchEngineBootstrap() {
        const { with: user_with } = this.currentEngineUser.api.buildFetchModelParams(this.currentEngineUser);
        const { with: client_with } = this.currentEngineClient.api.buildFetchModelParams(this.currentEngineClient);
        return (
            this.engineApi.get('/bootstrap/', { user_with, client_with })
                .then(this.handleEngineBootstrap)
        );
    }

    async changeActiveDevice(device) {
        if (this.currentEngineUser.isNew) {
            return;
        }

        if (!device.isRegistered) {
            showNotification({
                message: t(`accountDropdown.error.deviceNotRegistered`),
                dismissAfter: 5000,
                type: 'error',
            });
            return;
        }

        if (device.type === 'REX') {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });
            } catch (err) {
                showNotification({
                    message: t(`accountDropdown.error.micNotGranted`),
                    dismissAfter: 5000,
                    type: 'error',
                });
                return;
            }
        }

        await this.currentEngineUser.save({ onlyChanges: true, data: { active_device: device.id } });
        this.currentEngineUser.setInput('activeDevice', device);
    }

    @action handleEngineBootstrap(res) {
        if (this.contactChangeSubscription) {
            this.contactChangeSubscription.unsubscribe();
        }

        if (res.user) {
            this.setupSocket(this.engineApi);
            this.currentEngineUser.fromBackend({
                data: res.user.data,
                repos: res.user.with,
                relMapping: res.user.with_mapping,
            });

            phone.config()
            phone.user = this.currentEngineUser;

            this.contactChangeSubscription = subscribe(
                {
                    type: 'contact_change',
                    client: res.client.data.id,
                    contact: res.user.data.contact,
                },
                this.handleContactChange,
            );
        } else {
            this.destroySocket(this.engineApi);
            this.currentEngineUser.clear();
        }

        if (res.client) {
            this.currentEngineClient.fromBackend({
                data: res.client.data,
                repos: res.client.with,
                relMapping: res.client.with_mapping,
            });
        } else {
            this.currentEngineClient.clear();
        }

        this.extensionRanges = res.extension_ranges || {};

        // Set the language to the default location language if no specific app user language is set
        if (this.currentUser.language === '') {
            i18next.changeLanguage(this.currentEngineUser.location.defaultLanguage)
        }
        return res;
    }

    @action handleContactChange({ data: { updated = [], deleted = [] } }) {
        if (deleted.includes(this.currentEngineUser.contact.id)) {
            this.handleLogout();
        } // eslint-disable-next-line no-unused-vars
        for (const { id, user: userChanges = {}, ...changes } of updated) {
            if (id === this.currentEngineUser.contact.id) {
                updateModel(this.currentEngineUser.contact, changes);
                updateModel(this.currentEngineUser, userChanges);
            }
        }
    }

    setupSocket(api) {
        if (!api) {
            api = this.api;
        }
        if (!api.socketUrl) {
            return;
        }
        this.destroySocket(api);

        api.socket = new ClosableSocket({
            url: generateUrl(api.socketUrl),
        });
        api.socket.subscribe({
            onPublish: this.handleBuildInfoPublish,
            // onReconnect: this.handleBuildInfoReconnect,
            room: { '*PUBLIC*': 'build_info' },
        });
    }

    destroySocket(api) {
        if (!api) {
            api = this.api;
        }
        if (api.socket) {
            api.socket.close();
            api.socket = null;
        }
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)
    }
};


const viewStore = new SpecialViewStore({
    api,
    engineApi,
    user: new User(null, {
        relations: [
            'groups.permissions',
            'client',
        ],
    }),
    socketUrl: `${PUBLIC_URL || ''}/ws/`
});

/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;

export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }
    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    FallbackComponent() {
        return <div>An error has occurred</div>;
    }


    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                    <Modal size="tiny" open={this.showAlert} centered={false}>
                        <Modal.Content style={{ textAlign: 'center' }}>
                            <p>{this.alertMessage}</p>
                            <p>{t('form.confirmQuestion')}</p>
                        </Modal.Content>
                        <Modal.Actions style={{ display: 'flex' }}>
                            <CancelButton negative onClick={this.cancel} />
                            <RightDivider />
                            <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                        </Modal.Actions>
                    </Modal>
                    <ReCyCleTheme theme={{
                        ...theme,
                        primaryColor: theme.secondaryColor,
                        secondaryColor: theme.primaryColor,
                    }}>
                        <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                            this.showAlert = true;
                            this.alertConfirm = confirm;

                            if (typeof message === 'object') {
                                this.alertMessage = message.message;
                                this.alertOnApply = message.onApply;
                                this.alertOnCancel = message.onCancel;
                            } else {
                                this.alertMessage = message;
                                this.alertOnApply = null;
                                this.alertOnCancel = null;
                            }

                        }}>
                            <App store={viewStore} />
                        </Router>
                    </ReCyCleTheme>
                </React.Fragment>
            </Sentry.ErrorBoundary>
        );
    }
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);
